import { Box, Button, Container, Grid, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { List } from '@material-ui/core';
import {
	LineItem,
	LotterySelectWinnerAutosuggest,
	Subheader
} from 'components';
import { useAlerts, useLotteries } from 'hooks';
import { MONTHS } from 'lib/constants';
import { properCase } from 'lib/utils';
import { validateLotteryWithWinner } from 'validations/lotteries';

const LotterySelectWinner = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { lottery, handleChange, updateLottery, findLottery, setLottery } =
		useLotteries({
			id: id
		});

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const valid = validateLotteryWithWinner(lottery);
		if (valid.isValid) {
			await updateLottery(lottery);
			showAlertSuccess('Winner has been saved!');
			navigate(`/lotteries`);
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleRandomClick = (winner_id) => {
		setLottery({
			...lottery,
			winner_id: winner_id
		});
	};

	useEffect(() => {
		if (id) findLottery(id);
	}, []);

	return (
		<>
			<Subheader
				title="Select Lottery Winner"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Box p={1}>
				<Container>
					<Paper>
						<Box p={4}>
							<List>
								<LineItem label="Month" value={MONTHS[lottery.month - 1]} />
								<LineItem label="Year" value={lottery.year} />
								<LineItem
									label="Points Required"
									value={lottery.points_required}
								/>
								<LineItem
									label="Prize Description"
									value={lottery.prize_description}
								/>
								<LineItem
									label="Status"
									value={properCase(lottery.status || '')}
								/>
							</List>
						</Box>
					</Paper>
				</Container>
			</Box>
			<Box p={1}>
				<Container>
					<Paper>
						<Box p={4}>
							<Grid container spacing={1}>
								<Grid item xs={12} sm={12}>
									<LotterySelectWinnerAutosuggest
										value={lottery?.winner?.id}
										label="Select Winner"
										name="winner_id"
										placeholder="Select Winner"
										handleChange={handleChange}
										handleRandomClick={handleRandomClick}
									/>
								</Grid>
							</Grid>
							<Box py={2}>
								<Button
									color="primary"
									variant="contained"
									startIcon={<Check />}
									onClick={handleSaveClick}
								>
									Save
								</Button>
							</Box>
						</Box>
					</Paper>
				</Container>
			</Box>
		</>
	);
};

export default LotterySelectWinner;
