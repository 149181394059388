import { Button, ListItem, makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';

const MenuItem = ({
	handleClick,
	indent,
	active,
	value,
	depth = 0,
	open,
	expandable = false,
	text,
	icon: Icon
}) => {
	const classes = useStyles();

	let paddingLeft = 18;

	if (depth > 0) {
		paddingLeft = 42 + 8 * depth;
	}

	const style = {
		paddingLeft
	};

	return (
		<ListItem className={classes.itemLeaf} disableGutters>
			<Button
				onClick={(e) => handleClick(value, e)}
				className={clsx(
					classes.button,
					`depth-${depth}`,
					active ? classes.active : null
				)}
				style={style}
				color="primary"
				startIcon={Icon && <Icon className={classes.icon} />}
			>
				<span className={classes.text}>{text}</span>
				{expandable && (
					<>
						{open ? (
							<ExpandLess className={classes.expandIcon} color="inherit" />
						) : (
							<ExpandMore className={classes.expandIcon} color="inherit" />
						)}
					</>
				)}
			</Button>
		</ListItem>
	);
};
export default MenuItem;

const ITEM_HEIGHT = 30;

const useStyles = makeStyles((theme) => ({
	item: {
		display: 'block'
	},
	itemLeaf: {
		display: 'flex',
		paddingTop: 0,
		paddingBottom: 0,
		height: ITEM_HEIGHT
	},
	button: {
		borderRadius: 0,
		color: theme.palette.common.white,
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '260px',
		height: ITEM_HEIGHT
	},
	icon: {
		color: theme.palette.common.white,
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(1)
	},
	expandIcon: {
		marginLeft: 'auto',
		height: 18,
		width: 18
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 'auto'
	},
	active: {
		color: 'white',
		borderRight: `4px solid ${theme.palette.primary.main}`,
		fontWeight: theme.typography.fontWeightBold,
		'& $icon': {
			color: 'white'
		}
	},
	text: {
		marginLeft: theme.spacing(5)
	}
}));
