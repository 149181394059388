import {
	Button,
	FormControl,
	FormControlLabel,
	InputBase,
	Radio,
	RadioGroup,
	alpha,
	makeStyles
} from '@material-ui/core';
import { Shuffle } from '@material-ui/icons';
import clsx from 'clsx';
import { get } from 'lodash';
import { default as React, useEffect, useState } from 'react';

import api from 'api';
import { Autosuggest } from 'components';
import { buildOptions } from 'lib/utils';

const LotterySelectWinnerAutosuggest = ({
	value,
	name = 'winner_id',
	label,
	handleChange,
	handleRandomClick
}) => {
	const remoteUrl = '/api/v1/admin/users/lottery_draw';
	const [results, setResults] = useState([]);
	const [selectedValue, setSelectedValue] = useState('random');
	const [selectedName, setSelectedName] = useState('');
	const classes = useStyles();

	const handleRadioChange = (event) => {
		setSelectedValue(event.target.value);
	};

	const onRandomClick = () => {
		const random = Math.floor(Math.random() * results.length);
		const winner = results[random];
		handleRandomClick(winner.id);
		setSelectedName(winner.name);
	};

	const [option, setOption] = useState();
	const [options, setOptions] = useState([]);

	const findValue = async (value) => {
		let resp = await api.get(`${remoteUrl}/${value}`);
		setOption({
			value: resp.data.id,
			label: get(resp.data, 'name')
		});
	};

	const findOptions = async () => {
		let resp = await api.get(remoteUrl);
		let results = buildOptions(resp.data, 'id', 'name');
		setOptions(results);
		setResults(resp.data);
	};

	const handleInputChange = (newValue) => {
		findOptions(newValue);
	};

	useEffect(() => {
		if (value) {
			findValue(value);
		}
	}, [value]);

	useEffect(() => {
		findOptions();
	}, []);

	const button = (
		<div className={clsx(classes.buttonContainer)}>
			<InputBase
				placeholder={selectedName}
				inputProps={{
					autoComplete: 'none'
				}}
				className={classes.inputBase}
				readOnly
			/>
			<Button
				color="primary"
				variant="contained"
				startIcon={<Shuffle />}
				onClick={onRandomClick}
			>
				Random
			</Button>
		</div>
	);

	const autosuggest = (
		<Autosuggest
			label={label}
			name={name}
			value={option}
			options={options}
			placeholder={'Select'}
			handleChange={handleChange}
			handleInputChange={handleInputChange}
		/>
	);

	return (
		<div>
			<FormControl component="fieldset">
				<RadioGroup
					aria-label="type"
					name="type"
					value={selectedValue}
					onChange={handleRadioChange}
					row
				>
					<FormControlLabel value="random" control={<Radio />} label="Random" />
					<FormControlLabel
						value="select"
						control={<Radio />}
						label="Select from list"
					/>
				</RadioGroup>
			</FormControl>
			{selectedValue === 'random' && button}
			{selectedValue === 'select' && autosuggest}
		</div>
	);
};

export default LotterySelectWinnerAutosuggest;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-end'
	},
	autoSuggestContainer: {
		width: '100%',
		marginRight: 20
	},
	buttonContainer: {
		display: 'flex',
		height: 43.15
	},
	inputBase: {
		marginRight: 20,
		padding: 0,
		width: '100%',
		'& input': {
			'-webkit-appearance': 'none',
			'-moz-appearance': 'none',
			appearance: 'none',
			padding: theme.spacing(1.5, 1),
			borderRadius: 4,
			backgroundColor: theme.palette.common.white,
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			border: '1px solid #ced4da',
			fontSize: 15,
			'&:focus': {
				boxShadow: `${alpha('#999999', 0.25)} 0 0 0 0.2rem`,
				borderColor: theme.palette.primary.light
			}
		}
	}
}));
