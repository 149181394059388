import React from 'react';
import { useMenu } from 'hooks';
import {
	Hidden,
	Button,
	IconButton,
	TableRow,
	TableCell,
	Menu,
	MenuItem,
	Avatar,
	Checkbox
} from '@material-ui/core';
import { Image } from 'components';
import { MoreHoriz, Person } from '@material-ui/icons';

const ClubItem = ({
	club,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleEditClick(club, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(club, e)}>Delete</MenuItem>
		</Menu>
	);

	const isSelected = selectedIds.includes(club.id);

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(club)}
						name="club"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(club)}>
					<Image src={club.image_url} variant="rounded" size={80} />
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(club)}>{club.name}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(club)}>{club.city}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(club)}>{club.country}</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default ClubItem;
