import { isEmpty } from 'validations';

export const trainingPlanValidation = (trainingPlan, action = 'new') => {
	let isValid = true;
	let messages = [];
	console.log({ action });
	if (isEmpty(trainingPlan.name)) {
		isValid = false;
		messages.push('Name is required');
	}

	if (isEmpty(trainingPlan.description)) {
		isValid = false;
		messages.push('Description is required');
	}

	if (isEmpty(trainingPlan.category_id) && action === 'new') {
		isValid = false;
		messages.push('Skill Category are required');
	}

	if (isEmpty(trainingPlan.position)) {
		isValid = false;
		messages.push('Order is required');
	}

	return {
		isValid,
		messages
	};
};
