import { isEmpty } from 'validations';

export const validateFeedbackDrill = (feedback_drill) => {
	let isValid = true;
	let messages = [];

	// if (isEmpty(feedback_drill.video_url)) {
	//   isValid = false;
	//   messages.push("Video Url is required");
	// }

	if (isEmpty(feedback_drill.skill_categories)) {
		isValid = false;
		messages.push('Please choose at least 1 skill category');
	}

	if (isEmpty(feedback_drill.description)) {
		isValid = false;
		messages.push('Description is required');
	}

	if (isEmpty(feedback_drill.title)) {
		isValid = false;
		messages.push('Title is required');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};
