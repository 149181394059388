import {
	Box,
	Button,
	Grid,
	Paper,
	Typography,
	makeStyles
} from '@material-ui/core';
import { useTrainingPlans } from 'hooks';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const SortableTrainingPlans = ({
	id,
	ListContainer = List,
	ListItem,
	onDragEnd,
	category,
	handleAddClick,
	findTrainingPlans,
	trainingPlans
}) => {
	const sortListContainer = useRef(null);
	const classes = useStyles();

	const _onDragEnd = (result) => {
		if (!result.destination) {
			return trainingPlans;
		}
		const items = reorder(
			trainingPlans,
			result.source.index,
			result.destination.index
		);
		onDragEnd(items);
	};

	useEffect(() => {
		if (category) findTrainingPlans({ category_id: category.id });
	}, [category]);

	return (
		<DragDropContext onDragEnd={_onDragEnd}>
			<Droppable droppableId={id}>
				{(provided) => {
					return (
						<Paper
							ref={sortListContainer}
							style={{
								minHeight: sortListContainer?.current?.clientHeight,
								border: 'none',
								boxShadow: 'none'
							}}
						>
							<Grid container spacing={1} className={classes.root}>
								<Grid item md={1} xs={12}>
									{''}
								</Grid>
								<Grid item md={2} xs={12}>
									<Typography
										variant="body1"
										color={'primary'}
										className={classes.text}
									>
										{'Plan Number'}
									</Typography>
								</Grid>
								<Grid item md={2} xs={12}>
									<Typography
										variant="body1"
										color={'primary'}
										className={classes.text}
									>
										{'Plan Name'}
									</Typography>
								</Grid>
								<Grid item md={4} xs={12}>
									<Typography
										variant="body1"
										color={'primary'}
										className={classes.text}
									>
										{'Description'}
									</Typography>
								</Grid>
								<Grid item md={1} xs={12}>
									<Typography
										variant="body1"
										color={'primary'}
										className={classes.text}
									>
										{'Published'}
									</Typography>
								</Grid>
								<Grid item md={1} xs={12}>
									{''}
								</Grid>
							</Grid>
							<ListContainer
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{trainingPlans.length > 0 ? (
									trainingPlans.map((item, i) => (
										<Draggable
											key={item.id}
											draggableId={String(item.id)}
											index={i}
										>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style
													)}
												>
													<ListItem item={item} />
												</div>
											)}
										</Draggable>
									))
								) : (
									<Box
										p={2}
										display="flex"
										flexDirection="column"
										justifyContent="center"
										alignItems="center"
										height="100%"
										width="100%"
										sx={{ minHeight: 600 }}
									>
										<Typography variant="body1" style={{ marginBottom: 20 }}>
											{'No Training Plans'}
										</Typography>
										<Button
											onClick={handleAddClick}
											color="primary"
											variant="contained"
										>
											Add a training plan
										</Button>
									</Box>
								)}
								{provided.placeholder}
							</ListContainer>
						</Paper>
					);
				}}
			</Droppable>
		</DragDropContext>
	);
};

export default SortableTrainingPlans;

const getItemStyle = (_, draggableStyle) => ({
	userSelect: 'none',
	...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result.map((item, index) => ({
		...item,
		position: index + 1
	}));
};

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 20,
		borderBottom: '1px solid #e0e0e0'
	},
	item: { verticalAlign: 'center' },
	text: { paddingTop: 20 },
	icon: { color: theme.palette.common.grey, marginLeft: 20, marginTop: 20 },
	button: { paddingTop: 20 }
}));
