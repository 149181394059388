import {
	Box,
	Button,
	Container,
	Paper,
	Tab,
	Tabs,
	Menu,
	MenuItem,
	ButtonGroup
} from '@material-ui/core';
import { Subheader } from 'components';
import EventDetail from 'features/events/detail/EventDetail';
import EventUserList from 'features/events/entries/EventUserList';
import EventShortList from 'features/events/shortlist/index';
import { useAlerts, useEvents } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'components/Modal';
import EventVideosForm from './event_videos/EventVideosForm';

const EventShow = ({ ...props }) => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const { id } = useParams();
	const [openModal, setOpenModal] = useState(false);
	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const [openModalAddEvent, setOpenModalAddEvent] = useState(false);

	const handleOpenModalAddEvent = () => {
		setOpenModalAddEvent(true);
	};

	const handleCloseModalAddEvent = () => {
		setOpenModalAddEvent(false);
	};

	const {
		eventId,
		isLoading,
		isLoaded,
		event,
		findEvent,
		deleteEvent,
		reloadEvent,
		notifyEventUsers
	} = useEvents({
		id: id
	});

	const [tabValue, setTabValue] = useState(0);

	const handleTabChange = (ev, value) => {
		setTabValue(value);
	};

	const handleEditClick = () => navigate(`/events/${id}/edit`);

	const handleDeleteEvent = async () => {
		try {
			if (id) {
				await deleteEvent({ id: id });
				showAlertSuccess('Event deleted successfully');
				navigate('/events-partners');
			} else {
				showAlertError('Cannot delete event');
			}
		} catch (error) {
			showAlertError('Error deleting event');
		}
	};

	useEffect(() => {
		if (id) findEvent(id);
	}, [id]);

	const handleEventVideoCallback = () => {
		reloadEvent();
		handleClose();
		handleCloseModalAddEvent();
	};

	const handleNotifyShortlist = async () => {
		try {
			const response = await notifyEventUsers(id);
			if (response) {
				reloadEvent();
				showAlertSuccess('Shortlisted users have been notified successfully');
			} else {
				showAlertError('Failed to notify shortlisted users');
			}
		} catch (error) {
			showAlertError('An error occurred while notifying users');
		}
	};

	const shortlistedUsers =
		event.event_users?.filter((user) => user.status === 'SHORTLISTED') || [];

	const shortlistedSubmissions =
		event.event_submissions?.filter(
			(submission) =>
				submission.status === 'approved' && submission.notified === false
		) || [];

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : `${event && event.name}`}
				buttons={[
					<Button
						key="1"
						variant="contained"
						color="primary"
						disabled={shortlistedSubmissions.length <= 0}
						onClick={handleNotifyShortlist}
					>
						Notify Shortlist
					</Button>,
					<Button
						key="2"
						variant="outlined"
						id="basic-button"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
					>
						Actions
					</Button>
				]}
			/>
			<Container>
				<Tabs value={tabValue} onChange={handleTabChange}>
					<Tab label="Event Details" value={0} />
					<Tab label="All Entries" value={1} />
					<Tab label="Shortlist" value={2} />
				</Tabs>
				<Paper>
					<Box p={4}>
						{tabValue === 0 && (
							<EventDetail event={event} reloadEvent={reloadEvent} />
						)}

						{tabValue === 1 && <EventUserList event={event} />}

						{tabValue === 2 && <EventShortList event={event} />}
					</Box>
				</Paper>
			</Container>

			<Modal
				open={openModal}
				handleClose={handleCloseModal}
				title="Delete Event"
				subtitle="Are you sure you want to delete this event?"
				actions={
					<>
						<Button onClick={handleCloseModal} variant="outlined">
							Cancel
						</Button>
						<Button
							onClick={handleDeleteEvent}
							variant="contained"
							category="primary"
						>
							Delete
						</Button>
					</>
				}
				maxWidth="md"
				fullScreen={false}
			/>

			<Modal
				open={openModalAddEvent}
				handleClose={handleCloseModalAddEvent}
				title="+ Add Event Video"
				children={
					<>
						<EventVideosForm
							handleEventVideoCallback={handleEventVideoCallback}
						/>
					</>
				}
				maxWidth="md"
				fullScreen={false}
			/>

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button'
				}}
			>
				<MenuItem onClick={handleEditClick}>Edit</MenuItem>
				<MenuItem onClick={handleOpenModalAddEvent}>+ Add Event Video</MenuItem>
				<MenuItem onClick={handleOpenModal}>Delete</MenuItem>
			</Menu>
		</>
	);
};

export default EventShow;
