import React from 'react';

import { useResource } from 'hooks';

const useSettings = ({}) => {
	const {
		id: settingId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: setting,
		errors: settingErrors,
		findOne: findSetting,
		update: updateSetting,
		save: saveSetting,
		handleChange,
		refresh: reloadSetting,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort
	} = useResource({
		id: 1,
		url: '/api/v1/admin/settings',
		name: 'setting'
	});

	return {
		settingId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		setting,
		findSetting,
		settingErrors,
		saveSetting,
		updateSetting,
		handleChange,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadSetting
	};
};

export default useSettings;
