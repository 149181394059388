import React from 'react';
import { List } from '@material-ui/core';
import { Label, LineItem } from 'components';

const VideoDetails = ({ video }) => (
	<div>
		{video?.url && <video controls src={video.url} width="100%" />}
		<List>
			<LineItem label="User" value={video?.user?.name} />
			<LineItem label="Description" value={video.description} />
			<LineItem
				label="Tags"
				value={video?.tags?.map((label, i) => (
					<Label key={i} label={label} />
				))}
			/>
		</List>
	</div>
);

export default VideoDetails;
