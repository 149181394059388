import React from 'react';
import { AsyncAutosuggest } from 'components';

const UserAutosuggest = ({ value, name = 'user_id', label, handleChange }) => (
	<AsyncAutosuggest
		name={name}
		label={label}
		value={value}
		displayField="name"
		remoteUrl="/api/v1/admin/users"
		placeholder="Select"
		handleChange={handleChange}
	/>
);

export default UserAutosuggest;
