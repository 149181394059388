import { isEmpty } from 'validations';

export const validateTrainer = (trainer) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(trainer.first_name)) {
		isValid = false;
		messages.push('First name is required');
	}

	if (isEmpty(trainer.last_name)) {
		isValid = false;
		messages.push('Last name is required');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};
