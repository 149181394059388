import { Box, Button, Container, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SubheaderV2 } from 'components';
import LessonDetails from 'features/lessons/LessonDetails';
import { useLessons } from 'hooks';

const LessonShow = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { isLoading, lesson, findLesson } = useLessons({
		id: id
	});

	const handleEditClick = () => navigate(`/lessons/${id}/edit`);

	useEffect(() => {
		if (id) findLesson(id);
	}, []);

	return (
		<>
			<SubheaderV2
				title={isLoading ? 'Loading...' : lesson.title}
				buttons={[
					<Button onClick={handleEditClick} color="primary" variant="contained">
						Edit
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<LessonDetails lesson={lesson} />
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default LessonShow;
