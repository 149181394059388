import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import EventItem from 'features/events/EventItem';
import { useNavigation } from 'hooks';

const PartnerEvents = ({ partner, events }) => {
	const navigate = useNavigate();

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/events'
	});

	return (
		<>
			<div>
				<Grid container spacing={3} alignItems="center">
					<Grid item xs={6}>
						<Typography>Partner Events</Typography>
					</Grid>
					<Grid item xs={6} container justify="flex-end">
						<Button
							variant="contained"
							color="primary"
							onClick={() => navigate(`/events/new/partner/${partner.id}`)}
						>
							Create new event
						</Button>
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					{events &&
						events.map((event) => (
							<EventItem
								key={event.id}
								event={event}
								isSelectable={false}
								handleClick={handleClick}
							/>
						))}
				</Grid>
			</div>
		</>
	);
};

export default PartnerEvents;
