import React, { useCallback, useEffect } from 'react';
import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';
import PromoCodeForm from './PromoCodeForm';
import { useAlerts, usePromoCodes, usePromoRules } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { promoRuleOptions } from 'lib/utils';
import { validatePromoCode } from 'validations/promoCode';

const PromoCodeNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { promoCode, handleChange, createPromoCode, promoCodeId } =
		usePromoCodes({});
	const { promoRules, findPromoRules } = usePromoRules({});
	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const { isValid, messages } = validatePromoCode(promoCode);
		if (isValid) {
			await createPromoCode(promoCode);
		} else {
			messages.map((m) => showAlertError(m));
		}
	};

	const navigateToPromoCodeShow = useCallback(() => {
		if (promoCodeId) {
			showAlertSuccess('Promo Code Created');
			navigate(`/promo_codes/${promoCodeId}`);
		}
	}, [promoCodeId]);

	useEffect(() => {
		findPromoRules();
	}, []);

	useEffect(() => navigateToPromoCodeShow(), [navigateToPromoCodeShow]);

	return (
		<>
			<Subheader
				title="Add Promo Code"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<PromoCodeForm
						promo={promoCode}
						promoRules={promoRuleOptions(promoRules)}
						handleChange={handleChange}
					/>
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default PromoCodeNew;
