import React, { useEffect, useMemo, useState } from 'react';

import { Grid, makeStyles, Typography } from '@material-ui/core';
import {
	Autosuggest,
	MultiAutosuggest,
	TextInput,
	VideoUploader
} from 'components';
import { BOOLEAN } from 'lib/constants';

const TrainingPlanDrillForm = ({
	trainingPlan,
	handleChange,
	categories,
	skillsPlaceholder,
	memoryFile,
	handleDelete,
	handleUpload
}) => {
	const [options, setOptions] = useState([]);
	const classes = useStyles();

	const displayPublished = (published) => {
		let value = null;
		switch (published) {
			case undefined:
				value = null;
				break;
			case true:
				value = true;
				break;
			case false:
				value = false;
				break;
			default:
				value = null;
				break;
		}
		return value;
	};

	useEffect(() => {
		if (categories) {
			setOptions(
				categories.map((category) => {
					return {
						label: category.name,
						value: category.id
					};
				})
			);
		}
	}, [categories]);
	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={trainingPlan.name}
					label="Name"
					name="name"
					placeholder="Name"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					rows={3}
					multiline
					value={trainingPlan.description}
					label="Description"
					name="description"
					placeholder="Description"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<MultiAutosuggest
					options={options}
					value={trainingPlan.category_ids || []}
					label="Skill Categories"
					placeholder={skillsPlaceholder}
					name="category_ids"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>

			<Grid item md={4} xs={12}>
				<Autosuggest
					options={BOOLEAN}
					value={displayPublished(trainingPlan.published)}
					label={`Published `}
					name="published"
					placeholder="Yes / No"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<TextInput
					value={trainingPlan.position}
					label="Order"
					name="position"
					placeholder="Order"
					handleChange={handleChange}
					className={classes}
					type="number"
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<TextInput
					value={trainingPlan.video_modifier_main}
					label="Main Video Cloudinary Modifier"
					name="video_modifier_main"
					placeholder="eg: du_5 for clip duration"
					handleChange={handleChange}
					disabled={false}
					className={classes}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<TextInput
					value={trainingPlan.video_modifier_clip}
					label="Clip Video Cloudinary Modifier"
					name="video_modifier_clip"
					placeholder="eg: so_5 for start offset"
					handleChange={handleChange}
					disabled={false}
					className={classes}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<TextInput
					value={trainingPlan.video_modifier_thumbnail}
					label="Thumbnail Video Cloudinary Modifier"
					name="video_modifier_thumbnail"
					placeholder="eg: e_accelerate:5 for acceleration"
					handleChange={handleChange}
					disabled={false}
					className={classes}
				/>
			</Grid>

			<Grid container spacing={1}>
				<Grid item md={6} xs={12}>
					<Typography variant="body2" color="textSecondary">
						{'Preview Video'}
					</Typography>
					<VideoUploader
						label="Preview Video"
						videoUrl={
							trainingPlan.video2_url ||
							(memoryFile && memoryFile?.video2_url?.url)
						}
						handleUpload={(file) => handleUpload(file, 'video2_url')}
						handleDelete={() => {
							handleDelete('video2_url');
							handleChange({ target: { name: 'video2_url', value: '' } });
						}}
					/>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography variant="body2" color="textSecondary">
						{'Lesson Video'}
					</Typography>
					<VideoUploader
						label="Lesson Video'"
						videoUrl={
							trainingPlan.video_url ||
							(memoryFile && memoryFile?.video_url?.url)
						}
						handleUpload={(file) => handleUpload(file, 'video_url')}
						handleDelete={() => {
							handleDelete('video_url');
							handleChange({ target: { name: 'video_url', value: '' } });
						}}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<TextInput
					value={trainingPlan.video2_url}
					label="Preview Video URL"
					name="video2_url"
					placeholder="Enter Cloudinary URL"
					handleChange={handleChange}
					className={classes}
				/>
				<TextInput
					value={trainingPlan.video_url}
					label="Lesson Video URL"
					name="video_url"
					placeholder="Enter Cloudinary URL"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	inputBase: {
		'& input, & textarea': {
			borderColor: theme.palette.placeHolder.text
		}
	}
}));

export default TrainingPlanDrillForm;
