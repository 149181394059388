import React from 'react';
import api from 'api';
import { useAlerts, useLoaders, useResource } from 'hooks';
import { validateTopic } from 'validations/topics';

const useTopics = ({ id, defaultSortKey, defaultSortDirection, ...props }) => {
	const { showLoading, hideLoading } = useLoaders();
	const { showAlertError, showAlertSuccess } = useAlerts();

	const RESOURCE_URL = '/api/v1/admin/topics';

	const {
		resourceId: topicId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: topic,
		resources: topics,
		findOne: findTopic,
		findMany: findTopics,
		update: updateTopic,
		create: createTopic,
		save: saveTopic,
		destroy: deleteTopic,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadTopic,
		reloadMany: reloadTopics,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort
	} = useResource({
		id: id,
		url: RESOURCE_URL,
		name: 'topic',
		defaultSortKey: defaultSortKey,
		defaultSortDirection: defaultSortDirection
	});

	const checkTopicOrder = async (params) => {
		try {
			showLoading();

			const res = await api.post(`${RESOURCE_URL}/check_order`, params);
			hideLoading();
			return res;
		} catch (e) {
			console.log('error');
		}
		hideLoading();
	};

	return {
		topicId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		topic,
		topics,
		findTopic,
		findTopics,
		saveTopic,
		updateTopic,
		createTopic,
		deleteTopic,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadTopic,
		reloadTopics,
		checkTopicOrder
	};
};

export default useTopics;
