import { colors } from '@material-ui/core';

export default {
	primary: {
		contrastText: '#ffffff',
		main: '#3DCD90'
	},
	secondary: {
		contrastText: '#D6D6E7',
		main: '#151515'
	},
	error: {
		contrastText: '#ffffff',
		dark: colors.red[900],
		main: colors.red[600],
		light: colors.red[400]
	},
	text: {
		primary: '#0B132B',
		secondary: '#777777',
		link: '#CED9E4'
	},
	link: '#23263B',
	icon: '#3DCD90',
	background: {
		default: '#F5F5FA',
		paper: '#ffffff'
	},
	common: {
		border: '#E0E0E5',
		inputBorder: '#ced4da',
		grey: colors.grey[500]
	},
	divider: colors.grey[200],
	placeHolder: {
		text: '#8B8B8B'
	}
};
