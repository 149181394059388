import { List } from '@material-ui/core';
import React from 'react';

import { LineItem } from 'components';
import { properCase } from 'lib/utils';

const AchievementDetails = ({ achievement }) => {
	return (
		<List>
			<LineItem
				label="User"
				value={`${achievement?.user?.first_name} ${achievement?.user?.last_name}`}
			/>
			<LineItem label="Reward" value={achievement?.reward?.title} />
			<LineItem label="Points Achieved" value={achievement?.points_achieved} />
			<LineItem label="Status" value={properCase(achievement.status || '')} />
			<LineItem label="Date Created" value={achievement.created_at} />
			<LineItem label="Last Updated" value={achievement.updated_at} />
		</List>
	);
};

export default AchievementDetails;
