import React from 'react';
import api from 'api';
import { useAlerts, useLoaders, useResource } from 'hooks';
import { validateArticle } from 'validations/articles';

const useArticles = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders();
	const { showAlertError, showAlertSuccess } = useAlerts();

	const {
		resourceId: articleId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: article,
		resources: articles,
		findOne: findArticle,
		findMany: findArticles,
		update: updateArticle,
		create: createArticle,
		save: saveArticle,
		destroy: deleteArticle,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadArticle,
		reloadMany: reloadArticles,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort
	} = useResource({
		id: id,
		url: '/api/v1/admin/articles',
		name: 'article'
	});

	return {
		articleId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		article,
		articles,
		findArticle,
		findArticles,
		saveArticle,
		updateArticle,
		createArticle,
		deleteArticle,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadArticle,
		reloadArticles
	};
};

export default useArticles;
