import React, { useEffect, useState } from 'react';

import { Box, Container, Grid, List } from '@material-ui/core';

import { DisplayImage, DisplayText, LineItem } from 'components';
import AnswerCard from 'features/trainingPlan/trainingPlan/trivia/AnswerCard';
import AnswerModal from './AnswerModal';
import { useTrainingPlans, useTriviaAnswers } from 'hooks';
import { useParams } from 'react-router-dom';
import { orderBy, set, shuffle } from 'lodash';

const TriviaQuestionDetails = ({ question, findTrainingPlan }) => {
	const arrayIndex = Array.from({ length: 4 }, (_, i) => i);

	const { id } = useParams();
	const [showAnswerModal, setShowAnswerModal] = useState(false);

	const {
		handleChange,
		triviaAnswer,
		createTriviaAnswer,
		setResource,
		updateTriviaAnswer
	} = useTriviaAnswers();
	const { destroyTriviaAnswer } = useTriviaAnswers();
	const toggleAnswerModal = () => setShowAnswerModal((prev) => !prev);
	const [answers, setAnswers] = useState([]);

	const handleAddAnswer = () => {
		setResource({ question_id: question.id });
		toggleAnswerModal();
	};

	const handleEditAnswer = (answer) => {
		setResource(answer);
		toggleAnswerModal();
	};

	const handleDeteleAnswer = async (answer) => {
		await destroyTriviaAnswer(answer).finally(async () => {
			await findTrainingPlan(id);
		});
	};

	useEffect(() => {
		if (question?.trivia_answers) setAnswers(orderBy(question.trivia_answers, ['created_at'], ['asc']));
	}, [question]);

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<DisplayText label={'Trivia Question'} text={question?.question} />
			</Grid>

			<Grid item xs={12} sm={12}>
				{question.media_type === 'image' && (
					<DisplayImage
						label={'Question Image'}
						url={question?.media_url}
						width={'33.3%'}
					/>
				)}
				{question.media_type === 'video' && (
					<video
						src={question?.media_url}
						controls
						muted
						height="240"
						width="auto"
					/>
				)}
			</Grid>
			<Box my={4} width={'100%'}>
				<Grid container spacing={5}>
					{arrayIndex.map((index) => {
						if (answers?.length > 0 && answers[index]) {
							return (
								<AnswerCard
									key={index}
									correnctAnswerId={question.correct_answer_id}
									answer={answers[index]}
									index={index}
									handleAddAnswer={handleAddAnswer}
									handleDeteleAnswer={handleDeteleAnswer}
									handleEditAnswer={handleEditAnswer}
								/>
							);
						} else {
							return (
								<AnswerCard
									key={index}
									answer={undefined}
									index={index}
									handleAddAnswer={handleAddAnswer}
								/>
							);
						}
					})}
				</Grid>
			</Box>

			<AnswerModal
				handleChange={handleChange}
				triviaAnswer={triviaAnswer}
				createTriviaAnswer={createTriviaAnswer}
				open={showAnswerModal}
				title={'Answer'}
				handleClose={toggleAnswerModal}
				question={question}
				findTrainingPlan={findTrainingPlan}
				updateTriviaAnswer={updateTriviaAnswer}
			/>
		</Grid>
	);
};

export default TriviaQuestionDetails;
