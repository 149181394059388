import React, { useEffect } from 'react';
import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts, usePromoCodes, usePromoRules } from 'hooks';

import PromoCodeForm from './PromoCodeForm';
import { promoRuleOptions } from 'lib/utils';
import { validatePromoCode } from 'validations/promoCode';

const PromoCodeEdit = (props) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { promoCode, findPromoCode, handleChange, updatePromoCode } =
		usePromoCodes({ id: id });
	const { promoRules, findPromoRules } = usePromoRules({});

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const { isValid, messages } = validatePromoCode(promoCode);

		if (isValid) {
			await updatePromoCode(promoCode);

			showAlertSuccess('Promo Code updated');
			navigate(`/promo_codes/${id}`);
		} else {
			messages.map((m) => showAlertError(m));
		}
	};

	useEffect(() => {
		if (id) findPromoCode(id);
	}, []);

	useEffect(() => {
		findPromoRules();
	}, []);

	return (
		<>
			<Subheader
				title="Edit Promo Code"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<PromoCodeForm
							promoRules={promoRuleOptions(promoRules || [])}
							promo={promoCode}
							handleChange={handleChange}
						/>
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default PromoCodeEdit;
