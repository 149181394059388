import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';
import VideoForm from 'features/videos/VideoForm';
import { useAlerts, useVideos } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const VideoEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const {
		videoId,
		isLoading,
		isLoaded,
		video,
		handleChange,
		updateVideo,
		findVideo,
		findVideoTags,
		uploadFile,
		deleteFile
	} = useVideos({ id: id });

	const [tags, setTags] = useState();

	const { showAlertSuccess } = useAlerts();

	const handleFindVideoTags = async () => {
		let resp = await findVideoTags();
		let videoTags = resp.map((tag) => ({
			label: tag,
			value: tag
		}));
		setTags(videoTags);
	};

	const handleSaveClick = async () => {
		await updateVideo(video);
		showAlertSuccess('Video has been updated');
		navigate(`/videos/${id}`);
	};

	const handleUpload = async (file, type) => {
		await uploadFile(file, type);
		findVideo(id);
	};

	const handleDelete = async (type) => {
		await deleteFile(type);
		findVideo(id);
	};

	useEffect(() => {
		if (id) {
			findVideo(id);
		}
	}, [id]);

	useEffect(() => {
		handleFindVideoTags();
	}, []);

	return (
		<>
			<Subheader
				title="Edit Video"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<VideoForm video={video} tags={tags} handleChange={handleChange} />
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default VideoEdit;
