import React, { useContext } from 'react';

import { AppContext } from 'context';
import { Navigate, Outlet } from 'react-router-dom';

const AuthRoute = ({ props }) => {
	const { authenticated } = useContext(AppContext);

	return authenticated === false ? (
		<Navigate to="/login" />
	) : (
		<Outlet {...props} />
	);
};

export default AuthRoute;
