import React from 'react';
import { useQuery } from 'hooks';
import { Box } from '@material-ui/core';
import { Autosuggest, SearchInput } from 'components';

const SearchForm = ({ handleSearch, ...props }) => {
	const { query, handleChange, handleKeyPress, handleClear, setQuery } =
		useQuery({
			handleSearch
		});

	return (
		<Box p={1} px={2}>
			<SearchInput
				fullWidth
				name="keywords"
				onKeyPress={handleKeyPress}
				value={query.search}
				handleChange={handleChange}
			/>
		</Box>
	);
};

export default SearchForm;
