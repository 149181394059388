import { Box, Button, Container, List, Typography } from '@material-ui/core';
import { chain, orderBy } from 'lodash';
import React from 'react';

import LessonItem from 'features/courses/lessons/LessonItem';
import LessonSortableList from 'features/courses/lessons/LessonSortableList';
import { useAlerts, useLessons, useNavigation } from 'hooks';

const LessonList = ({
	lessons,
	setLessons,
	level,
	resortLessons,
	reloadCourse,
	handleAddClick,
	...props
}) => {
	const { handleClick, handleEditClick } = useNavigation({
		history,
		url: '/lessons'
	});

	const { showAlertSuccess } = useAlerts();

	const { deleteLesson } = useLessons({});

	const onDragEnd = (result) => {
		resortLessons(lessons);
		setLessons(result);
	};

	const handleDeleteClick = async (lesson) => {
		await deleteLesson(lesson);
		showAlertSuccess('Lesson has been deleted');

		const sortedLessons = chain(lessons)
			.orderBy(['position'], ['asc'])
			.filter(removeIdElement(lesson.id))
			.map(orderByIndex)
			.value();

		resortLessons(sortedLessons);
		setLessons(sortedLessons);
	};

	return (
		<>
			{lessons.length > 0 ? (
				<>
					<LessonSortableList
						id="sort-lessons"
						onDragEnd={onDragEnd}
						list={orderBy(lessons, ['position', 'title'], ['asc', 'desc'])}
						ListContainer={List}
						ListItem={({ item }) => (
							<LessonItem
								key={item.id}
								lesson={item}
								level={level}
								handleClick={handleClick}
								handleDeleteClick={handleDeleteClick}
								handleEditClick={handleEditClick}
							/>
						)}
					/>
				</>
			) : (
				<Container maxWidth="lg">
					<Box
						p={2}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						height="100%"
						width="100%"
						sx={{ minHeight: 600 }}
					>
						<Typography variant="body1" style={{ marginBottom: 20 }}>
							{'No lessons in this course.'}
						</Typography>
						<Button
							onClick={handleAddClick}
							color="primary"
							variant="contained"
						>
							Add a new lesson
						</Button>
					</Box>
				</Container>
			)}
		</>
	);
};

export default LessonList;

const removeIdElement = (otherId) => (v) => v.id !== otherId;
const orderByIndex = (v, idx) => ({ ...v, position: idx + 1 });
