import React from 'react';

import { useResource } from 'hooks';

const useLotteryDraws = ({ id, ...props }) => {
	const [filters, setFilters] = React.useState({
		status: undefined
	});

	const resetFilters = () => {
		setFilters({
			status: undefined
		});
	};

	const {
		id: lotteryDrawId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: lotteryDraw,
		resources: lotteryDraws,
		errors: lotteryDrawErrors,
		findOne: findLotteryDraw,
		findMany: findLotteryDraws,
		update: updateLotteryDraw,
		create: createLotteryDraw,
		save: saveLotteryDraw,
		destroy: deleteLotteryDraw,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reloadOne: reloadLotteryDraw,
		reloadMany: reloadLotteryDraws,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		setResource: setLotteryDraw
	} = useResource({
		id: id,
		url: '/api/v1/admin/lottery_draws',
		name: 'lottery_draw'
	});

	return {
		lotteryDrawId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		lotteryDraw,
		lotteryDraws,
		findLotteryDraw,
		findLotteryDraws,
		lotteryDrawErrors,
		saveLotteryDraw,
		updateLotteryDraw,
		createLotteryDraw,
		deleteLotteryDraw,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadLotteryDraw,
		reloadLotteryDraws,
		filters,
		setFilters,
		resetFilters,
		setLotteryDraw
	};
};

export default useLotteryDraws;
