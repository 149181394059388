import { List, ListItem, makeStyles } from '@material-ui/core';
import React from 'react';

import { Label, LineItem } from 'components';

const EditorialDetails = ({ editorial }) => {
	return (
		<List>
			<ListItem>
				<img src={editorial.thumbnail_url} width={240} alt={'thumbnail_url'} />
			</ListItem>
			<ListItem>
				<img src={editorial.image_url} width={240} alt={'image_url'} />
			</ListItem>
			<ListItem>
				<img src={editorial.web_image_url} width={500} alt={'web_image_url'} />
			</ListItem>
			<ListItem>
				{editorial.video_url && (
					<video
						src={editorial.video_url}
						controls
						autoPlay
						muted
						height="240"
						width="auto"
					/>
				)}
			</ListItem>
			{editorial.jw_captions &&
				Object.keys(editorial.jw_captions).map((language, i) => (
					<LineItem
						key={i}
						label={`${language} captions`}
						value={editorial?.jw_captions[language]}
					/>
				))}
			<LineItem label="JW Player ID" value={editorial?.jw_player_id} />
			<LineItem label="Title" value={editorial.title} />
			<LineItem label="Category" value={editorial.category} />
			<LineItem label="Description" value={editorial.description} />
			<LineItem label="Video length" value={editorial.video_length} />
			<LineItem label="Topic" value={editorial?.topic?.title} />
			<LineItem
				label="Premium"
				value={<Label label={editorial.premium ? 'Yes' : 'No'} />}
			/>
		</List>
	);
};

export default EditorialDetails;

const useStyles = makeStyles((theme) => ({
	captions: {
		whiteSpace: 'pre-wrap',
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing(2),
		borderRadius: theme.shape.borderRadius
	}
}));
