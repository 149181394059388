import React from 'react';
import { Grid } from '@material-ui/core';
import { UserAutosuggest, TextInput, SwitchInput, JSONInput } from 'components';

const ArticleForm = ({ article, isEditing, handleChange }) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={article.title}
				label="Title"
				name="title"
				placeholder="Title"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				rows={10}
				multiline
				value={article.subtitle}
				label="Subtitle"
				name="subtitle"
				placeholder="Subtitle"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={article.category}
				label="Category"
				name="category"
				placeholder="Category"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={article.video_url}
				label="Video URL"
				name="video_url"
				placeholder="Video URL"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={article.jw_player_id}
				label="JW Player ID"
				name="jw_player_id"
				placeholder="JW Player ID"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			{article.jw_captions && (
				<JSONInput
					value={article.jw_captions}
					label="JW Captions"
					name="jw_captions"
					placeholder="JW Player Captions"
					handleChange={handleChange}
				/>
			)}
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				type="date"
				value={article.published_at}
				label="Published at"
				name="published_at"
				placeholder="mm/dd/yyyyy"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				rows={10}
				multiline
				value={article.body}
				label="Content"
				name="body"
				placeholder="Article content"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<UserAutosuggest
				value={article.author_id}
				name={'author_id'}
				placeholder={'Author'}
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<SwitchInput
				value={article.published ? true : false}
				label="Published"
				name="published"
				placeholder="Published"
				handleChange={handleChange}
			/>
		</Grid>
	</Grid>
);

export default ArticleForm;
