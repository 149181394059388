import React, { useState } from 'react';
import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Subheader } from 'components';
import { useAlerts, useTriviaQuestions, useUploadFileInMemory } from 'hooks';
import TriviaQuestionForm from 'features/trainingPlan/trainingPlan/trivia/TriviaQuestionForm';
import { triviaQuestionValidation } from 'validations/triviaQuestion';
import { getUrlFromFile } from 'hooks/useUploadFileInMemory';
import { triviaAnswerValidation } from 'validations/triviaAnswers';

const TriviaQuestionNew = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();

	const [triviaMedia, setTriviaMedia] = useState([
		{ id: 1, url: '', file: '', answer: '' },
		{ id: 2, url: '', file: '', answer: '' },
		{ id: 3, url: '', file: '', answer: '' },
		{ id: 4, url: '', file: '', answer: '' }
	]);

	const { handleChange, triviaQuestion, createTriviaBlock } =
		useTriviaQuestions();
	const { showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const valid = triviaQuestionValidation(triviaQuestion);

		if (!valid.isValid) {
			showAlertError(valid.messages[0]);
			return;
		}
		const answersValid = triviaAnswerValidation(
			triviaMedia,
			triviaQuestion.answers_media_type
		);

		if (!answersValid.isValid) {
			showAlertError(answersValid.messages[0]);
			return;
		}

    const triviaMediaWithMediaTypes = triviaMedia.map((media) => ({
      ...media,
      media_type: triviaQuestion.answers_media_type
    }))

		createTriviaBlock(
			{
				...triviaQuestion,
				files: memoryFile,
				trivia_answers: triviaMediaWithMediaTypes,
				training_plan_id: id
			},
			triviaQuestion?.media_type !== 'text' && memoryFile
		).finally(() => {
			navigate(`/training-plans/${id}`);
		});
	};

	const handleUpload = async (file, type) => {
		await handleUploadFile(file, type);
	};

	const handleMediaDelete = async (index) => {
		const updatedMedia = await triviaMedia.map((media) => {
			if (media.id === index) {
				return { ...media, file: '', url: '' };
			} else {
				return media;
			}
		});

		setTriviaMedia(updatedMedia);
	};

	const handleMediaChange = async (index, file) => {
		const url = await getUrlFromFile(file);
		const updatedMedia = await triviaMedia.map((media) => {
			if (media.id === index) {
				return { ...media, file: file, url: url };
			} else {
				return media;
			}
		});

		setTriviaMedia(updatedMedia);
	};

	const handleAnswerChange = async (index, value) => {
		const updatedAnswers = triviaMedia.map((item) => {
			if (item.id === index) {
				return { ...item, answer: value };
			} else {
				return item;
			}
		});

		setTriviaMedia(updatedAnswers);
	};

	return (
		<div>
			<Subheader
				title="Add Trivia Question"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<TriviaQuestionForm
					question={triviaQuestion}
					handleChange={handleChange}
					handleUploadFile={handleUpload}
					handleDelete={removeFile}
					memoryFile={memoryFile}
					handleMediaChange={handleMediaChange}
					triviaMedia={triviaMedia}
					handleAnswerChange={handleAnswerChange}
					handleMediaDelete={handleMediaDelete}
				/>
			</Container>
		</div>
	);
};

export default TriviaQuestionNew;
