import api from 'api';
import { useAlerts, useLoaders, useResource } from 'hooks';
import { FEEDBACK_DRILL_SKILLS } from 'lib/constants';

const useFeedbackDrills = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders();
	const { showAlertError, showAlertSuccess } = useAlerts();
	const RESOURCE_NAME = 'feedback_drill';
	const RESOURCE_URL = '/api/v1/admin/feedback_drills';

	const {
		resourceId: feedbackDrillId,
		setId,
		isLoading,
		isLoaded,
		setIsLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: feedbackDrill,
		resources: feedbackDrills,
		findOne: findFeedbackDrill,
		findMany: findFeedbackDrills,
		save: saveFeedbackDrill,
		destroy: deleteFeedbackDrill,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reloadOne: reloadFeedbackDrill,
		reloadMany: reloadFeedbackDrills,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		setResource: setFeedbackDrill,
		handleSort,
		handleErrors
	} = useResource({
		id: id,
		url: RESOURCE_URL,
		name: RESOURCE_NAME
	});

	const createFeedbackDrill = async (resource, files, showLoaders = true) => {
		try {
			showLoading();
			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			};
			let formData = new FormData();
			for (const item in resource) {
				if (item === 'skill_categories') {
					for (const skill of FEEDBACK_DRILL_SKILLS) {
						const feedback_drill_key = Object.keys(skill)[0];
						const feedback_drill_identifier = Object.values(skill)[0].title;
						const relevant_skill = resource[item].filter(
							(v) => v.title === feedback_drill_identifier
						)[0];

						if (relevant_skill) {
							for (const [key, value] of Object.entries(relevant_skill)) {
								formData.append(
									`${RESOURCE_NAME}[${item}][${feedback_drill_key}][${key}]`,
									value
								);
							}
						}
					}
				} else {
					formData.append(`${RESOURCE_NAME}[${item}]`, resource[item]);
				}
			}

			if (files && Object.keys(files).length) {
				for (const key in files) {
					formData.append(`${RESOURCE_NAME}[${key}]`, files[key].file);
				}
			}
			const res = await api.post(`${RESOURCE_URL}`, formData, config);

			if (res.data && res.data.id) {
				setFeedbackDrill(res.data);
				setIsLoaded(true);
				setId(res.data.id);
			}
			hideLoading();
			return res.data;
		} catch (e) {
			hideLoading();
			console.log(e);
			showAlertError('There was an issue saving');
			handleErrors(e);
		}
	};

	const updateFeedbackDrill = async (resource, showLoaders = true) => {
		try {
			showLoading();
			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			};
			let formData = new FormData();
			for (const item in resource) {
				if (item === 'skill_categories') {
					for (const skill of FEEDBACK_DRILL_SKILLS) {
						const feedback_drill_key = Object.keys(skill)[0];
						const feedback_drill_identifier = Object.values(skill)[0].title;
						const relevant_skill = resource[item].filter(
							(v) => v.title === feedback_drill_identifier
						)[0];

						if (relevant_skill) {
							for (const [key, value] of Object.entries(relevant_skill)) {
								formData.append(
									`${RESOURCE_NAME}[${item}][${feedback_drill_key}][${key}]`,
									value
								);
							}
						}
					}
				} else {
					formData.append(`${RESOURCE_NAME}[${item}]`, resource[item] || '');
				}
			}

			const res = await api.put(
				`${RESOURCE_URL}/${resource.id}`,
				formData,
				config
			);

			if (res.data && res.data.id) {
				setFeedbackDrill(res.data);
				setIsLoaded(true);
				setId(res.data.id);
			}
			hideLoading();
			return res.data;
		} catch (e) {
			hideLoading();
			console.log(e);
			showAlertError('There was an issue saving');
			handleErrors(e);
		}
	};

	return {
		feedbackDrillId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		feedbackDrill,
		feedbackDrills,
		findFeedbackDrill,
		findFeedbackDrills,
		saveFeedbackDrill,
		updateFeedbackDrill,
		createFeedbackDrill,
		deleteFeedbackDrill,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		setFeedbackDrill,
		handleSort,
		reloadFeedbackDrill,
		reloadFeedbackDrills
	};
};

export default useFeedbackDrills;
