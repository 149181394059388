import { useResource } from 'hooks';

const useLessons = ({ id, ...props }) => {
	const {
		resourceId: lessonId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: lesson,
		resources: lessons,
		setResource: setLesson,
		findOne: findLesson,
		findMany: findLessons,
		update: updateLesson,
		create: createLesson,
		save: saveLesson,
		destroy: deleteLesson,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reloadOne: reloadLesson,
		reloadMany: reloadLessons,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort
	} = useResource({
		id: id,
		url: '/api/v1/admin/lessons',
		name: 'lesson'
	});

	return {
		lessonId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		lesson,
		lessons,
		setLesson,
		findLesson,
		findLessons,
		saveLesson,
		updateLesson,
		createLesson,
		uploadFile,
		deleteFile,
		deleteLesson,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadLesson,
		reloadLessons
	};
};

export default useLessons;
