import React, { useEffect, useState } from 'react';

import { Container, List, makeStyles, Paper } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Info } from '@material-ui/icons';

import { useAlerts, useCategory, useNavigation, useTrainingPlans } from 'hooks';

import DragableTrainingPlanItem from 'features/trainingPlan/trainingPlan/DragableTrainingPlanItem';
import { TabPanel } from 'components';
import SortableTrainingPlans from 'features/trainingPlan/trainingPlan/SortableTrainingPlans';

const TrainingPlanList = ({ handleAddClick }) => {
	const {
		trainingPlans,
		findTrainingPlans,
		destroyTrainingPlan,
		setTrainingPlans,
		resortTrainingPlans
	} = useTrainingPlans({
		id: null
	});

	const { showAlertSuccess } = useAlerts();

	const { handleClick, handleEditClick } = useNavigation({
		history,
		url: '/training-plans'
	});

	const { findCategories, categories } = useCategory({ id: null });
	const [tabIndex, setTabindex] = useState(0);

	const handleTabChange = (_, newValue) => {
		setTabindex(newValue);
	};

	const onDragEnd = (results) => {
		setTrainingPlans(results);

		resortTrainingPlans(results);
	};

	const handleDeleteClick = async (plan) => {
		await destroyTrainingPlan(plan);
		showAlertSuccess('Training plan has been deleted');
		setTrainingPlans((prev) => prev.filter((item) => item.id !== plan.id));
	};

	useEffect(() => {
		findCategories();
	}, []);
	const classes = useStyles();
	return (
		<>
			<Container maxWidth="lg">
				<div className={classes.infoContainer}>
					<Info color="primary" />
					<div className={classes.infoText}>{'This is experimental'}</div>
				</div>

				<Tabs
					value={tabIndex}
					onChange={handleTabChange}
					aria-label="Training Plan"
				>
					{categories?.length > 0 &&
						categories.map((category) => (
							<Tab key={category.id} label={category.name} />
						))}
				</Tabs>
				{/* commented out the search form intentionally */}
				{/* <SearchForm
            isLoading={isLoading}
            query={query}
            handleSearch={findTrainingPlans}
          /> */}
				<Paper classes={{ root: { padding: 0 } }}>
					{categories?.length > 0 &&
						categories.map((category, index) => (
							<TabPanel value={tabIndex} index={index}>
								<SortableTrainingPlans
									id={`sortable-training-plans-${tabIndex}`}
									key={`training-plans-${tabIndex}`}
									onDragEnd={onDragEnd}
									ListContainer={List}
									category={category}
									findTrainingPlans={findTrainingPlans}
									trainingPlans={trainingPlans}
									handleAddClick={handleAddClick}
									ListItem={({ item }) => (
										<DragableTrainingPlanItem
											key={item.id}
											plan={item}
											handleClick={handleClick}
											handleDeleteClick={handleDeleteClick}
											handleEditClick={handleEditClick}
										/>
									)}
								/>
							</TabPanel>
						))}
				</Paper>
			</Container>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	infoContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		height: 50,
		backgroundColor: '#fff8c4',
		borderRadius: 5,
		gap: 10,
		marginBottom: 30
	},
	infoText: {
		alignItems: 'center',
		color: theme.palette.text.secondary
	}
}));

export default TrainingPlanList;
