import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Container, Alert } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Add } from '@material-ui/icons';

import { Subheader, TabPanel, Wip, StatusMessage } from 'components';
import EventList from 'features/events/EventList';
import PartnersList from 'features/partners/PartnersList';
import { useNavigation } from 'hooks';

const CREATE_BUTTON_CONFIG = [
	{
		url: 'events',
		title: 'Events'
	},
	{
		url: 'partners',
		title: 'Partners'
	}
];

const EventPartners = ({ ...props }) => {
	const navigate = useNavigate();
	const [tabValue, setTabValue] = useState(0);
	const [createConfig, setCreateConfig] = useState(CREATE_BUTTON_CONFIG[0]);
	const [wip, setWip] = React.useState(false);

	const handleTabChange = (_, newValue) => {
		setTabValue(newValue);
		setCreateConfig(CREATE_BUTTON_CONFIG[newValue]);
	};

	const { handleAddClick } = useNavigation({
		history,
		url: `/${createConfig.url}`
	});

	const createButton = useMemo(
		() => (
			<Button
				variant="contained"
				startIcon={<Add />}
				onClick={handleAddClick}
				color="primary"
			>
				{createConfig.title}
			</Button>
		),
		[createConfig.title]
	);

	if (wip) {
		return <Wip />;
	}

	return (
		<>
			<Subheader title="Events & Partners" buttons={[createButton]} />

			<Container>
				<>
					<AppBar
						position="static"
						style={{ background: '#FFF', color: '#000' }}
					></AppBar>
					<Tabs
						value={tabValue}
						onChange={handleTabChange}
						aria-label="EventsPartners Tabs"
					>
						{CREATE_BUTTON_CONFIG.map((button, index) => (
							<Tab key={index} label={button.title} />
						))}
					</Tabs>
				</>
			</Container>
			<div style={{ width: '100%' }}>
				<TabPanel value={tabValue} index={0}>
					<EventList title={'Events'} />
				</TabPanel>
				<TabPanel value={tabValue} index={1}>
					<PartnersList title={'Partner'} />
				</TabPanel>
			</div>
		</>
	);
};

export default EventPartners;
