import { isEmpty } from 'validations';

export const validateCourse = (course) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(course.title)) {
		isValid = false;
		messages.push('Title is required');
	}

	if (isEmpty(course.description)) {
		isValid = false;
		messages.push('Description is required');
	}

	if (isEmpty(course.categories)) {
		isValid = false;
		messages.push('Categories are required');
	}

	if (isEmpty(course.trainer_id)) {
		isValid = false;
		messages.push('Trainer is required');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};
