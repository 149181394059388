import { Box, Button, Container, Paper, Tab, Tabs } from '@material-ui/core';
import { Subheader, StatusMessage } from 'components';
import UserPlayer from 'features/events/user/UserPlayer/UserPlayer';
import Submissions from 'features/events/user/submission/Submissions';
import { useEvents, useAlerts } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

const UserDetailTitle = ({ isLoading, eventSubmission }) => {
	const navigate = useNavigate();

	const id = eventSubmission && eventSubmission.event.id;

	const titleContent = isLoading
		? 'Loading...'
		: `${eventSubmission && eventSubmission.user.first_name} ${eventSubmission && eventSubmission?.user.last_name}`;

	return <div> {titleContent} </div>;
};

const EventUserDetail = ({ ...props }) => {
	const navigate = useNavigate();
	const { id, userId, eventUserId, entryId } = useParams();
	const { showAlertSuccess, showAlertError } = useAlerts();
	const [currentEntryId, setCurrentEntryId] = useState(entryId);
	const [isPreviousDisabled, setIsPreviousDisabled] = useState(false);
	const [isNextDisabled, setIsNextDisabled] = useState(false);

	const {
		isLoading,
		findEventSubmission,
		eventSubmission,
		addToShortlist,
		addEventSubmissionFeedback,
		handleChange,
		event,
		reloadEvent,
		findEvent
	} = useEvents({
		id: id
	});
	useEffect(() => {
		if (id) findEvent(id);
	}, [id]);

	let eventSubmissions = (event && event?.event_submissions) || [];

	const [tabValue, setTabValue] = useState(0);

	const handleTabChange = (ev, value) => {
		setTabValue(value);
	};

	useEffect(() => {
		if (entryId) findEventSubmission(id, entryId);
	}, [entryId, id]);

	const handleShortlist = async (status) => {
		const payload = {
			eventId: id,
			submissionId: entryId,
			params: {
				status: status === 'approved' ? 'pending' : 'approved'
			}
		};

		await addToShortlist(payload)
			.then(() => {
				showAlertSuccess('User added to shortlist!');
			})
			.catch((err) => {
				showAlertError(err.message);
			});
	};

	const handleReloadEvent = () => {
		findEventSubmission(id, entryId);
	};

	const handlePrevious = () => {
		const index = eventSubmissions?.findIndex(
			(submission) => submission.id.toString() === currentEntryId.toString()
		);

		if (index === -1) {
			console.log('Current submission not found');
			return;
		}

		if (index > 0) {
			const previousSubmission = eventSubmissions[index - 1];
			console.log('Previous submission:', previousSubmission);
			setCurrentEntryId(previousSubmission.id);
			navigate(`/events/${id}/entries/${previousSubmission.id}`);
		} else {
			console.log(
				'This is the first submission, no previous submission available'
			);
		}
	};

	const handleNext = () => {
		const index = eventSubmissions?.findIndex(
			(submission) => submission.id.toString() === currentEntryId.toString()
		);

		if (index === -1) {
			return;
		}

		if (index < eventSubmissions.length - 1) {
			const nextSubmission = eventSubmissions[index + 1];
			setCurrentEntryId(nextSubmission.id);
			navigate(`/events/${id}/entries/${nextSubmission.id}`);
		} else {
			console.log('This is the last submission, no next submission available');
		}
	};

	useEffect(() => {
		const index = eventSubmissions?.findIndex(
			(submission) => submission.id.toString() === currentEntryId.toString()
		);

		setIsPreviousDisabled(index <= 0);
		setIsNextDisabled(index >= eventSubmissions.length - 1);
	}, [currentEntryId, eventSubmissions]);

	return (
		<>
			<Subheader
				title={UserDetailTitle({
					isLoading,
					eventSubmission
				})}
				buttons={[
					<Button
						key="previous"
						onClick={handlePrevious}
						color="primary"
						variant="outlined"
						startIcon={<ArrowBackIos />}
						disabled={isPreviousDisabled}
						size="medium"
					>
						Previous Player{' '}
					</Button>,
					<Button
						key="next"
						onClick={handleNext}
						color="primary"
						variant="outlined"
						size="medium"
						disabled={isNextDisabled}
						endIcon={<ArrowForwardIos />}
					>
						Next Player{' '}
					</Button>,
					<Button
						key="edit"
						onClick={() => handleShortlist(eventSubmission?.status)}
						color="primary"
						variant="contained"
						size="medium"
					>
						{eventSubmission?.status === 'approved'
							? 'Unshortlist'
							: 'Add to Shortlist'}
					</Button>
				]}
			/>{' '}
			<Container>
				{' '}
				{eventSubmission?.status === 'approved' && (
					<StatusMessage
						status="success"
						message="User has been added to the shortlist!"
					/>
				)}{' '}
				<Tabs value={tabValue} onChange={handleTabChange}>
					<Tab label="Entry Submission" value={0} />{' '}
					<Tab label="Player Bio" value={1} />{' '}
				</Tabs>{' '}
				<Paper>
					<Box p={4}>
						{' '}
						{tabValue === 0 && (
							<Submissions
								videos={eventSubmission && eventSubmission.submissions}
								handleChange={handleChange}
								reloadEvent={handleReloadEvent}
								event={event}
								addEventSubmissionFeedback={addEventSubmissionFeedback}
							/>
						)}{' '}
						{tabValue === 1 && (
							<UserPlayer user={eventSubmission && eventSubmission.user} />
						)}{' '}
					</Box>{' '}
				</Paper>{' '}
			</Container>{' '}
		</>
	);
};

export default EventUserDetail;
