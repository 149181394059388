import {
	Button,
	Checkbox,
	IconButton,
	Menu,
	MenuItem,
	TableCell,
	TableRow
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import React from 'react';

import { Image } from 'components';
import { useMenu } from 'hooks';
import { removeUnderscoresAndCapitalize } from 'lib/utils';

const UserItem = ({
	user,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleEditClick(user, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(user, e)}>Delete</MenuItem>
		</Menu>
	);

	const isSelected = selectedIds.includes(user.id);

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(user)}
						name="user"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(user)}>
					<Image src={user.avatar_url} variant="rounded" size={80} />
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(user)}>{user.first_name}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(user)}>{user.last_name}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(user)}>{user.email}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(user)}>
					{removeUnderscoresAndCapitalize(user.role)}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(user)}>
					{user.paid ? 'Yes' : '-'}
				</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default UserItem;
