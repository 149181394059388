import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Subheader } from 'components';
import LotteryForm from 'features/lotteries/LotteryForm';
import { useAlerts, useLotteries } from 'hooks';
import { validateLottery } from 'validations/lotteries';

const LotteryEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { lottery, handleChange, updateLottery, findLottery } = useLotteries({
		id: id
	});

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const valid = validateLottery(lottery);
		if (valid.isValid) {
			await updateLottery(lottery);
			showAlertSuccess('Lottery has been updated');
			navigate(`/lotteries/${id}`);
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	useEffect(() => {
		if (id) findLottery(id);
	}, []);

	return (
		<>
			<Subheader
				title="Edit Lottery"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<LotteryForm lottery={lottery} handleChange={handleChange} />
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default LotteryEdit;
