import React from 'react';
import api from 'api';
import { useAlerts, useLoaders, useResource } from 'hooks';
import { validateEditorial } from 'validations/editorials';

const useEditorials = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders();
	const { showAlertError, showAlertSuccess } = useAlerts();

	const {
		resourceId: editorialId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: editorial,
		resources: editorials,
		findOne: findEditorial,
		findMany: findEditorials,
		update: updateEditorial,
		create: createEditorial,
		save: saveEditorial,
		destroy: deleteEditorial,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadEditorial,
		reloadMany: reloadEditorials,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort
	} = useResource({
		id: id,
		url: '/api/v1/admin/editorials',
		name: 'editorial'
	});

	return {
		editorialId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		editorial,
		editorials,
		findEditorial,
		findEditorials,
		saveEditorial,
		updateEditorial,
		createEditorial,
		deleteEditorial,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadEditorial,
		reloadEditorials
	};
};

export default useEditorials;
