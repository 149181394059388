import { Box, Button, Container, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Subheader } from 'components';
import RewardDetails from 'features/rewards/RewardDetails';
import { useRewards } from 'hooks';

const RewardShow = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { isLoading, reward, findReward } = useRewards({ id: id });

	const handleEditClick = () => navigate(`/rewards/${id}/edit`);

	useEffect(() => {
		if (id) findReward(id);
	}, []);

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : reward.title}
				buttons={[
					<Button onClick={handleEditClick} color="primary" variant="contained">
						Edit
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<RewardDetails reward={reward} />
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default RewardShow;
