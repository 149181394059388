import {
	Box,
	Fade,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Paper,
	Slide,
	Typography,
	makeStyles
} from '@material-ui/core';
import { Clear, Info } from '@material-ui/icons';
import { AppContext } from 'context';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import palette from 'theme/palette';

const Alert = () => {
	const [open, setOpen] = useState(false);
	const { alert, setAlert } = useContext(AppContext);

	const propsAlert = useMemo(
		() => ({
			backgroundColor:
				alert?.variant === 'success'
					? palette.primary.main
					: alert?.variant === 'error'
						? palette.error.main
						: palette.error.light,
			color: palette.primary.contrastText
		}),
		[alert]
	);
	const classes = useStyles(propsAlert);

	const handleClose = () => {
		setOpen(false);
		setAlert();
	};

	useEffect(() => {
		if (alert && alert?.message) {
			setOpen(true);
		}
	}, [alert]);

	useEffect(() => {
		if (open) setTimeout(() => setOpen(false), 2500);
	}, [open]);

	return (
		<Fade in={open}>
			<Slide direction="down" in={open}>
				<Box width="100%" p={2} className={classes.root}>
					<Paper className={`${classes.alert}`}>
						<List disablePadding>
							<ListItem button onClick={handleClose}>
								<ListItemIcon>
									<Info className={classes.icon} />
								</ListItemIcon>
								<ListItemText
									primary={
										<Typography variant="body1" className={classes.text}>
											{alert?.message || ''}
										</Typography>
									}
								/>
							</ListItem>
							<ListItemSecondaryAction>
								<IconButton size="small" onClick={handleClose}>
									<Clear className={classes.icon} />
								</IconButton>
							</ListItemSecondaryAction>
						</List>
					</Paper>
				</Box>
			</Slide>
		</Fade>
	);
};

export default Alert;

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		zIndex: 2000,
		width: '100%',
		top: 0,
		left: 0
	},
	alert: (props) => ({
		maxWidth: 600,
		margin: '0 auto',
		backgroundColor: props.backgroundColor,
		color: (props) => props.color
	}),
	text: {
		color: theme.palette.common.white
	},
	icon: {
		color: theme.palette.common.white
	}
}));
