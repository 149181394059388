import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';
import VideoForm from 'features/videos/VideoForm';
import { useAlerts, useUploadFileInMemory, useVideos } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const VideoNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [tags, setTags] = useState();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();

	const {
		video,
		handleChange,
		createVideo,
		findVideo,
		findVideoTags,
		deleteFile
	} = useVideos({
		id: id
	});

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleFindVideoTags = async () => {
		let resp = await findVideoTags();
		let videoTags = resp.map((tag) => ({
			label: tag,
			value: tag
		}));
		setTags(videoTags);
	};

	const handleSaveClick = async () => {
		await createVideo(video, memoryFile);
		showAlertSuccess('Video has been created');
		navigate('/videos');
	};

	useEffect(() => {
		handleFindVideoTags();
	}, []);

	const handleDelete = async (type) => {
		removeFile();
	};

	return (
		<>
			<Subheader
				title="Add Videos"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<VideoForm video={video} tags={tags} handleChange={handleChange} />
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default VideoNew;
