import { List } from '@material-ui/core';
import React from 'react';

import { LineItem } from 'components';
import { properCase } from 'lib/utils';

const ReferralDetails = ({ referral }) => {
	return (
		<List>
			<LineItem
				label="Referrer"
				value={
					referral?.referrer
						? `${referral?.referrer?.first_name} ${referral?.referrer?.last_name}`
						: 'n/a'
				}
			/>
			<LineItem
				label="New User"
				value={
					referral?.new_user
						? `${referral?.new_user?.first_name} ${referral?.new_user?.last_name}`
						: 'n/a'
				}
			/>
			<LineItem label="Points" value={referral.points} />
			<LineItem label="Status" value={properCase(referral.status || '')} />
			<LineItem label="Date Created" value={referral.created_at} />
			<LineItem label="Last Updated" value={referral.updated_at} />
		</List>
	);
};

export default ReferralDetails;
