import { isEmpty } from 'validations';

export const validateReward = (reward) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(reward.title)) {
		isValid = false;
		messages.push('Title is required');
	}

	if (isEmpty(reward.points)) {
		isValid = false;
		messages.push('Points is required');
	}

	if (isEmpty(reward.description)) {
		isValid = false;
		messages.push('Description is required');
	}

	if (isEmpty(reward.status)) {
		isValid = false;
		messages.push('Status is required');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};
