import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { LineItem, Image } from 'components';

const TopicDetails = ({ topic }) => (
	<List>
		<ListItem>
			<img src={topic.thumbnail_url} width={240} />
		</ListItem>
		<ListItem>
			<img src={topic.image_url} width={240} />
		</ListItem>
		<ListItem>
			<img src={topic.web_image_url} width={500} />
		</ListItem>
		<ListItem>
			<img src={topic.web_image2_url} width={500} />
		</ListItem>
		<LineItem label="Order" value={topic.order} />
		<LineItem label="Title" value={topic.title} />
		<LineItem label="Description" value={topic.description} />
	</List>
);

export default TopicDetails;
