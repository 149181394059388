import React from 'react';

import {
	Button,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	TableCell,
	Typography,
	makeStyles,
	TableRow
} from '@material-ui/core';
import { DragIndicator, MoreHoriz } from '@material-ui/icons';

import { useMenu } from 'hooks';

const DragableTrainingPlanItem = ({
	plan,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const classes = useStyles();

	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleEditClick(plan, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(plan, e)}>Delete</MenuItem>
		</Menu>
	);

	const GridItem = ({ name, plan, handleClick, ...rest }) => {
		return (
			<Grid {...rest}>
				<Button onClick={(e) => handleClick(plan, e)} className={classes.item}>
					<Typography variant="body1" className={classes.text}>
						{name}
					</Typography>
				</Button>
			</Grid>
		);
	};

	return (
		<Grid container spacing={1} className={classes.root}>
			<Grid item md={1} xs={12} className={classes.item}>
				<DragIndicator className={classes.icon} />
			</Grid>
			<GridItem
				item
				md={2}
				xs={12}
				name={`TrainingPlan ${plan.position}`}
				plan={plan}
				handleClick={handleClick}
			/>
			<GridItem
				item
				md={2}
				xs={12}
				name={plan.name}
				plan={plan}
				handleClick={handleClick}
			/>
			<GridItem
				item
				md={4}
				xs={12}
				name={plan.description}
				plan={plan}
				handleClick={handleClick}
			/>
			<GridItem
				item
				md={1}
				xs={12}
				name={plan.published ? 'Yes' : 'No'}
				plan={plan}
				handleClick={handleClick}
			/>

			<Grid className={classes.item} item md={1} xs={12}>
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 80,
		paddingLeft: 20,
		paddingRight: 20
	},
	item: { verticalAlign: 'center', '&:hover': { backgroundColor: '#fff' } },
	text: { paddingTop: 20 },
	icon: { color: theme.palette.common.grey, marginLeft: 20, marginTop: 20 },
	button: { paddingTop: 20, paddingBottom: 20 }
}));

export default DragableTrainingPlanItem;
