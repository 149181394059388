import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Subheader } from 'components';
import LotteryForm from 'features/lotteries/LotteryForm';
import { useAlerts, useLotteries } from 'hooks';
import { validateLottery } from 'validations/lotteries';

const LotteryNew = ({ ...props }) => {
	const navigate = useNavigate();

	const { id } = useParams();

	const { showAlertError } = useAlerts();

	const { lottery, handleChange, createLottery, setLottery } = useLotteries({
		id: id
	});

	const handleSaveClick = async () => {
		const valid = validateLottery(lottery);
		if (valid.isValid) {
			await createLottery(lottery);
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const setInitialValues = useCallback(
		() => setLottery((prev) => ({ ...prev, status: 'active' })),
		[]
	);

	useEffect(() => {
		setInitialValues();
	}, [setInitialValues]);

	const navigateAfterCreatingResource = useCallback(() => {
		if (lottery.id) {
			navigate(`/lotteries`);
		}
	}, [lottery]);

	useEffect(() => {
		navigateAfterCreatingResource();
	}, [navigateAfterCreatingResource]);

	return (
		<>
			<Subheader
				title="Add Lottery"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<LotteryForm lottery={lottery} handleChange={handleChange} />
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default LotteryNew;
