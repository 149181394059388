import { isEmpty, isLowerCase } from 'validations';

export const validatePosition = (position) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(position.name)) {
		isValid = false;
		messages.push('Name is required');
	}

	if (isEmpty(position.value)) {
		isValid = false;
		messages.push('Value is required');
	} else if (!isLowerCase(position.value)) {
		isValid = false;
		messages.push('Value must be in lowercase');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};
