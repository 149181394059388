import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const DisplayText = ({ label, text, className }) => {
	const classes = useStyles();

	return (
		<div className={clsx(className?.root, classes.root)}>
			<Typography
				variant="body1"
				color="textSecondary"
				className={clsx(className?.label, classes.label)}
				gutterBottom
			>
				{label}
			</Typography>
			<Typography
				variant="subtitle1"
				color="textPrimary"
				className={clsx(className?.text, classes.text)}
			>
				{text}
			</Typography>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: 32
	},
	label: {},
	text: {}
}));

export default DisplayText;
