import React, { useContext, useEffect } from 'react';
import { useAlerts, useAuth } from 'hooks';
import { AppContext } from 'context';
import { useNavigate, useParams } from 'react-router-dom';
import { Subheader } from 'components';
import { Box, Container } from '@material-ui/core';
import LoginForm from 'features/auth/LoginForm';

const Login = ({ ...props }) => {
	const navigate = useNavigate();

	const { authenticated, currentUser } = useContext(AppContext);

	const { user, handleChange, fetchMe, loginUser } = useAuth();

	const { showAlertError, showAlertSuccess } = useAlerts();

	const handleSubmit = async () => {
		await loginUser(user);
	};

	const handleKeyPress = (event) => {
		if (event.key == 'Enter') handleSubmit();
	};

	return (
		<div>
			<Subheader title="Sign In" />
			<Container maxWidth="xs">
				<Box my={10}>
					<LoginForm
						user={user}
						handleChange={handleChange}
						handleKeyPress={handleKeyPress}
						handleSubmit={handleSubmit}
					/>
				</Box>
			</Container>
		</div>
	);
};

export default Login;
