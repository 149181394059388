import { Grid } from '@material-ui/core';
import { Autosuggest, DatePicker, TextInput } from 'components';
import { PROMO_CODE_STATUS } from 'lib/constants';
import React from 'react';

const PromoCodeForm = ({ promo, promoRules, handleChange }) => {
	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={promo.promo_code || ''}
					label="Promo Code Name"
					name="promo_code"
					placeholder="Promo Code Name"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<DatePicker
					value={promo.expiration_date || ''}
					label="Promo Expiry Date"
					name="expiration_date"
					placeholder="Promo Expiry Date"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Autosuggest
					options={PROMO_CODE_STATUS}
					value={promo.active || true}
					label="Status"
					name="active"
					placeholder=""
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={promo.redeems || ''}
					label="Promo Redeems"
					name="redeems"
					placeholder=""
					handleChange={handleChange}
					type="number"
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Autosuggest
					options={promoRules}
					value={promo.promo_rule_id}
					label="Promo Rule"
					name="promo_rule_id"
					placeholder=""
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={promo.details || ''}
					label="Promo Details"
					name="details"
					placeholder=""
					handleChange={handleChange}
					rows={3}
					multiline
				/>
			</Grid>
		</Grid>
	);
};

export default PromoCodeForm;
