import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
	Box,
	Button,
	Container,
	Grid,
	makeStyles,
	Paper,
	Typography
} from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { LineItem, Subheader, TextInput } from 'components';
import {
	useAlerts,
	useFeedbackAttempts,
	useFeedbackRequests,
	useQueryParams,
	useTrainingPlanDrills,
	useTrainingPlans
} from 'hooks';
import {
	removeUnderscoresAndCapitalize,
	objectMap,
	properCase
} from 'lib/utils';

const FeedbackAttemptShow = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const query = useQueryParams();
	const type = query.get('type');
	const status = query.get('status');

	const classes = styles();
	const { showAlertError, showAlertSuccess } = useAlerts();
	const { feedbackAttempt, findFeedbackAttempt } = useFeedbackAttempts({
		id: id
	});
	const { findFeedbackRequest, feedbackRequest } = useFeedbackRequests({
		url: ''
	});

	const [feedback, setFeedback] = useState({
		comment: '',
		skill_ratings: {}
	});

	const seen = true;

	useEffect(() => {
		if (feedbackRequest && feedbackRequest.feedback)
			setFeedback(feedbackRequest.feedback);
	}, [feedbackRequest]);

	const playercardKeys = [
		'shooting',
		'dribbling',
		'defending',
		'passing',
		'physical',
		'control'
	];

	useEffect(() => {
		if (id) findFeedbackRequest(id, { type, filter: status });
	}, [id]);

	const handleEditClick = () =>
		navigate(`/feedback_attempt/${id}/edit?type=${type}&status=${status}`);

	return (
		<React.Fragment>
			<Subheader
				title={'Feedback Submission'}
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<ArrowRight />}
						onClick={handleEditClick}
						disabled={status === 'viewed'}
					>
						Edit
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<React.Fragment>
							{feedbackRequest?.submissions?.map((submission) => (
								<Grid container key={submission.id}>
									<Grid className={classes.itemContainer} item xs={12} md={6}>
										<center className={classes.placeholder}>
											<Typography variant="caption">Benchmark Video</Typography>
										</center>
										<video
											width="100%"
											height={'90%'}
											controls
											src={submission?.lesson?.video_url}
										/>
									</Grid>
									<Grid className={classes.itemContainer} item xs={12} md={6}>
										<center className={classes.placeholder}>
											<Typography variant="caption">
												Users Uploaded Attempt
											</Typography>
										</center>
										<video
											width="100%"
											height={'90%'}
											controls
											src={submission?.video_url}
										/>
									</Grid>
								</Grid>
							))}

							<Grid item xs={12} sm={12}>
								<Box sx={{ marginTop: 20, marginBottom: 20 }}>
									<Typography variant="h5">User Playercard </Typography>
									<Grid container spacing={2}>
										{playercardKeys.map((key, index) => (
											<Grid
												key={`${key}+${index}`}
												item
												className={classes.playercardItem}
												xs={12}
												sm={3}
											>
												<Typography variant="subtitle1">{key}</Typography>
												<Typography variant="caption">
													{(feedbackRequest?.user_player_card &&
														feedbackRequest?.user_player_card[key]) ||
														'-'}
												</Typography>
											</Grid>
										))}
									</Grid>
								</Box>
							</Grid>

							<Grid item xs={12} sm={12}>
								<Box sx={{ marginTop: 20 }}>
									<Typography variant="h5">Feedback</Typography>
									<TextInput
										value={feedback.comment || ''}
										label=""
										name="comment"
										placeholder="Feedback"
										type={'text'}
										multiline={true}
										rows={10}
										disabled={seen}
									/>
								</Box>

								{feedbackRequest?.category_names?.map((skill, index) => (
									<Box sx={{ marginTop: 20 }} key={index}>
										<Typography variant="h5">{skill}</Typography>
										<TextInput
											key={index}
											value={feedback.skill_ratings[skill.toLowerCase()] || ''}
											label={skill}
											name={skill.toLowerCase()}
											placeholder={skill}
											disabled={seen}
											type={'number'}
										/>
									</Box>
								))}
							</Grid>
						</React.Fragment>
					</Box>
				</Paper>
			</Container>
		</React.Fragment>
	);
};

const styles = makeStyles((theme) => ({
	itemContainer: {
		padding: theme.spacing(2),
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		height: 400
	},
	placeholder: {
		color: theme.palette.text.secondary
	},
	playercardItem: {
		display: 'flex',
		flexDirection: 'column'
	}
}));

export default FeedbackAttemptShow;
