import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const FlexRow = ({ className, children, ...rest }) => {
	const classes = useStyles();

	return (
		<Box
			className={clsx(className, classes.root)}
			display="flex"
			flexDirection="row"
			width="100%"
			{...rest}
		>
			{children}
		</Box>
	);
};

FlexRow.propTypes = {
	className: PropTypes.string,
	children: PropTypes.object.isRequired
};

export default FlexRow;

const useStyles = makeStyles((theme) => ({
	root: {}
}));
