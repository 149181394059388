import React from 'react';
import api from 'api';
import { useAlerts, useLoaders, useResource } from 'hooks';
import { validateUser } from 'validations/users';

const useUsers = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders();
	const { showAlertError, showAlertSuccess } = useAlerts();

	const {
		resourceId: userId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: user,
		resources: users,
		findOne: findUser,
		findMany: findUsers,
		update: updateUser,
		save: saveUser,
		destroy: deleteUser,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadUser,
		reloadMany: reloadUsers,
		paginate,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		setResource: setUser,
		setIsLoaded,
		setId
	} = useResource({
		id: id,
		url: '/api/v1/admin/users',
		name: 'user'
	});

	const resetUser = async (userId) => {
		try {
			showLoading();
			await api.post(`/api/v1/admin/users/${userId}/reset`);
			showAlertSuccess('Account successfully updated');
		} catch (e) {
			showAlertError('There was an error resetting');
		}
		hideLoading();
	};

	const updateUserStatus = async (userId, status) => {
		try {
			showLoading();
			await api.post(`/api/v1/admin/users/${userId}/update_status`, {
				status
			});
			showAlertSuccess('Account successfully updated');
		} catch (e) {
			showAlertError('There was an error updating the status');
		}
		hideLoading();
	};

	const createUser = async (resource) => {
		try {
			showLoading();
			const res = await api.post('/api/v1/admin/users', {
				user: resource
			});
			hideLoading();
			if (res.data && res.data.id) {
				setUser(res.data);
				setIsLoaded(true);
				setId(res.data.id);
			}
			return res.data;
		} catch (e) {
			showAlertError('There was an issue updating');
		}
	};

	return {
		userId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		currentUser: user,
		user,
		users,
		findUser,
		findUsers,
		saveUser,
		updateUser,
		createUser,
		deleteUser,
		uploadFile,
		deleteFile,
		resetUser,
		handleChange,
		handleChangePage,
		updateUserStatus,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		reloadUser,
		reloadUsers,
		sortKey,
		sortDirection,
		handleSort,
		setUser
	};
};

export default useUsers;
