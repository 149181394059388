import React, { useState } from 'react';

import { useResource } from 'hooks';
import useResourceLocal from './useResourceLocal';

const useLotteries = ({ id, ...props }) => {
	const [sortKey, _] = useState(`lotteries.id`);
	const [filters, setFilters] = React.useState({
		status: undefined
	});

	const resetFilters = () => {
		setFilters({
			status: undefined
		});
	};

	const {
		resourceId: lotteryId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: lottery,
		resources: lotteries,
		findOne: findLottery,
		findMany: findLotteries,
		update: updateLottery,
		create: createLottery,
		save: saveLottery,
		destroy: deleteLottery,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		refresh: reloadLottery,
		refreshMany: reloadLotteries,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortDirection,
		handleSort,
		setResource: setLottery
	} = useResource({
		id: id,
		url: '/api/v1/admin/lotteries',
		name: 'lottery'
	});

	return {
		lotteryId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		lottery,
		lotteries,
		findLottery,
		findLotteries,
		saveLottery,
		updateLottery,
		createLottery,
		deleteLottery,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadLottery,
		reloadLotteries,
		filters,
		setFilters,
		resetFilters,
		setLottery
	};
};

export default useLotteries;
