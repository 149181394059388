import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';
import UserForm from 'features/users/UserForm';
import { useAlerts, useUsers } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const UserNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { userId, isLoading, isLoaded, user, createUser, findUser, setUser } =
		useUsers({ id: id });

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		await createUser(user);
		showAlertSuccess('User has been created');
		navigate('/users');
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		const [parent, nested] = name.split('.');

		setUser((prevState) =>
			nested
				? {
						...prevState,
						[parent]: {
							...prevState[parent],
							[nested]: value
						}
					}
				: {
						...prevState,
						[parent]: value
					}
		);
	};
	useEffect(() => {
		if (id) findUser(id);
	}, []);

	return (
		<>
			<Subheader
				title="Add Users"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<UserForm user={user} handleChange={handleChange} />
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default UserNew;
