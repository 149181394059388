import { isEmpty } from 'validations';

export const validateLesson = (lesson) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(lesson.title)) {
		isValid = false;
		messages.push('Title is required');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};
