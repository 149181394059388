import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader } from 'components';
import ClubForm from 'features/clubs/ClubForm';
import { useAlerts, useClubs } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ClubEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const {
		clubId,
		isLoading,
		isLoaded,
		club,
		handleChange,
		updateClub,
		findClub,
		uploadFile,
		deleteFile
	} = useClubs({
		id: id
	});

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		await updateClub(club);
		showAlertSuccess('Club has been updated');
		navigate('/clubs');
	};

	const handleUpload = async (file, type) => {
		await uploadFile(file, type);
		findClub(id);
	};

	const handleDelete = async (type) => {
		await deleteFile(type);
		findClub(id);
	};

	useEffect(() => {
		if (id) findClub(id);
	}, []);

	return (
		<>
			<Subheader
				title="Edit Club"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<center>
							<ImageUploader
								label="Upload image (2:3)"
								imageUrl={club.image_url}
								handleUpload={(file) => handleUpload(file, 'image_url')}
								handleDelete={() => handleDelete('image_url')}
							/>
						</center>
						<ClubForm club={club} handleChange={handleChange} />
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default ClubEdit;
