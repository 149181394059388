import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { LineItem, Image } from 'components';

const PositionDetails = ({ position }) => (
	<List>
		<LineItem label="Name" value={position.name} />
		<LineItem label="Value" value={position.value} />
		<LineItem label="Description" value={position?.description} />
		<LineItem label="Created At" value={position.created_at} />
	</List>
);

export default PositionDetails;
