import { Grid } from '@material-ui/core';
import React from 'react';

import { Autosuggest, UserAutosuggest } from 'components';
import { LOTTERY_DRAW_STATUS } from 'lib/constants';

const LotteryDrawForm = ({ lotteryDraw, handleChange }) => {
	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<UserAutosuggest
					value={lotteryDraw?.user?.id}
					label="User"
					name="user_id"
					placeholder="Select user"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12}>
				<Autosuggest
					options={LOTTERY_DRAW_STATUS}
					value={lotteryDraw.status || []}
					label="Status"
					name="status"
					placeholder=""
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	);
};

export default LotteryDrawForm;
