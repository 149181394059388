import { isEmpty } from 'validations';

export const triviaAnswerValidation = (answers = [], media_type) => {
	let isValid = true;
	let messages = [];

	answers.forEach((answer, index) => {
		if (media_type === 'text' && isEmpty(answer.answer)) {
			isValid = false;
			messages.push(`Answer ${index + 1} is required`);
		}

		if (isEmpty(answer.file) && media_type !== 'text') {
			isValid = false;
			messages.push(`Answer ${index + 1} is required`);
		}
	});

	return {
		isValid,
		messages
	};
};
