import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, SubheaderV2 } from 'components';
import {
	useAlerts,
	useSkillCategories,
	useTrainingPlanDrills,
	useUploadFileInMemory
} from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TrainingPlanForm from 'features/trainingPlan/TrainingPlanForm';
import TrainingPlanDrillForm from 'features/trainingPlan/trainingPlanDrill/TrainingPlamDrillForm';
import { trainingPlanValidation } from 'validations/trainingPlan';
import { filterNonNullValues } from 'lib/utils';

const TrainingPlanDrillEdit = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { showAlertError } = useAlerts();
	const {
		findTrainingPlanDrill,
		trainingPlanDrill,
		handleChange,
		updateTrainingPlanDrill
	} = useTrainingPlanDrills({ id: id });
	const { categories, findCategories } = useSkillCategories();
	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();

	const handleSaveClick = async () => {
		const drill = filterNonNullValues(trainingPlanDrill);
		const response = await updateTrainingPlanDrill(drill, memoryFile);

		if (response.status && response.status !== 200) {
			const { message } = response.data;
			showAlertError(message);
			return;
		}
		navigate('/training-plan-drills');
	};

	const handleUpload = async (file, type) => {
		await handleUploadFile(file, type);
	};

	const handleDelete = async () => {
		await removeFile();
	};

	useEffect(() => {
		if (id) findTrainingPlanDrill(id);
	}, [id]);

	useEffect(() => {
		findCategories();
	}, []);

	return (
		<>
			<SubheaderV2
				title="Edit Benchmark Drill"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Update Benchmark Drill
					</Button>
				]}
			/>

			<Container>
				<Box p={4}>
					<TrainingPlanDrillForm
						trainingPlan={trainingPlanDrill}
						handleChange={handleChange}
						categories={categories}
						memoryFile={memoryFile}
						handleUpload={handleUpload}
						handleDelete={handleDelete}
					/>
				</Box>
			</Container>
		</>
	);
};

export default TrainingPlanDrillEdit;
