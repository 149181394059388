import { List } from '@material-ui/core';
import { LineItem } from 'components';
import React from 'react';

const PromoRuleDetails = ({ promo }) => {
	return (
		<List>
			<LineItem value={promo.name} label={'Name'} />
			<LineItem value={promo.description} label={'Description'} />
			<LineItem value={promo.user_description} label={'User Description'} />
			<LineItem value={promo.duration} label={'Duration'} />
		</List>
	);
};

export default PromoRuleDetails;
