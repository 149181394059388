import {
	Button,
	Checkbox,
	IconButton,
	Menu,
	MenuItem,
	TableCell,
	TableRow
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { Modal } from 'components';
import { useMenu } from 'hooks';
import React from 'react';

const PartnerItem = ({
	partner,
	isSelectable,
	selectedIds = [],
	handleClick,
	handleEditClick,
	deletePartner,
	reloadPartners,
	showAlertSuccess,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();
	const [openModal, setOpenModal] = React.useState(false);

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={() => handleEditClick(partner)}>Edit</MenuItem>
			<MenuItem onClick={() => handleOpenModal()}>Delete</MenuItem>
		</Menu>
	);

	const handleDeleteClick = async (partner) => {
		await deletePartner(partner);
		showAlertSuccess('Partner has been deleted successfully.');
		handleCloseModal();
		reloadPartners();
	};

	const isSelected = selectedIds.includes(partner.id);

	return (
		<>
			<TableRow>
				{isSelectable && (
					<TableCell>
						<Checkbox
							checked={isSelected ? true : false}
							name="event"
							color="primary"
						/>
					</TableCell>
				)}
				<TableCell>
					<Button onClick={() => handleClick(partner)}>{partner.name}</Button>
				</TableCell>
				<TableCell>
					<Button onClick={() => handleClick(partner)}>{partner.email}</Button>
				</TableCell>
				<TableCell align="right">
					<IconButton onClick={(e) => handleToggleMenu(e)}>
						<MoreHoriz />
						{renderMenu()}
					</IconButton>
				</TableCell>
			</TableRow>
			<Modal
				open={openModal}
				handleClose={handleCloseModal}
				title="Delete Partner"
				subtitle={`Are you sure you want to delete the partner ${partner.name}?`}
				actions={
					<>
						<Button color="primary" onClick={handleCloseModal}>
							Cancel
						</Button>
						<Button
							color="primary"
							variant="outlined"
							onClick={() => handleDeleteClick(partner)}
						>
							Delete
						</Button>
					</>
				}
			/>
		</>
	);
};

export default PartnerItem;
