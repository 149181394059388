import { List } from '@material-ui/core';
import React from 'react';

import { LineItem } from 'components';
import { properCase } from 'lib/utils';

const RewardDetails = ({ reward }) => {
	return (
		<List>
			<LineItem label="Title" value={reward.title} />
			<LineItem label="Points" value={reward.points} />
			<LineItem label="Description" value={reward.description} />
			<LineItem label="Status" value={properCase(reward.status || '')} />
			<LineItem label="Date Created" value={reward.created_at} />
			<LineItem label="Last Updated" value={reward.updated_at} />
		</List>
	);
};

export default RewardDetails;
