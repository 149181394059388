import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';

import { useAlerts, useCategory } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateCategory } from 'validations/category';
import CategoryForm from './CategoryForm';

const CategoryEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const {
		categoryId,
		isLoading,
		isLoaded,
		category,
		handleChange,
		updateCategory,
		findCategory
	} = useCategory({ id: id });

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const valid = validateCategory(category);
		if (valid.isValid) {
			await updateCategory(category);
			showAlertSuccess('Category has been updated');
			navigate(`/categories/${id}`);
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	useEffect(() => {
		if (id) findCategory(id);
	}, []);

	return (
		<>
			<Subheader
				title="Edit Category"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<CategoryForm category={category} handleChange={handleChange} />
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default CategoryEdit;
