import { makeStyles } from '@material-ui/core';
import React from 'react';

import LogoSvg from 'assets/logo.svg';

const Logo = () => {
	const classes = useStyles();
	return <img src={LogoSvg} className={classes.logo} />;
};

const useStyles = makeStyles((theme) => ({
	logo: {
		width: 200
	}
}));

export default Logo;
