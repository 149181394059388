import React from 'react';

import { Grid } from '@material-ui/core';

import { Autosuggest, MultiAutosuggest, TextInput } from 'components';
import { FEEDBACK_DRILL_SKILLS, FEEDBACK_DRILL_STATUS } from 'lib/constants';

const FeedbackDrillForm = ({ feedbackDrill, isEditing, handleChange }) => {
	const feedback_skills = React.useMemo(
		() =>
			FEEDBACK_DRILL_SKILLS.map((item) => {
				const values = Object.values(item)[0];
				return {
					value: { ...values },
					label: values.skill.slice(0, 1).toUpperCase() + values.skill.slice(1)
				};
			}),
		FEEDBACK_DRILL_SKILLS
	);

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={feedbackDrill.title || ''}
					label="Title"
					name="title"
					placeholder="Title"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					rows={3}
					multiline
					value={feedbackDrill.description || ''}
					label="Description"
					name="description"
					placeholder="Description"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={feedbackDrill.video_url || ''}
					label="Video URL"
					name="video_url"
					placeholder="Video URL"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12}>
				<MultiAutosuggest
					options={feedback_skills}
					value={feedbackDrill.skill_categories || []}
					label="Skill Categories"
					name="skill_categories"
					placeholder=""
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={feedbackDrill.video_quality || 100}
					label="Video Quaity"
					name="video_quality"
					placeholder="Video Quality"
					type={'number'}
					inputProps={{ min: 0, max: 100 }}
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={feedbackDrill.video_modifier_main}
					label="Video Modifier Main"
					name="video_modifier_main"
					placeholder="Enter a Cloudinary modifiation"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={feedbackDrill.video_modifier_clip}
					label="Video Modifier Clip"
					name="video_modifier_clip"
					placeholder="Enter a Cloudinary modifiation"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={feedbackDrill.video_modifier_thumbnail}
					label="Video Modifier Thumbnail"
					name="video_modifier_thumbnail"
					placeholder="Enter a Cloudinary modifiation"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12}>
				<Autosuggest
					options={FEEDBACK_DRILL_STATUS}
					value={feedbackDrill.status || []}
					label="Status"
					name="status"
					placeholder=""
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	);
};

export default FeedbackDrillForm;
