import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader } from 'components';
import TrainerForm from 'features/trainers/TrainerForm';
import { useAlerts, useTrainers, useUploadFileInMemory } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateTrainer } from 'validations/trainers';

const TrainerNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const { trainer, handleChange, createTrainer, findTrainer } = useTrainers({
		id: id
	});

	const handleSaveClick = async () => {
		const valid = validateTrainer(trainer);
		if (valid.isValid) {
			await createTrainer(trainer, memoryFile);
			showAlertSuccess('Trainer has been created');
			navigate('/trainers');
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleDeleteImage = () => {
		removeFile();
	};

	useEffect(() => {
		if (id) findTrainer(id);
	}, []);

	return (
		<>
			<Subheader
				title="Add Trainers"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<center>
						<ImageUploader
							label="Upload image (2:3)"
							imageUrl={trainer.image_url || memoryFile?.image_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'image_url')}
							handleDelete={handleDeleteImage}
						/>
					</center>
					<TrainerForm trainer={trainer} handleChange={handleChange} />
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default TrainerNew;
