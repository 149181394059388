import { Grid } from '@material-ui/core';
import fp from 'lodash/fp';
import React from 'react';

import { DisplayImage, DisplayText } from 'components';

const CourseDetails = ({ course }) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<DisplayText label={'Course Title'} text={course?.title} />
			<DisplayText label={'Course Description'} text={course?.description} />
		</Grid>
		<Grid item xs={12} md={4}>
			<DisplayText
				label={'Level'}
				text={fp.compose(
					fp.join(' '),
					fp.map((x) => fp.capitalize(x)),
					fp.split('_')
				)(course?.level)}
			/>
		</Grid>
		<Grid item xs={12} md={4}>
			<DisplayText
				label={'Position'}
				text={fp.compose(
					fp.join(', '),
					fp.map((x) =>
						fp.compose(
							fp.join(' '),
							fp.map((y) => fp.capitalize(y)),
							fp.split('_')
						)(x)
					)
				)(course?.positions)}
			/>
		</Grid>
		<Grid item xs={12} md={4}>
			<DisplayText
				label={'Trainer'}
				text={course?.trainer?.first_name + ' ' + course?.trainer?.last_name}
			/>
		</Grid>
		<Grid item xs={12} md={4}>
			<DisplayText
				label={'Skill Category'}
				text={fp.compose(
					fp.join(' '),
					fp.map((y) => fp.capitalize(y)),
					fp.split('_'),
					fp.head
				)(course?.categories)}
			/>
		</Grid>
		<Grid item xs={12} md={4}>
			<DisplayText
				label={'Featured Course'}
				text={course?.featured ? 'Yes' : 'No'}
			/>
		</Grid>
		<Grid item xs={12} md={4}>
			<DisplayText
				label={'Publised Course'}
				text={course?.published ? 'Yes' : 'No'}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<DisplayImage
				label={'Course Image'}
				url={course?.image_url}
				width={'33.3%'}
			/>
		</Grid>
	</Grid>
);

export default CourseDetails;
