import fetchData from './fetchData';
import { extractIdFromUrl } from '../../utils/index';

/**
 * fileApi
 *
 * Replaces axios so that data can be read from the /api/data directory.
 *
 * This enables frontend development without the requirement for a working
 * backend endpoint.
 */

const fileApi = () => {};

fileApi.get = (url) => {
	const { id, resource } = extractIdFromUrl(url);
	return Promise.resolve({ data: fetchData(id, resource) });
};

fileApi.post = (url, input) => {
	return Promise.resolve({
		data: { id: '123', ...input }
	});
};

fileApi.put = (url, input) => {
	const { id } = extractIdFromUrl(url);
	return Promise.resolve({
		data: { id, ...input }
	});
};

fileApi.delete = (url) => {
	const { id } = extractIdFromUrl(url);
	return Promise.resolve({ data: { message: `record with id ${id} deleted` } });
};

export default fileApi;
