import React, { useEffect } from 'react';
import { useArticles, useAlerts, useNavigation, useSelected } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { SortableTableHeader, Subheader, Placeholder } from 'components';
import Pagination from '@material-ui/lab/Pagination';
import {
	Container,
	Box,
	Paper,
	Button,
	Table,
	TableHead,
	TableContainer,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import ArticleItem from 'features/articles/ArticleItem';
import ArticleToolbar from 'features/articles/ArticleToolbar';
import SearchForm from 'features/articles/SearchForm';

const TABLE_HEADERS = [
	{
		value: 'image_url',
		label: 'Image',
		sortable: true,
		hidden: false
	},
	{
		value: 'title',
		label: 'Title',
		sortable: true,
		hidden: false
	},
	{
		value: 'category',
		label: 'Category',
		sortable: true,
		hidden: false
	},
	{
		value: 'published_at',
		label: 'Published at',
		sortable: false,
		hidden: false
	}
];

const ArticleList = ({ ...props }) => {
	const navigate = useNavigate();
	const {
		isLoading,
		isLoaded,
		isEmpty,
		articles,
		findArticles,
		deleteArticle,
		paginate,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadArticles,
		sortKey,
		sortDirection,
		handleSort
	} = useArticles({});

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/articles'
	});

	const {
		selected,
		selectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected
	} = useSelected();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleDeleteClick = async (article) => {
		await deleteArticle(article);
		showAlertSuccess('Article has been deleted');
		reloadArticles();
	};

	useEffect(() => {
		findArticles(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [query, page, sortKey, sortDirection]);

	return (
		<>
			<Subheader
				title="Articles"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						Article
					</Button>
				]}
			/>
			<Container maxWidth="lg">
				<>
					<Paper>
						<SearchForm
							isLoading={isLoading}
							query={query}
							handleSearch={findArticles}
						/>
						{!isEmpty ? (
							<TableContainer component={Paper} elevation={0}>
								<Table>
									<TableHead>
										<TableRow>
											{TABLE_HEADERS.filter((h) => !h.hidden).map(
												(header, idx) => (
													<SortableTableHeader
														key={idx}
														handleSort={handleSort}
														value={`articles.${header.value}`}
														sortable={header.sortable}
														sortKey={sortKey}
														sortDirection={sortDirection}
													>
														{header.label}
													</SortableTableHeader>
												)
											)}
											<TableCell></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{articles.map((article, idx) => (
											<ArticleItem
												key={idx}
												article={article}
												isSelectable={false}
												selectedIds={selectedIds}
												handleSelect={handleSelect}
												handleClick={handleClick}
												handleDeleteClick={handleDeleteClick}
												handleEditClick={handleEditClick}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<Placeholder
								icon={<Search />}
								title="No articles"
								subtitle="Please try another search"
							/>
						)}
					</Paper>
					{!isEmpty && (
						<Box my={1}>
							<Pagination
								onChange={(ev, page) => paginate(page)}
								color="primary"
								size="small"
								shape="rounded"
								page={page}
								count={numPages}
							/>
						</Box>
					)}
				</>
				<ArticleToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	);
};

export default ArticleList;
