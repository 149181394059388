import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader } from 'components';
import ClubForm from 'features/clubs/ClubForm';
import { useAlerts, useClubs, useUploadFileInMemory } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ClubNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();

	const { club, handleChange, createClub, findClub, deleteFile } = useClubs({
		id: id
	});

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		await createClub(club, memoryFile);
		showAlertSuccess('Club has been created');
		navigate('/clubs');
	};

	useEffect(() => {
		if (id) findClub(id);
	}, []);

	const handleDelete = async (type) => {
		removeFile();
	};

	return (
		<>
			<Subheader
				title="Add Clubs"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<center>
						<ImageUploader
							label="Upload image (2:3)"
							imageUrl={club.image_url || memoryFile?.image_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'image_url')}
							handleDelete={() => handleDelete('image_url')}
						/>
					</center>
					<ClubForm club={club} handleChange={handleChange} />
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default ClubNew;
