import { Grid } from '@material-ui/core';
import React from 'react';

import { Autosuggest, TextInput } from 'components';
import { REWARD_STATUS } from 'lib/constants';

const RewardForm = ({ reward, isEditing, handleChange }) => {
	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={reward.title || ''}
					label="Title"
					name="title"
					placeholder="Title"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={reward.points || ''}
					label="Points"
					name="points"
					placeholder="Points"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					rows={3}
					multiline
					value={reward.description || ''}
					label="Description"
					name="description"
					placeholder="Description"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12}>
				<Autosuggest
					options={REWARD_STATUS}
					value={reward.status || []}
					label="Status"
					name="status"
					placeholder=""
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	);
};

export default RewardForm;
