import React, { useState } from 'react';
import api from 'api';
import { useAlerts, useLoaders, useResource } from 'hooks';
import { validateEvent } from 'validations/events';

const useEvents = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders();
	const { showAlertError, showAlertSuccess } = useAlerts();
	const [eventSubmission, setEventSubmission] = useState(null);
	const [eventFeedback, setEventFeedback] = useState(null);

	const RESOURCE_URL = '/api/v1/admin/events';
	const RESOURCE_URL_SUBMISSIONS = '/api/v1/admin';

	const {
		resourceId: eventId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: event,
		resources: events,
		findOne: findEvent,
		findMany: findEvents,
		update: updateEvent,
		create: createEvent,
		save: saveEvent,
		destroy: deleteEvent,
		handleChange,
		clearResource,
		handleChangePage,
		uploadFile,
		deleteFile,
		reloadOne: reloadEvent,
		reloadMany: reloadEvents,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort
	} = useResource({
		id: id,
		url: RESOURCE_URL,
		name: 'event'
	});

	const fetchEventUsers = async (id) => {
		try {
			showLoading();

			const res = await api.get(`${RESOURCE_URL}/id/event_users`);
			hideLoading();
			return res;
		} catch (e) {
			console.log('error');
		}
		hideLoading();
	};

	const notifyEventUsers = async (id) => {
		try {
			showLoading();

			const res = await api.post(`${RESOURCE_URL}/${id}/notify`);
			hideLoading();
			return res;
		} catch (e) {
			console.log('error');
		}
		hideLoading();
	};

	const findEventSubmission = async (eventId, submissionId) => {
		try {
			showLoading();
			const res = await api.get(
				`${RESOURCE_URL}/${eventId}/event_submissions/${submissionId}`
			);
			setEventSubmission(res.data);
			hideLoading();
			return res;
		} catch (e) {
			console.log('error');
		}
		hideLoading();
	};

	const findEventSubmissionWithoutSubmissionId = async (eventId) => {
		try {
			showLoading();
			const res = await api.get(`${RESOURCE_URL}/${eventId}/event_submissions`);
			setEventSubmission(res.data);
			hideLoading();
			return res;
		} catch (e) {
			console.log('error');
		}
		hideLoading();
	};

	const addToShortlist = async (props) => {
		const { eventId, submissionId, params } = props;
		try {
			showLoading();
			const res = await api.put(
				`${RESOURCE_URL}/${eventId}/event_submissions/${submissionId}`,
				params
			);
			setEventSubmission(res.data);
			hideLoading();
			return res;
		} catch (e) {
			console.log('error');
		}
		hideLoading();
	};

	const addEventSubmissionFeedback = async (props) => {
		const { submissionId, params } = props;
		try {
			showLoading();
			const res = await api.post(
				`${RESOURCE_URL_SUBMISSIONS}/submissions/${submissionId}/feedback`,
				params
			);
			setEventFeedback(res.data);
			hideLoading();
			return res;
		} catch (e) {
			console.log('error ', e);
		}
		hideLoading();
	};

	return {
		eventId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		event,
		events,
		findEvent,
		findEvents,
		saveEvent,
		updateEvent,
		createEvent,
		deleteEvent,
		uploadFile,
		deleteFile,
		handleChange,
		clearResource,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadEvent,
		reloadEvents,
		fetchEventUsers,
		notifyEventUsers,
		findEventSubmission,
		eventSubmission,
		addToShortlist,
		eventFeedback,
		addEventSubmissionFeedback,
		findEventSubmissionWithoutSubmissionId
	};
};

export default useEvents;
