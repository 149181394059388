import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { LineItem } from 'components';

const ArticleDetails = ({ article }) => (
	<List>
		<ListItem>
			<img src={article.image_url} width={240} />
		</ListItem>
		<ListItem>
			<img src={article.image2_url} width={240} />
		</ListItem>
		<ListItem>
			<img src={article.web_image_url} width={500} />
		</ListItem>
		<ListItem>
			{article.video_url && (
				<video
					src={article.video_url}
					controls
					autoPlay
					muted
					height="240"
					width="auto"
				/>
			)}
		</ListItem>
		<LineItem label="Title" value={article.title} />
		<LineItem label="Subtitle" value={article.subtitle} />
		<LineItem label="Category" value={article.category} />
		{article.jw_captions &&
			Object.keys(article.jw_captions).map((language, i) => (
				<LineItem
					key={i}
					label={`${language} captions`}
					value={article?.jw_captions[language]}
				/>
			))}
		<LineItem label="JW Player ID" value={article?.jw_player_id} />
		<LineItem label="Published at" value={article.published_at} />
		<LineItem label="Body" value={article.body} />
		<LineItem label="Author" value={article.author?.name} />
	</List>
);

export default ArticleDetails;
