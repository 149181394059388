import {
	Box,
	Button,
	Container,
	Paper,
	Typography,
	Tabs
} from '@material-ui/core';
import { Subheader } from 'components';
import EventUserList from 'features/events/entries/EventUserList';
import { useEvents, usePartners } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PartnerDetail from './detail/PartnerDetail';
import PartnerEvents from './detail/PartnerEvents';

const PartnerShow = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { isLoading, partner, findPartner } = usePartners({
		id: id
	});

	const { events, findEvents, query, page, sortKey, sortDirection } = useEvents(
		{}
	);

	const [tabValue, setTabValue] = useState(0);

	const handleTabChange = (ev, value) => {
		setTabValue(value);
	};

	const handleEditClick = () => navigate(`/partners/${id}/edit`);

	useEffect(() => {
		if (id) findPartner(id);
	}, []);

	useEffect(() => {
		findEvents(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [query, page, sortKey, sortDirection]);

	const filteredEvents = events.filter(
		(event) => event.partner?.id === Number(id)
	);

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : `${partner.name}`}
				buttons={[
					<Button onClick={handleEditClick} color="primary" variant="contained">
						Edit
					</Button>
				]}
			/>
			<Container>
				<Typography variant="h6">Partner Details</Typography>
				<Paper>
					<Box p={4}>
						<PartnerDetail partner={partner} />
					</Box>
				</Paper>

				<Box paddingTop={5}>
					<PartnerEvents partner={partner || {}} events={filteredEvents} />
				</Box>
			</Container>
		</>
	);
};

export default PartnerShow;
