export const LESSONABLE_TYPE = {
	TRAINING_PLAN: 'TrainingPlan',
	TRAINING_PLAN_DRILL: 'TrainingPlanDrill'
};

export const TRAINING_PLAN_TRIVIA_MEDIA_TYPES = [
	{ value: 'image', label: 'Image' },
	{ value: 'video', label: 'Video' },
	{ value: 'text', label: 'Text' }
];
