import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader } from 'components';
import TrainerForm from 'features/trainers/TrainerForm';
import { useAlerts, useTrainers } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateTrainer } from 'validations/trainers';

const TrainerEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const {
		trainerId,
		isLoading,
		isLoaded,
		trainer,
		handleChange,
		updateTrainer,
		findTrainer,
		uploadFile,
		deleteFile
	} = useTrainers({ id: id });

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const valid = validateTrainer(trainer);
		if (valid.isValid) {
			await updateTrainer(trainer);
			showAlertSuccess('Trainer has been updated');
			navigate(`/trainers/${id}`);
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleUploadImage = async (file) => {
		await uploadFile(file, 'image_url');
		findTrainer(id);
	};

	const handleDeleteImage = async (file) => {
		await deleteFile('image_url');
		findTrainer(id);
	};

	useEffect(() => {
		if (id) findTrainer(id);
	}, []);

	return (
		<>
			<Subheader
				title="Edit Trainer"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<center>
							<ImageUploader
								label="Upload image (2:3)"
								imageUrl={trainer.image_url}
								handleUpload={handleUploadImage}
								handleDelete={handleDeleteImage}
							/>
						</center>
						<TrainerForm trainer={trainer} handleChange={handleChange} />
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default TrainerEdit;
