import { isEmpty } from 'validations';

export const validateTopic = (topic) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(topic.title)) {
		isValid = false;
		messages.push('Title is required');
	}

	if (isEmpty(topic.description)) {
		isValid = false;
		messages.push('Description is required');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};
