import api from 'api';
import { useResource } from 'hooks';

const useCourses = ({ id, ...props }) => {
	const {
		resourceId: courseId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: course,
		resources: courses,
		findOne: findCourse,
		findMany: findCourses,
		update: updateCourse,
		create: createCourse,
		save: saveCourse,
		destroy: deleteCourse,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reloadOne: reloadCourse,
		reloadMany: reloadCourses,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort
	} = useResource({
		id: id,
		url: '/api/v1/admin/courses',
		name: 'course'
	});

	const resortLessons = (lessons) => {
		const sorted = lessons.map((item) => ({
			id: item.id,
			position: item.position
		}));
		api.post(`/api/v1/admin/courses/${course.id}/resort`, {
			lessons: sorted
		});
		return sorted;
	};

	return {
		courseId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		course,
		courses,
		findCourse,
		findCourses,
		saveCourse,
		updateCourse,
		createCourse,
		deleteCourse,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadCourse,
		reloadCourses,
		resortLessons
	};
};

export default useCourses;
