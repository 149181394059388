import { isEmpty } from 'validations';

export const validateEditorial = (editorial) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(editorial.title)) {
		isValid = false;
		messages.push('Title is required');
	}

	if (isEmpty(editorial.description)) {
		isValid = false;
		messages.push('Description is required');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};
