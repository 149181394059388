import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader } from 'components';
import EventForm from 'features/events/EventForm';
import {
	useAlerts,
	useEvents,
	usePartners,
	useUploadFileInMemory
} from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateEvent } from 'validations/events';

const EventNew = ({ ...props }) => {
	const navigate = useNavigate();

	const { partnerId } = useParams();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();
	const {
		partners,
		findPartners,
		query,
		page,
		sortKey,
		sortDirection,
		handleSort
	} = usePartners({});

	const { event, createEvent, handleChange, findEvent, deleteFile } = useEvents(
		{}
	);

	const { showAlertSuccess, showAlertError } = useAlerts();

	const [eventVideos, setEventVideo] = React.useState([]);
	const [videoData, setVideoData] = React.useState([]);

	const handleSaveClick = async () => {
		const valid = await validateEvent(event);

		// console.log('event ', { ...event, partner_id: partnerId });
		let eventPartner = { ...event, partner_id: partnerId };
		if (!memoryFile) {
			showAlertError('Please upload an image');
		} else if (valid.isValid) {
			const newEvent = await createEvent(
				partnerId ? eventPartner : event,
				memoryFile
			);
			showAlertSuccess('Event has been created');
			navigate(`/events/${newEvent.id}/edit`);
		} else {
			showAlertError(valid.messages.join(', '));
		}
	};

	useEffect(() => {
		findPartners(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [query, page, sortKey, sortDirection]);

	const handleAddVideo = () => {
		handleChange({
			...event,
			event_videos: [...event.event_videos, { title: '', url: '' }]
		});
	};

	const handleVideoChange = (e, index, type) => {
		const newValue = type === 'title' ? e.target.value : e.target.files[0];

		setVideoData((prevData) => {
			const updatedData = [...prevData];
			updatedData[index] = {
				...updatedData[index],
				[type]: newValue
			};
			return updatedData;
		});

		if (type === 'file') {
			handleUploadFile(newValue, `video_url-${index}`);
		}
	};

	const handleDeleteImage = () => {
		removeFile();
	};

	return (
		<>
			<Subheader
				title="Add Events"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<EventForm
						event={event}
						eventVideos={eventVideos}
						handleChange={handleChange}
						memoryFile={memoryFile}
						removeFile={removeFile}
						partners={partners}
						handleUploadFile={handleUploadFile}
						setEventVideo={setEventVideo}
						handleVideoChange={handleVideoChange}
						handleAddVideo={handleAddVideo}
						videoData={videoData}
						partnerId={partnerId}
						handleDeleteFile={handleDeleteImage}
					/>
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default EventNew;
