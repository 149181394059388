import {
	Box,
	Button,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Placeholder, SortableTableHeader, Subheader } from 'components';
import SearchForm from 'features/videos/SearchForm';
import VideoItem from 'features/videos/VideoItem';
import { useAlerts, useNavigation, useSelected, useVideos } from 'hooks';

const TABLE_HEADERS = [
	{
		value: 'image_url',
		label: 'Image',
		sortable: false,
		hidden: false
	},
	{
		value: 'user_id',
		label: 'User',
		sortable: false,
		hidden: false
	},
	{
		value: 'tags',
		label: 'Tags',
		sortable: false,
		hidden: false
	}
];

const VideoList = ({ ...props }) => {
	const navigate = useNavigate();
	const {
		isLoading,
		isEmpty,
		videos,
		findVideos,
		deleteVideo,
		paginate,
		params,
		page,
		numPages,
		reloadVideos,
		sortKey,
		sortDirection,
		handleSort
	} = useVideos({});

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/videos'
	});

	const { selectedIds, handleSelect } = useSelected();

	const { showAlertSuccess } = useAlerts();

	const handleDeleteClick = async (video) => {
		await deleteVideo(video);
		showAlertSuccess('Video has been deleted');
		reloadVideos();
	};

	useEffect(() => {
		findVideos(
			{
				...params,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [page, sortKey, sortDirection]);

	return (
		<>
			<Subheader
				title="Videos"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						Video
					</Button>
				]}
			/>
			<Container maxWidth="lg">
				<>
					<Paper>
						<SearchForm
							isLoading={isLoading}
							params={params}
							handleSearch={findVideos}
						/>
						{!isEmpty ? (
							<TableContainer component={Paper} elevation={0}>
								<Table>
									<TableHead>
										<TableRow>
											{TABLE_HEADERS.filter((h) => !h.hidden).map(
												(header, idx) => (
													<SortableTableHeader
														key={idx}
														handleSort={handleSort}
														value={`videos.${header.value}`}
														sortable={header.sortable}
														sortKey={sortKey}
														sortDirection={sortDirection}
													>
														{header.label}
													</SortableTableHeader>
												)
											)}
											<TableCell></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{videos.map((video) => (
											<VideoItem
												key={video.id}
												video={video}
												isSelectable={false}
												selectedIds={selectedIds}
												handleSelect={handleSelect}
												handleClick={handleClick}
												handleDeleteClick={handleDeleteClick}
												handleEditClick={handleEditClick}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<Placeholder
								icon={<Search />}
								title="No videos"
								subtitle="Please try another search"
							/>
						)}
					</Paper>
					{!isEmpty && (
						<Box my={1}>
							<Pagination
								onChange={(ev, page) => paginate(page)}
								color="primary"
								size="small"
								shape="rounded"
								page={page}
								count={numPages}
							/>
						</Box>
					)}
				</>
			</Container>
		</>
	);
};

export default VideoList;
