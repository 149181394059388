import { Button, TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React from 'react';

const SortableTableHeader = ({
	value,
	sortKey,
	sortable = true,
	sortDirection,
	handleSort,
	children
}) => {
	let isActive = sortKey === value ? true : false;

	const renderIcon = () => {
		if (!sortable || !isActive) return null;
		return sortDirection == 'asc' ? <ExpandMore /> : <ExpandLess />;
	};

	return (
		<StyledTableCell align="left" sortDirection="asc">
			<Button
				color="primary"
				active={(sortKey === value).toString()}
				endIcon={renderIcon()}
				onClick={() => sortable && handleSort(value)}
			>
				{children}
			</Button>
		</StyledTableCell>
	);
};

export default SortableTableHeader;

const StyledTableCell = withStyles(() => ({
	head: {
		borderWidth: 0
	}
}))(TableCell);
