import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';
import { useAlerts, usePartners, useUploadFileInMemory } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validatePartner } from 'validations/partner';
import PartnerForm from './form/PartnerForm';

const PartnerNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { partner, handleChange, createPartner, findPartner, deleteFile } =
		usePartners({
			id: id
		});

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const valid = await validatePartner(partner);
		console.log('valid', valid);
		if (valid.isValid) {
			await createPartner(partner);
			showAlertSuccess('Partner has been created');
			navigate('/events-partners');
		} else {
			showAlertError(valid.messages.join(', '));
		}
	};

	useEffect(() => {
		if (id) findPartner(id);
	}, []);

	return (
		<>
			<Subheader
				title="Add partner"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<PartnerForm partner={partner} handleChange={handleChange} />
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default PartnerNew;
