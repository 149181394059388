import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader } from 'components';
import { useAlerts, usePartners, useUploadFileInMemory } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validatePartner } from 'validations/partner';
import PartnerForm from './form/PartnerForm';

const PartnerEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const {
		partnerId,
		isLoading,
		isLoaded,
		partner,
		handleChange,
		updatePartner,
		findPartner,
		deleteFile
	} = usePartners({ id: id });

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		let valid = validatePartner(partner);
		if (valid.isValid) {
			await updatePartner(partner);
			showAlertSuccess('Partner has been updated');
			navigate(`/partners/${id}`);
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	useEffect(() => {
		if (id) findPartner(id);
	}, []);

	return (
		<>
			<Subheader
				title="Edit Partner"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<PartnerForm
							partner={partner}
							status="edit"
							handleChange={handleChange}
						/>
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default PartnerEdit;
