import { Box, Typography, makeStyles, withWidth } from '@material-ui/core';
import React from 'react';

const SubheaderV2 = ({ title, buttons, width, ...props }) => {
	const classes = useStyles();
	const isMobile = ['xs', 'sm'].includes(width);

	return (
		<Box className={classes.box} px={4} pt={4}>
			<Box
				width="66.6%"
				minHeight="50px"
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
			>
				<Box ml={isMobile ? 6 : 3} mt={1.5}>
					<Typography color="textPrimary" variant="h4">
						{title}
					</Typography>
				</Box>
			</Box>
			<Box width="33.3%" display="flex" justifyContent="flex-end">
				{buttons &&
					buttons.map((button, i) => (
						<Box m={1} key={i}>
							{button}
						</Box>
					))}
			</Box>
		</Box>
	);
};
export default withWidth()(SubheaderV2);

const useStyles = makeStyles((theme) => ({
	box: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	}
}));
