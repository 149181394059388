import React, { useEffect } from 'react';
import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';

import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts, usePromoRules } from 'hooks';
import PromoRuleForm from './PromoRuleForm';
import { validatePromoRule } from 'validations/promoRule';

const PromoRuleEdit = (props) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { findPromoRule, promoRule, handleChange, updatePromoRule } =
		usePromoRules({ id: id });
	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const { isValid, messages } = validatePromoRule(promoRule);
		if (isValid) {
			await updatePromoRule(promoRule);
			showAlertSuccess('Promo Rule Updated');
			navigate(`/promo_rules/${id}`);
		} else {
			messages.map((m) => showAlertError(m));
		}
	};

	useEffect(() => {
		if (id) findPromoRule(id);
	}, []);

	return (
		<>
			<Subheader
				title="Edit Promo Rule"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<PromoRuleForm promo={promoRule} handleChange={handleChange} />
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default PromoRuleEdit;
