import React from 'react';
import { useMenu } from 'hooks';
import {
	Hidden,
	Button,
	IconButton,
	TableRow,
	TableCell,
	Menu,
	MenuItem,
	Avatar,
	Checkbox
} from '@material-ui/core';
import { Image } from 'components';
import { MoreHoriz, Person, Check } from '@material-ui/icons';

const TrainerItem = ({
	trainer,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleEditClick(trainer, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(trainer, e)}>Delete</MenuItem>
		</Menu>
	);

	const isSelected = selectedIds.includes(trainer.id);

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(trainer)}
						name="trainer"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(trainer)}>
					<Image src={trainer.image_url} variant="rounded" size={80} />
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(trainer)}>
					{trainer.first_name}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(trainer)}>
					{trainer.last_name}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(trainer)}>
					{trainer.featured ? <Check /> : ''}
				</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default TrainerItem;
