import { isEmpty } from 'validations';

export const validatePartner = (partner) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(partner.name)) {
		isValid = false;
		messages.push('Partner Name is required');
	}
	if (isEmpty(partner.email)) {
		isValid = false;
		messages.push('Partner Login Email is required');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};
