import {
	Menu,
	TableCell,
	TableRow,
	MenuItem,
	Button,
	IconButton
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { useMenu } from 'hooks';
import { formatDisplayDate } from 'lib/utils';
import React from 'react';

const PromoRuleItem = ({
	promo,
	handleClick,
	handleEditClick,
	handleDeleteClick
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleEditClick(promo, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(promo, e)}>Delete</MenuItem>
		</Menu>
	);

	return (
		<TableRow>
			<TableCell>
				<Button onClick={() => handleClick(promo)}>{promo.name}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(promo)}>{promo.duration}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(promo)}>
					{formatDisplayDate(promo.created_at)}
				</Button>
			</TableCell>

			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default PromoRuleItem;
