import React from 'react';

import { useResource } from 'hooks';

const usePromoCodes = ({ id, defaultSortKey, ...props }) => {
	const {
		id: promoCodeId,
		isLoaded,
		isLoading,
		resource: promoCode,
		resources: promoCodes,
		isEmpty,
		findOne: findPromoCode,
		findMany: findPromoCodes,
		paginate,
		query,
		page,
		numPages,
		reloadMany: reloadPromoCodes,
		sortKey,
		sortDirection,
		handleSort,
		handleChange,
		update: updatePromoCode,
		create: createPromoCode,
		destroy: deletePromoCode,
		errors: promoErrors
	} = useResource({
		id: id,
		url: '/api/v1/admin/promo_codes',
		name: 'promo_code',
		defaultSortKey: defaultSortKey
	});

	return {
		promoCodeId,
		isLoaded,
		isLoading,
		promoCode,
		promoCodes,
		isEmpty,
		findPromoCode,
		paginate,
		query,
		page,
		numPages,
		reloadPromoCodes,
		sortKey,
		sortDirection,
		handleSort,
		findPromoCodes,
		handleChange,
		updatePromoCode,
		createPromoCode,
		deletePromoCode,
		promoErrors
	};
};

export default usePromoCodes;
