import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	Container,
	Grid,
	makeStyles,
	Paper,
	Typography
} from '@material-ui/core';
import { Placeholder, Subheader, TextInput } from 'components';
import { Check } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts, useSubmissions, useTrainingPlanDrills } from 'hooks';

const TrainingPlanDrillSubmissionShow = () => {
	const { id, drillId } = useParams();

	const [feedback, setFeedback] = useState({
		comment: '',
		skill_ratings: undefined,
		status: ''
	});
	const { trainingPlanDrill, findTrainingPlanDrill, updateFeedback } =
		useTrainingPlanDrills({ id: drillId });
	const { findSubmission, submission } = useSubmissions({
		url: `/api/v1/admin/training_plan_drills/${drillId}/submissions`
	});
	const { showAlertError, showAlertSuccess } = useAlerts();
	const navigate = useNavigate();

	const classes = styles();

	const handleSaveClick = async () => {
		const response = await updateFeedback(feedback.id, {
			...feedback,
			submission_id: id
		});

		if (response.status === 'sent') {
			showAlertSuccess('Feedback updated successfully');
			findSubmission(id);
		} else {
			showAlertError('Error sending feedback');
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFeedback({ ...feedback, [name]: value });
	};

	const handleSkillScoreChange = (e, skill) => {
		const { name, value } = e.target;
		setFeedback({
			...feedback,
			skill_ratings: { ...feedback.skill_ratings, [skill.toLowerCase()]: value }
		});
	};

	useEffect(() => {
		if (id) findSubmission(id);
	}, [id]);

	useEffect(() => {
		if (drillId) findTrainingPlanDrill(drillId);
	}, [drillId]);

	useEffect(() => {
		if (submission) setFeedback(submission.feedback || {});
	}, [submission]);

	const seen = useMemo(() => {
		return feedback.status === 'read';
	}, [feedback.status]);

	return (
		<>
			<Subheader
				title="Give Feedback"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
						disabled={seen}
					>
						Save
					</Button>
				]}
			/>

			<Container>
				<Paper>
					<Box p={4}>
						<Grid container>
							<Grid className={classes.itemContainer} item xs={12} md={6}>
								<center className={classes.placeholder}>
									<Typography variant="caption">Benchmark Video</Typography>
								</center>
								<video
									width="100%"
									height={'90%'}
									controls
									src={trainingPlanDrill?.lesson?.video_url}
								/>
							</Grid>
							<Grid className={classes.itemContainer} item xs={12} md={6}>
								<center className={classes.placeholder}>
									<Typography variant="caption">
										Users Uploaded Attempt
									</Typography>
								</center>
								<video
									width="100%"
									height={'90%'}
									controls
									src={submission?.video_url}
								/>
							</Grid>
						</Grid>

						<Grid item xs={12} sm={12}>
							<Box sx={{ marginTop: 20 }}>
								<Typography variant="h5">Feedback</Typography>
								<TextInput
									value={feedback.comment || ''}
									label=""
									name="comment"
									placeholder="Feedback"
									handleChange={handleChange}
									type={'text'}
									multiline={true}
									rows={10}
									disabled={seen}
								/>
							</Box>

							{feedback.skill_ratings &&
								trainingPlanDrill?.category_names?.map((skill, index) => (
									<Box sx={{ marginTop: 20 }} key={index}>
										<Typography variant="h5">{skill}</Typography>
										<TextInput
											key={index}
											value={feedback.skill_ratings[skill.toLowerCase()] || ''}
											label={skill}
											name={skill.toLowerCase()}
											placeholder={skill}
											handleChange={(e) => handleSkillScoreChange(e, skill)}
											type={'number'}
											disabled={seen}
										/>
									</Box>
								))}
						</Grid>
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
								disabled={seen}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

const styles = makeStyles((theme) => ({
	itemContainer: {
		padding: theme.spacing(2),
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		height: 400
	},
	placeholder: {
		color: theme.palette.text.secondary
	}
}));

export default TrainingPlanDrillSubmissionShow;
