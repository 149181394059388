import React from 'react';
import { useMenu } from 'hooks';
import {
	Button,
	IconButton,
	TableRow,
	TableCell,
	Menu,
	MenuItem,
	Checkbox
} from '@material-ui/core';
import { Add, MoreHoriz } from '@material-ui/icons';

const AddLessonItem = ({ lesson, handleAddLesson, ...props }) => {
	return (
		<TableRow>
			<TableCell>{lesson.title}</TableCell>

			<TableCell>{lesson.level}</TableCell>

			<TableCell>{lesson.trainer?.name}</TableCell>

			<TableCell>{lesson.category_name}</TableCell>

			<TableCell align="right">
				<IconButton onClick={() => handleAddLesson(lesson)}>
					<Add />
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default AddLessonItem;
