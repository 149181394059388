import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader, VideoUploader } from 'components';
import EditorialForm from 'features/editorials/EditorialForm';
import { useAlerts, useEditorials } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateEditorial } from 'validations/editorials';

const EditorialEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const {
		editorial,
		handleChange,
		updateEditorial,
		findEditorial,
		uploadFile,
		deleteFile
	} = useEditorials({ id: id });

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const valid = validateEditorial(editorial);
		if (valid.isValid) {
			await updateEditorial(editorial);
			showAlertSuccess('Editorial has been updated');
			navigate(`/editorials/${id}`);
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleUpload = async (file, type) => {
		await uploadFile(file, type);
		findEditorial(id);
	};

	const handleDelete = async (type) => {
		await deleteFile(type);
		findEditorial(id);
	};

	useEffect(() => {
		if (id) findEditorial(id);
	}, []);

	return (
		<>
			<Subheader
				title="Edit Editorial"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<center>
							<ImageUploader
								label="Upload thumbnail (3:2)"
								imageUrl={editorial.thumbnail_url}
								handleUpload={(file) => handleUpload(file, 'thumbnail_url')}
								handleDelete={() => handleDelete('thumbnail_url')}
							/>
							<ImageUploader
								label="Upload image (3:2)"
								imageUrl={editorial.image_url}
								handleUpload={(file) => handleUpload(file, 'image_url')}
								handleDelete={() => handleDelete('image_url')}
							/>
							<ImageUploader
								label="Upload web image (6:1)"
								imageUrl={editorial.web_image_url}
								handleUpload={(file) => handleUpload(file, 'web_image_url')}
								handleDelete={() => handleDelete('web_image_url')}
							/>
							<VideoUploader
								label="Upload video"
								videoUrl={editorial.video_url}
								handleUpload={(file) => handleUpload(file, 'video_url')}
								handleDelete={() => handleDelete('video_url')}
							/>
						</center>
						<EditorialForm editorial={editorial} handleChange={handleChange} />
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default EditorialEdit;
