import { isEmpty } from 'validations';

export const validateAchievement = (achievement) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(achievement.user) && isEmpty(achievement.user_id)) {
		isValid = false;
		messages.push('User is required');
	}

	if (isEmpty(achievement.reward) && isEmpty(achievement.reward_id)) {
		isValid = false;
		messages.push('Reward is required');
	}

	if (isEmpty(achievement.points_achieved)) {
		isValid = false;
		messages.push('Points Achieved is required');
	}

	if (isEmpty(achievement.status)) {
		isValid = false;
		messages.push('Status is required');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};
