import React from 'react';
import api from 'api';
import { useAlerts, useLoaders, useResource } from 'hooks';

const usePositions = ({
	id,
	defaultSortKey,
	defaultSortDirection,
	...props
}) => {
	const { showLoading, hideLoading } = useLoaders();
	const { showAlertError, showAlertSuccess } = useAlerts();

	const RESOURCE_URL = '/api/v1/admin/positions';

	const {
		resourceId: positionId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: position,
		resources: positions,
		findOne: findPosition,
		findMany: findPositions,
		update: updatePosition,
		create: createPosition,
		save: savePosition,
		destroy: deletePosition,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadPosition,
		reloadMany: reloadPositions,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort
	} = useResource({
		id: id,
		url: RESOURCE_URL,
		name: 'position'
	});

	return {
		positionId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		position,
		positions,
		findPosition,
		findPositions,
		savePosition,
		updatePosition,
		createPosition,
		deletePosition,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadPosition,
		reloadPositions
	};
};

export default usePositions;
