import React from 'react';
import api from 'api';
import { useAlerts, useLoaders, useResource } from 'hooks';

const useCategory = ({
	id,
	defaultSortKey,
	defaultSortDirection,
	...props
}) => {
	const { showLoading, hideLoading } = useLoaders();
	const { showAlertError, showAlertSuccess } = useAlerts();

	const RESOURCE_URL = '/api/v1/admin/categories';

	const {
		resourceId: categoryId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: category,
		resources: categories,
		findOne: findCategory,
		findMany: findCategories,
		update: updateCategory,
		create: createCategory,
		save: saveCategory,
		destroy: deleteCategory,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadCategory,
		reloadMany: reloadCategories,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort
	} = useResource({
		id: id,
		url: RESOURCE_URL,
		name: 'category'
	});

	return {
		categoryId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		category,
		categories,
		findCategory,
		findCategories,
		saveCategory,
		updateCategory,
		createCategory,
		deleteCategory,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadCategory,
		reloadCategories
	};
};

export default useCategory;
