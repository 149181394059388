import {
	Button,
	Checkbox,
	IconButton,
	Menu,
	TableCell,
	TableRow,
	MenuItem
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';

import { useMenu } from 'hooks';
import { formatDisplayDate } from 'lib/utils';
import moment from 'moment';
import React from 'react';

const PromoCodeItem = ({
	promo,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();
	const isSelected = selectedIds.includes(promo.id);

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleEditClick(promo, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(promo, e)}>Delete</MenuItem>
		</Menu>
	);

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(promo)}
						name="topic"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(promo)}>{promo.promo_code}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(promo)}>
					{formatDisplayDate(promo.created_at)}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(promo)}>
					{formatDisplayDate(promo.expiration_date)}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(promo)}>
					{promo.current_redeems}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(promo)}>
					{promo.active ? 'Active' : 'Inactive'}
				</Button>
			</TableCell>

			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default PromoCodeItem;
