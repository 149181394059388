import React from 'react';
import { Grid } from '@material-ui/core';
import { Autosuggest, TextInput } from 'components';
import { COUNTRIES } from 'lib/constants/countries';

const ClubForm = ({ club, isEditing, handleChange }) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={club.name}
				label="Name"
				name="name"
				placeholder="Name"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={club.contact_email}
				label="Contact email"
				name="contact_email"
				placeholder="Contact email"
				handleChange={handleChange}
			/>
		</Grid>

		<Grid item xs={12} sm={12}>
			<TextInput
				value={club.contact_phone}
				label="Contact phone"
				name="contact_phone"
				placeholder="Contact phone"
				handleChange={handleChange}
			/>
		</Grid>

		<Grid item xs={12} sm={12}>
			<TextInput
				value={club.city}
				label="City"
				name="city"
				placeholder="City"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<Autosuggest
				options={COUNTRIES}
				value={club.country}
				label="Country"
				name="country"
				placeholder="Country"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				multiline
				rows={3}
				value={club.main_ground}
				label="Main ground address"
				name="main_ground"
				placeholder="Main ground address"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				multiline
				rows={3}
				value={club.training_ground}
				label="Training ground"
				name="training_ground"
				placeholder="Training ground address"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={club.level}
				label="Level"
				name="level"
				placeholder="Level"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={club.website}
				label="Website"
				name="website"
				placeholder="Website"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={6}>
			<TextInput
				type={'number'}
				value={club.lat}
				label="Latitude"
				name="lat"
				placeholder="Latitude"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={6}>
			<TextInput
				type={'number'}
				value={club.lng}
				label="Longitude"
				name="lng"
				placeholder="Longitude"
				handleChange={handleChange}
			/>
		</Grid>
	</Grid>
);

export default ClubForm;
