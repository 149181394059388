import React, { useState } from 'react';
import { Button, IconButton, Typography } from '@material-ui/core';
import { Toolbar } from 'components';
import { Person } from '@material-ui/icons';
import styled from 'styled-components';

const ArticleToolbar = ({
	totalSelected,
	selectedIds,
	selected,
	handleClear
}) => (
	<Toolbar open={selectedIds?.length > 0} handleClose={handleClear}></Toolbar>
);

export default ArticleToolbar;

const StyledButton = styled(Button)`
	margin-right: 10px;
`;
