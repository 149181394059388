import React, { useEffect, useMemo, useState } from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import { Autosuggest, MultiAutosuggest, TextInput } from 'components';
import { BOOLEAN } from 'lib/constants';

const TrainingPlanForm = ({
	trainingPlan,
	handleChange,
	type,
	categories,
	skillsPlaceholder,
	maxSkills,
	withPosition = false
}) => {
	const [options, setOptions] = useState([]);
	const classes = useStyles();

	const displayPublished = (published) => {
		let value = null;
		switch (published) {
			case undefined:
				value = null;
				break;
			case true:
				value = true;
				break;
			case false:
				value = false;
				break;
			default:
				value = null;
				break;
		}
		return value;
	};

	useEffect(() => {
		if (categories) {
			setOptions(
				categories.map((category) => {
					return {
						label: category.name,
						value: category.id
					};
				})
			);
		}
	}, [categories]);

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={trainingPlan.name}
					label="Name"
					name="name"
					placeholder="Name"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					rows={3}
					multiline
					value={trainingPlan.description}
					label="Description"
					name="description"
					placeholder="Description"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				{maxSkills > 1 ? (
					<MultiAutosuggest
						options={options}
						value={trainingPlan.category_ids || []}
						label="Skill Categories"
						placeholder={skillsPlaceholder}
						name="category_ids"
						handleChange={handleChange}
						className={classes}
					/>
				) : (
					<Autosuggest
						options={options}
						value={trainingPlan.category_id}
						label="Skill Category"
						name="category_id"
						placeholder={skillsPlaceholder}
						handleChange={handleChange}
						className={classes}
					/>
				)}
			</Grid>

			<Grid item md={4} xs={12}>
				<Autosuggest
					options={BOOLEAN}
					value={displayPublished(trainingPlan.published)}
					label={`Published `}
					name="published"
					placeholder="Yes / No"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<TextInput
					value={trainingPlan.position}
					label="Order"
					name="position"
					placeholder="Order"
					handleChange={handleChange}
					className={classes}
					type="number"
				/>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	inputBase: {
		'& input, & textarea': {
			borderColor: theme.palette.placeHolder.text
		}
	}
}));

export default TrainingPlanForm;
