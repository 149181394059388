import { List } from '@material-ui/core';
import React from 'react';

import { LineItem } from 'components';
import { properCase } from 'lib/utils';

const LotteryDrawDetails = ({ lotteryDraw }) => {
	return (
		<List>
			<LineItem
				label="User"
				value={
					lotteryDraw?.user
						? `${lotteryDraw?.user?.first_name} ${lotteryDraw?.user?.last_name}`
						: 'n/a'
				}
			/>
			<LineItem label="Status" value={properCase(lotteryDraw.status || '')} />
			<LineItem label="Date Created" value={lotteryDraw.created_at} />
			<LineItem label="Last Updated" value={lotteryDraw.updated_at} />
		</List>
	);
};

export default LotteryDrawDetails;
