import { isEmpty } from 'validations';

export const validateLotteryDraw = (LotteryDraw) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(LotteryDraw.user) && isEmpty(LotteryDraw.user_id)) {
		isValid = false;
		messages.push('User is required');
	}

	if (isEmpty(LotteryDraw.status)) {
		isValid = false;
		messages.push('Status is required');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};
