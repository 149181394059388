import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
	Box,
	Button,
	Chip,
	Container,
	Grid,
	Paper,
	Typography
} from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { Image, LineItem, Subheader } from 'components';
import { useFeedbackDrills } from 'hooks';
import { removeUnderscoresAndCapitalize } from 'lib/utils';

const FeedbackDrillShow = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { feedbackDrill, findFeedbackDrill } = useFeedbackDrills({
		id: id
	});

	useEffect(() => {
		if (id) findFeedbackDrill(id);
	}, []);

	const handleEditClick = () => navigate(`/feedback_drill/${id}/edit`);

	return (
		<React.Fragment>
			<Subheader
				title={'Feedback Drill - ' + (feedbackDrill.title || '')}
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<ArrowRight />}
						onClick={handleEditClick}
					>
						Edit
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<React.Fragment>
							<Grid container spacing={1}>
								<Grid item xs={6} sm={6}>
									<Typography variant="h5">Video</Typography>
									{
										<video
											controls
											src={feedbackDrill?.video_url}
											width="100%"
										/>
									}
								</Grid>
								<Grid item xs={6} sm={6}>
									<Typography variant="h5">Image</Typography>
									<Image src={feedbackDrill?.image_url} size="85%" />
								</Grid>
								<Grid item xs={12} sm={12}>
									<Typography variant="h5">Basic Info</Typography>
									<LineItem label="Title:" value={feedbackDrill?.title} />
									<LineItem
										label="Description:"
										value={feedbackDrill?.description}
									/>
									<Typography variant="h5">Video Modifiers</Typography>
									<LineItem
										label="Main:"
										value={feedbackDrill?.video_modifier_main}
									/>
									<LineItem
										label="Clip:"
										value={feedbackDrill?.video_modifier_clip}
									/>
									<LineItem
										label="Thumbnail:"
										value={feedbackDrill?.video_modifier_thumbnail}
									/>
									<Typography variant="h5">Skill Categories</Typography>
									<LineItem
										label=""
										value={
											feedbackDrill?.skill_categories &&
											feedbackDrill.skill_categories.map((v) => (
												<Chip
													key={v.skill}
													style={{ marginRight: 5 }}
													label={v.skill.toUpperCase()}
												/>
											))
										}
									/>
									<LineItem
										label="Status:"
										value={removeUnderscoresAndCapitalize(
											feedbackDrill?.status || ''
										)}
									/>
									<Typography variant="h5">Audit</Typography>
									<LineItem
										label="Updated On:"
										value={feedbackDrill.updated_at || ''}
									/>
									<LineItem
										label="Created On:"
										value={feedbackDrill.created_at || ''}
									/>
								</Grid>
							</Grid>
						</React.Fragment>
					</Box>
				</Paper>
			</Container>
		</React.Fragment>
	);
};

export default FeedbackDrillShow;
