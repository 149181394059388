import { isEmpty } from 'validations';

export const validateSetting = (setting) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(setting.version)) {
		isValid = false;
		messages.push('Version is required');
	}

	if (isEmpty(setting.release_guard)) {
		isValid = false;
		messages.push('Release Guard is required');
	}

	if (isEmpty(setting.icon_width)) {
		isValid = false;
		messages.push('Icon Width is required');
	}

	if (isEmpty(setting.thumbnail_width)) {
		isValid = false;
		messages.push('Thumbnail Width is required');
	}

	if (isEmpty(setting.medium_width)) {
		isValid = false;
		messages.push('Medium Width is required');
	}

	if (isEmpty(setting.large_width)) {
		isValid = false;
		messages.push('Large Width is required');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};
