import moment from 'moment';
import { compose, replace, map, split, join } from 'lodash/fp';

export const getInitials = (name = '') => {
	if (!name) return;
	return name
		.replace(/\s+/, ' ')
		.split(' ')
		.slice(0, 2)
		.map((v) => v && v[0].toUpperCase())
		.join('');
};

export const truncate = (text, length) => {
	if (text === null || text === undefined) return '';

	if (text.length < length) {
		return text;
	} else {
		return text.slice(0, length) + '...';
	}
};

export const calculateTime = (timeInSeconds) => {
	let h = Math.floor(timeInSeconds / 3600)
			.toString()
			.padStart(2, '0'),
		m = Math.floor((timeInSeconds % 3600) / 60)
			.toString()
			.padStart(2, '0'),
		s = Math.floor(timeInSeconds % 60)
			.toString()
			.padStart(2, '0');
	if (h === '00') {
		return `${m}:${s}`;
	}
	return `${h}:${m}:${s}`;
};

export const getDate = (date) => {
	return moment(date).format('DD/MM/YYYY');
};

export const getDayFromDate = (date) => {
	return moment(date).format('D');
};

export const getMonthFromDate = (date) => {
	return moment(date).format('MMMM');
};

export const getLotteryDates = (date) => {
	const current = moment(date);
	const previous = moment(date).subtract(1, 'months');

	const currentMonth = current.month() + 1;
	const currentYear = current.year();
	const previousMonth = previous.month() + 1;
	const previousYear = previous.year();

	return { currentMonth, currentYear, previousMonth, previousYear };
};

export const getFilename = (route) => {
	let filename = route.split('\\').pop().split('/').pop();
	return filename;
};

export const extractIdFromUrl = (url) => {
	if (url[0] !== '/') {
		throw new Error('url must begin with a slash');
	}

	const parsedUrl = url.split('/');

	if (parsedUrl[1] !== 'api') {
		throw new Error('first segment must be api');
	}

	if (parsedUrl[2] !== 'v1') {
		throw new Error('second segment must be v1');
	}

	const adminIndex = parsedUrl.indexOf('admin');

	if (adminIndex !== -1) {
		// If "admin" section is found in the URL
		const baseUrl = parsedUrl.slice(0, adminIndex + 3).join('/');
		const resourceIndex = adminIndex + 1; // Resource is right after "admin"
		const resource = parsedUrl[resourceIndex];
		const id = parsedUrl[resourceIndex + 1];
		return { baseUrl, id, resource };
	} else {
		if (parsedUrl.length === 4) {
			return { baseUrl: url, id: null, resource: parsedUrl[3] };
		} else {
			return {
				baseUrl: parsedUrl.slice(0, 4).join('/'),
				id: parsedUrl[4],
				resource: parsedUrl[3]
			};
		}
	}
};

export const getScaledImg = (
	imageWidthActual,
	imageHeightActual,
	desiredWidth
) => {
	const ratio = imageHeightActual / imageWidthActual;
	const imageWidth = desiredWidth;
	const imageHeight = imageWidth * ratio;

	return [imageWidth, imageHeight];
};

export const getImageWidthHeightRatio = (
	imageWidthActual,
	imageHeightActual,
	desiredWidth
) => {
	const ratio = imageHeightActual / imageWidthActual;
	const imageWidth = desiredWidth;
	const imageHeight = imageWidth * ratio;

	return {
		ratio: ratio,
		imageWidth: imageWidth,
		imageHeight: imageHeight
	};
};

export const getWidthFromProps = (type, size) => {
	switch (type) {
		case 'auto':
			return '100%';
		case 'custom':
			return size; // formated styled component value. eg: "100%", "10px"
		default:
			return `${size}px`;
	}
};

export const cleanObject = (obj) => {
	const result = {};
	Object.keys(obj).forEach((key) => {
		if (
			obj[key] !== '' &&
			typeof obj[key] !== 'undefined' &&
			obj[key] !== null
		) {
			result[key] = obj[key];
		}
	});
	return result;
};

export const hasFeedbackDrill = (skills) => {
	const allValuesNull = Object.values(skills).every(
		(item) => item.value === null
	);

	return allValuesNull;
};

export const removeUnderscoresAndCapitalize = compose(
	join(' '),
	map((x) => x.slice(0, 1).toUpperCase() + x.slice(1)),
	split(' '),
	replace('_', ' ')
);

export const convertVideoToImage = (url) =>
	(url || '').replace('.mp4', '.jpg').replace('.mov', '.jpg');

// weight in kg
// height in cm
export const calculateBmi = (weight, hight) => {
	const heightM = hight / 100;

	const bmi = weight / (heightM * heightM);

	return Number(bmi).toFixed(1);
};
