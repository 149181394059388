import React from 'react';

import { useResource } from 'hooks';
import api from 'api';

const useTrainingPlanDrills = ({ id, ...props }) => {
	const {
		id: trainingPlanDrillId,
		isLoading,
		resource: trainingPlanDrill,
		resources: trainingPlanDrills,
		errors: trainingPlanDrillErrors,
		findOne: findTrainingPlanDrill,
		findMany: findTrainingPlanDrills,
		create: createTrainingPlanDrill,
		update: updateTrainingPlanDrill,
		destroy: destroyTrainingPlanDrill,
		setResources: setTrainingPlanDrills,
		handleErrors,
		...rest
	} = useResource({
		id: id,
		url: '/api/v1/admin/training_plan_drills',
		name: 'training_plan_drill',
		label: 'Training Plan Drill'
	});

	const resortTrainingPlanDrills = async (trainingPlanDrills) => {
		const sorted = trainingPlanDrills.map((item) => ({
			id: item.id,
			position: item.position
		}));
		await api.post(`/api/v1/admin/training_plan_drills/resort`, {
			trainingPlanDrills: sorted
		});
		return sorted;
	};

	const createFeedback = async (id, feedback) => {
		try {
			const res = await api.post(
				`/api/v1/admin/training_plan_drills/${id}/create_feedback`,
				{ feedback }
			);
			return res.data;
		} catch (e) {
			handleErrors(e);
			return e;
		}
	};

	const updateFeedback = async (id, feedback) => {
		try {
			const res = await api.put(
				`/api/v1/admin/training_plan_drills/${id}/update_feedback`,
				{ feedback }
			);
			return res.data;
		} catch (e) {
			handleErrors(e);
			return e;
		}
	};

	return {
		trainingPlanDrillId,
		trainingPlanDrill,
		trainingPlanDrills,
		trainingPlanDrillErrors,
		isLoading,
		findTrainingPlanDrill,
		findTrainingPlanDrills,
		createTrainingPlanDrill,
		updateTrainingPlanDrill,
		destroyTrainingPlanDrill,
		resortTrainingPlanDrills,
		setTrainingPlanDrills,
		createFeedback,
		updateFeedback,
		...rest
	};
};

export default useTrainingPlanDrills;
