import React from 'react';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import { EventDisplayImage, LineItem, StatusMessage } from 'components';
import { useAlerts, useEvents, useEventVideos } from 'hooks';

const EventMediaDisplay = ({ id, eventVideos, image, reloadEvent, event }) => {
	const { showAlertSuccess, showAlertError } = useAlerts();

	const {
		findEvent,
		deleteFile,
		eventSubmission,
		findEventSubmissionWithoutSubmissionId
	} = useEvents({ id: id });

	const handleDelete = async (type) => {
		await deleteFile(type);
		reloadEvent();
		showAlertSuccess('Event Image deleted successfully');
		findEvent(id);
	};

	const { deleteEventVideo } = useEventVideos({});

	const handleVideoDelete = async (event_id) => {
		try {
			if (id) {
				await deleteEventVideo({ id: event_id });
				showAlertSuccess('Event Video deleted successfully');
				reloadEvent();
			} else {
				showAlertError('Cannot delete event video');
			}
		} catch (error) {
			showAlertError('Error deleting event');
		}
	};

	const isVideoSubmission = (video_id) => {
		if (!eventSubmission) {
			return false;
		}

		return eventSubmission.some((submission) =>
			submission.submissions.some((sub) => sub.event_video_id === video_id)
		);
	};

	React.useEffect(() => {
		if (id) {
			findEventSubmissionWithoutSubmissionId(id);
		}
	}, [event]);

	return (
		<>
			{image && (
				<Grid item xs={12} md={6}>
					<Box p={2} width="100%">
						{image && (
							<Typography component="span" variant="body1">
								Event Image:
							</Typography>
						)}
					</Box>

					{image && <EventDisplayImage label={'Event Image:'} url={image} />}

					{image && (
						<Button
							color="secondary"
							size="small"
							onClick={() => handleDelete('image_url')}
						>
							Remove
						</Button>
					)}
				</Grid>
			)}

			{eventVideos?.map((video, index) => (
				<Grid item xs={12} md={6} key={video?.id}>
					<LineItem label={`Event Video ${index + 1}`} value={video?.title} />
					<div>
						<video controls style={{ width: '100%' }}>
							<source src={video?.video_url} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</div>

					{isVideoSubmission(video?.id) && (
						<StatusMessage
							status="warning"
							message="This video cannot be deleted as it has submissions against it."
						/>
					)}

					<Box mb={2}>
						<Button
							color="secondary"
							size="small"
							disabled={isVideoSubmission(video?.id)}
							onClick={() => handleVideoDelete(video?.id)}
						>
							Remove
						</Button>
					</Box>
				</Grid>
			))}
		</>
	);
};

export default EventMediaDisplay;
