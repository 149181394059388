import React, { useEffect } from 'react';

import { Box, Button, Container, Paper } from '@material-ui/core';
import { Subheader } from 'components';
import { usePromoRules } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import PromoRuleDetails from './PromoRuleDetails';

const PromoRuleShow = (props) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { promoRule, findPromoRule, isLoading } = usePromoRules({ id: id });

	const handleEditClick = () => navigate(`/promo_rules/${id}/edit`);

	useEffect(() => {
		if (id) findPromoRule(id);
	}, []);
	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : promoRule.name}
				buttons={[
					<Button onClick={handleEditClick} color="primary" variant="contained">
						Edit
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<PromoRuleDetails promo={promoRule} />
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default PromoRuleShow;
