import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { syntheticEvent } from 'lib/utils';
import { Lens } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const RatingInput = ({
	value,
	label,
	name,
	handleChange,
	max = 5,
	size,
	...rest
}) => {
	const handleRatingChange = (event, newValue) => {
		let ev = syntheticEvent(newValue, name);
		handleChange(ev);
	};

	return (
		<div>
			<Typography component="legend">{label}</Typography>
			<StyledRating
				name={name}
				value={parseInt(value || 0)}
				onChange={handleRatingChange}
				max={max}
				size={size}
				icon={<Lens fontSize="inherit" />}
			/>
		</div>
	);
};

export default RatingInput;

const StyledRating = withStyles((theme) => ({
	iconFilled: {
		color: theme.palette.primary.main
	},
	iconHover: {
		color: theme.palette.primary.main
	}
}))(Rating);
