import React, { useEffect, useState } from 'react';
import { alpha, Typography, InputBase, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';

const Autosuggest = ({
	className,
	value,
	options,
	label,
	name,
	placeholder = 'Select',
	multiselect = false,
	handleChange,
	handleInputChange,
	...props
}) => {
	const classes = useStyles();
	const [selected, setSelected] = useState({
		label: '',
		value: null
	});

	const handleOnChange = (event, newValue) => {
		setSelected(newValue);
		handleChange({
			target: {
				name: name,
				value: newValue?.value
			}
		});
	};

	useEffect(() => {
		if (typeof value != 'object') {
			setSelected(options.find((o) => o.value == value));
		} else {
			setSelected(value);
		}
	}, [value, options]);

	return (
		<div className={clsx(className?.root, classes.root)}>
			<Typography
				variant="body2"
				color="textSecondary"
				className={clsx(className?.label, classes.label)}
			>
				{label}
			</Typography>
			<Autocomplete
				multiple={multiselect}
				disableCloseOnSelect={multiselect}
				classes={{
					paper: classes.paper,
					option: classes.option,
					popperDisablePortal: classes.popperDisablePortal
				}}
				value={selected}
				defaultValue={selected}
				onChange={(event, newValue) => {
					handleOnChange(event, newValue);
				}}
				onInputChange={(event, newInputValue) => {
					handleInputChange && handleInputChange(newInputValue);
				}}
				noOptionsText="No options"
				clearOnBlur
				handleHomeEndKeys
				options={options}
				getOptionLabel={(option) => option?.label || ''}
				getOptionSelected={(option, value) => option?.label == value?.label}
				renderInput={(params) => (
					<InputBase
						placeholder={placeholder}
						ref={params.InputProps.ref}
						inputProps={{
							...params.inputProps,
							autoComplete: 'none'
						}}
						className={clsx(className?.inputBase, classes.inputBase)}
					/>
				)}
			/>
		</div>
	);
};

export default Autosuggest;

const useStyles = makeStyles((theme) => ({
	root: {},
	iconSelected: {
		height: 20,
		width: 20
	},
	inputBase: {
		padding: 0,
		width: '100%',
		'& input': {
			'-webkit-appearance': 'none',
			'-moz-appearance': 'none',
			appearance: 'none',
			padding: theme.spacing(1.5, 1),
			borderRadius: 4,
			backgroundColor: theme.palette.common.white,
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			border: '1px solid #ced4da',
			fontSize: 15,
			'&:focus': {
				boxShadow: `${alpha('#999999', 0.25)} 0 0 0 0.2rem`,
				borderColor: theme.palette.primary.light
			}
		}
	},
	paper: {
		backgroundColor: theme.palette.common.white,
		marginTop: theme.spacing(1),
		color: '#586069',
		fontSize: 15
	},
	option: {
		minHeight: 'auto',
		alignItems: 'flex-start',
		padding: 8,
		'&[aria-selected="true"]': {
			backgroundColor: 'transparent'
		},
		'&[data-focus="true"]': {
			backgroundColor: theme.palette.action.hover
		}
	},
	popperDisablePortal: {
		position: 'relative'
	},
	label: {
		marginBottom: 0
	},
	text: {
		flexGrow: 1
	},
	close: {
		opacity: 0.6,
		width: 18,
		height: 18
	}
}));
