import { Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const TrainingPlanLessonSortableList = ({
	id,
	list,
	ListContainer = List,
	ListItem,
	onDragEnd
}) => {
	const sortListContainer = useRef(null);
	const classes = useStyles();

	const _onDragEnd = (result) => {
		if (!result.destination) {
			return list;
		}
		const items = reorder(list, result.source.index, result.destination.index);
		onDragEnd(items);
	};

	return (
		<DragDropContext onDragEnd={_onDragEnd}>
			<Droppable droppableId={id}>
				{(provided) => {
					return (
						<Paper
							ref={sortListContainer}
							style={{
								minHeight: sortListContainer?.current?.clientHeight
							}}
						>
							<Grid container spacing={1} className={classes.root}>
								<Grid item md={1} xs={12}>
									{''}
								</Grid>
								<Grid item md={2} xs={12}>
									<Typography
										variant="body1"
										color={'primary'}
										className={classes.text}
									>
										{'Lesson Number'}
									</Typography>
								</Grid>
								<Grid item md={3} xs={12}>
									<Typography
										variant="body1"
										color={'primary'}
										className={classes.text}
									>
										{'Lesson Name'}
									</Typography>
								</Grid>
								<Grid item md={2} xs={12}>
									<Typography
										variant="body1"
										color={'primary'}
										className={classes.text}
									>
										{'Level'}
									</Typography>
								</Grid>
								<Grid item md={2} xs={12}>
									<Typography
										variant="body1"
										color={'primary'}
										className={classes.text}
									>
										{'Trainer'}
									</Typography>
								</Grid>
								<Grid item md={1} xs={12}>
									{''}
								</Grid>
							</Grid>
							<ListContainer
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{list.map((item, i) => (
									<Draggable
										key={item.id}
										draggableId={String(item.id)}
										index={i}
									>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style
												)}
											>
												<ListItem item={item} index={i} />
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</ListContainer>
						</Paper>
					);
				}}
			</Droppable>
		</DragDropContext>
	);
};

export default TrainingPlanLessonSortableList;

const getItemStyle = (_, draggableStyle) => ({
	userSelect: 'none',
	...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result.map((item, index) => ({
		...item,
		position: index + 1
	}));
};

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 20
	},
	item: { verticalAlign: 'center' },
	text: { paddingTop: 20 },
	icon: { color: theme.palette.common.grey, marginLeft: 20, marginTop: 20 },
	button: { paddingTop: 20 }
}));
