import React from 'react';
import { Box } from '@material-ui/core';

import { SearchInput } from 'components';
import { useQuery } from 'hooks';

const SearchForm = ({ handleSearch, ...props }) => {
	const { query, handleChange, handleKeyPress } = useQuery({
		handleSearch
	});

	return (
		<Box p={1} px={2}>
			<SearchInput
				fullWidth
				name="keywords"
				onKeyPress={handleKeyPress}
				value={query.search}
				handleChange={handleChange}
			/>
		</Box>
	);
};

export default SearchForm;
