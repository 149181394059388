import React, { useMemo } from 'react';

import { Button, Container, makeStyles, Paper } from '@material-ui/core';
import { Add, Info } from '@material-ui/icons';
import { Subheader } from 'components';
import TrainingPlanDrillList from 'features/trainingPlan/trainingPlanDrill/TrainingPlanDrillList';
import { useNavigate } from 'react-router-dom';

const TrainingPlanDrills = () => {
	const navigate = useNavigate();
	const classes = useStyles();

	const handleAddNew = () => {
		navigate('/training-plan-drills/new');
	};

	const createButton = useMemo(() => {
		return (
			<Button
				variant="contained"
				startIcon={<Add />}
				onClick={handleAddNew}
				color="primary"
			>
				{'Benchmark Drill'}
			</Button>
		);
	}, []);

	return (
		<>
			<Subheader title="Benchmark Drills" buttons={[createButton]} />
			<Container maxWidth="lg">
				<div className={classes.infoContainer}>
					<Info color="primary" />
					<div className={classes.infoText}>{'This is experimental'}</div>
				</div>
				<>
					<Paper>
						<TrainingPlanDrillList
							title={'Drills'}
							handleAddClick={handleAddNew}
						/>
					</Paper>
				</>
			</Container>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	infoContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		height: 50,
		backgroundColor: '#fff8c4',
		borderRadius: 5,
		gap: 10,
		marginBottom: 20
	},
	infoText: {
		alignItems: 'center',
		color: theme.palette.text.secondary
	}
}));

export default TrainingPlanDrills;
