import { Box, Button, Container, Paper, Tab, Tabs } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SubheaderV2 } from 'components';
import CourseDetails from 'features/courses/CourseDetails';
import LessonList from 'features/courses/lessons/LessonList';
import { useCourses } from 'hooks';

const CourseShow = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { isLoading, course, findCourse, reloadCourse, resortLessons } =
		useCourses({
			id: id
		});

	const [lessons, setLessons] = useState([]);
	const [tabValue, setTabValue] = useState(0);

	const ADD_LESSON_URL = useMemo(
		() => `/lessons/new?course_id=${id}&position=${lessons.length + 1}`,
		[id, lessons]
	);
	const EDIT_COURSE_URL = `/courses/${id}/edit`;

	useEffect(() => {
		if (id) findCourse(id);
	}, []);

	useEffect(() => {
		if (course?.lessons?.length) {
			setLessons(course.lessons);
		}
	}, [course]);

	const handleTabChange = (_, newValue) => {
		setTabValue(newValue);
	};

	const [buttonClickHandler, buttonText, buttonStartIcon] = useMemo(() => {
		return lessons.length === 0
			? [() => navigate(EDIT_COURSE_URL), 'Edit Course', null]
			: tabValue === 0
				? [() => navigate(EDIT_COURSE_URL), 'Edit Course', null]
				: [() => navigate(ADD_LESSON_URL), 'New Lesson', <Add />];
	}, [lessons, tabValue]);

	return (
		<>
			<SubheaderV2
				title={isLoading ? 'Loading...' : course.title}
				buttons={[
					<Button
						startIcon={buttonStartIcon}
						onClick={buttonClickHandler}
						color="primary"
						variant="contained"
					>
						{buttonText}
					</Button>
				]}
			/>
			<Container>
				<Tabs value={tabValue} onChange={handleTabChange}>
					<Tab label="Couse Details" value={0} />
					<Tab label="Lessons" value={1} />
				</Tabs>
				<Paper>
					{tabValue == 0 ? (
						<Box p={2}>
							<CourseDetails course={course} />
						</Box>
					) : (
						<LessonList
							lessons={lessons}
							level={course.level}
							resortLessons={resortLessons}
							setLessons={setLessons}
							reloadCourse={reloadCourse}
							handleAddClick={() => navigate(ADD_LESSON_URL)}
						/>
					)}
				</Paper>
			</Container>
		</>
	);
};

export default CourseShow;
