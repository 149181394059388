import { Grid } from '@material-ui/core';
import React from 'react';

import {
	Autosuggest,
	RewardAutosuggest,
	TextInput,
	UserAutosuggest
} from 'components';
import { REWARD_USER_STATUS } from 'lib/constants';

const AchievementForm = ({ achievement, isEditing, handleChange }) => {
	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<UserAutosuggest
					value={achievement?.user?.id}
					label="User"
					name="user_id"
					placeholder="Select user"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<RewardAutosuggest
					value={achievement?.reward?.id}
					label="Reward"
					name="reward_id"
					placeholder="Select reward"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={achievement.points_achieved || ''}
					label="Points Achieved"
					name="points_achieved"
					placeholder="Points Achieved"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12}>
				<Autosuggest
					options={REWARD_USER_STATUS}
					value={achievement.status || []}
					label="Status"
					name="status"
					placeholder=""
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	);
};

export default AchievementForm;
