import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Add, Search } from '@material-ui/icons';
import {
	Box,
	Button,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import { Subheader, Placeholder, SortableTableHeader } from 'components';
import { useAlerts, useNavigation } from 'hooks';
import SearchForm from 'components/Search';
import usePromoCodes from 'hooks/usePromoCodes';
import PromoCodeItem from './PromoCodeItem';
import { Pagination } from '@material-ui/lab';

const TABLE_HEADERS = [
	{
		value: 'promo_code',
		label: 'Promo Code',
		sortable: true,
		hidden: false
	},
	{
		value: 'created_at',
		label: 'Started Date',
		sortable: false,
		hidden: false
	},
	{
		value: 'expiration_date',
		label: 'Expiry Date',
		sortable: false,
		hidden: false
	},
	{
		value: 'redeems',
		label: 'Current Redeems',
		sortable: false,
		hidden: false
	},
	{
		value: 'active',
		label: 'Status',
		sortable: true,
		hidden: false
	}
];

const PromoCodeList = ({ ...props }) => {
	const navigate = useNavigate();

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/promo_codes'
	});

	const {
		findPromoCode,
		query,
		isLoading,
		isEmpty,
		findPromoCodes,
		sortKey,
		sortDirection,
		page,
		handleSort,
		promoCodes,
		deletePromoCode,
		reloadPromoCodes,
		paginate,
		numPages
	} = usePromoCodes({
		defaultSortKey: 'promo_codes.promo_code'
	});

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleDeleteClick = async (promo) => {
		const res = await deletePromoCode(promo);
		if (res.status === 200) {
			showAlertSuccess('Promo Code deleted');
		} else {
			showAlertError(res?.data?.message);
		}
		reloadPromoCodes();
	};

	useEffect(() => {
		findPromoCodes(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [query, page, sortDirection, sortKey]);

	return (
		<>
			<Container maxWidth="lg">
				<>
					<Paper>
						<SearchForm
							isLoading={isLoading}
							query={query}
							handleSearch={findPromoCode}
						/>

						{!isEmpty ? (
							<TableContainer component={Paper} elevation={0}>
								<Table>
									<TableHead>
										<TableRow>
											{TABLE_HEADERS.filter((h) => !h.hidden).map(
												(header, idx) => (
													<SortableTableHeader
														key={idx}
														handleSort={handleSort}
														value={`promo_codes.${header.value}`}
														sortable={header.sortable}
														sortKey={sortKey}
														sortDirection={sortDirection}
													>
														{header.label}
													</SortableTableHeader>
												)
											)}

											<TableCell></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{promoCodes.map((promo, idx) => (
											<PromoCodeItem
												key={idx}
												promo={promo}
												isSelectable={false}
												handleClick={handleClick}
												handleEditClick={handleEditClick}
												handleDeleteClick={handleDeleteClick}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<Placeholder
								icon={<Search />}
								title="No Promo Codes"
								subtitle="Please try another search"
							/>
						)}
					</Paper>
					{!isEmpty && (
						<Box my={1}>
							<Pagination
								onChange={(ev, page) => paginate(page)}
								color="primary"
								size="small"
								shape="rounded"
								page={page}
								count={numPages}
							/>
						</Box>
					)}
				</>
			</Container>
		</>
	);
};

export default PromoCodeList;
