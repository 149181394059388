import {
	Box,
	Button,
	Container,
	List,
	Paper,
	Typography
} from '@material-ui/core';
import api from 'api';
import { Subheader } from 'components';
import EditorialItem from 'features/topics/EditorialItem';
import TopicDetails from 'features/topics/TopicDetails';
import { useAlerts, useTopics } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const TopicShow = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const editorialContainerRef = useRef(null);

	const { topicId, isLoading, isLoaded, topic, findTopic } = useTopics({
		id: id
	});

	const [editorials, setEditorials] = useState([]);

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleEditClick = () => navigate(`/topics/${id}/edit`);

	const handleEditorialClick = (editorial) => {
		if (editorial.id) {
			navigate(`/editorials/${editorial.id}`);
		}
	};

	const handleTrainerClick = (trainer) => {
		if (trainer.id) {
			navigate(`/trainers/${trainer.id}`);
		}
	};

	useEffect(() => {
		if (id) findTopic(id);
	}, []);

	useEffect(() => {
		if (topic?.editorials?.length) {
			setEditorials(topic.editorials);
		}
	}, [topic]);

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		const items = reorder(
			editorials,
			result.source.index,
			result.destination.index
		);
		const sorted = items.map((item) => ({
			id: item.id,
			position: item.position
		}));
		api.post(`/api/v1/admin/topics/${topic.id}/resort`, {
			editorials: sorted
		});
		setEditorials(items);
	};

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : topic.title}
				buttons={[
					<Button onClick={handleEditClick} color="primary" variant="contained">
						Edit
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<TopicDetails topic={topic} />
					</Box>
				</Paper>
				<Box m={4} />
				{topic?.editorials?.length > 0 && (
					<>
						<Typography variant="h5">Editorials</Typography>
						<Paper>
							<List>
								{topic.editorials.map((editorial, i) => (
									<EditorialItem
										key={i}
										editorial={editorial}
										handleClick={() => handleEditorialClick(editorial)}
									/>
								))}
							</List>
						</Paper>
					</>
				)}
				<Box p={4} />
			</Container>
		</>
	);
};

export default TopicShow;

const getItemStyle = (_, draggableStyle) => ({
	userSelect: 'none',
	...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result.map((item, index) => ({
		...item,
		position: index + 1
	}));
};
