import { List, ListItem, Typography } from '@material-ui/core';
import { LineItem } from 'components';
import moment from 'moment';
import React from 'react';

const PromoCodeDetails = ({ promo }) => (
	<List>
		<LineItem value={promo.promo_code} label={'Promo Code'} />
		<LineItem value={promo.created_at} label={'Started Date'} />
		<LineItem value={promo.expiration_date} label={'Expiration Date'} />
		<LineItem value={promo.current_redeems} label={'Current Redeems'} />
		<LineItem value={promo.active ? 'Active' : 'Inactive'} label={'Status'} />
		<LineItem value={promo.promo_rule?.name} label={'Promo Rule'} />
		<LineItem value={promo.details} label={'Promo Details'} />
	</List>
);

export default PromoCodeDetails;
