import { Grid } from '@material-ui/core';
import { Autosuggest, TextInput } from 'components';
import React from 'react';

const SettingForm = ({ setting, isEditing, handleChange }) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={setting.version}
				label="Version"
				name="version"
				placeholder="Version"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12}>
			<Autosuggest
				options={[
					{ value: 'off', label: 'Off' },
					{ value: 'on', label: 'On' }
				]}
				value={setting.release_guard}
				label="Release Guard"
				name="release_guard"
				placeholder=""
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={setting.icon_width}
				label="Icon Width"
				name="icon_width"
				placeholder="Icon Width"
				type={'number'}
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={setting.thumbnail_width}
				label="Thumbnail Width"
				name="thumbnail_width"
				placeholder="Thumbmail Width"
				type={'number'}
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={setting.medium_width}
				label="Medium Width"
				name="medium_width"
				placeholder="Medium Width"
				type={'number'}
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={setting.large_width}
				label="Large Width"
				name="large_width"
				placeholder="Large Width"
				type={'number'}
				handleChange={handleChange}
			/>
		</Grid>
	</Grid>
);

export default SettingForm;
