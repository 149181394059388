import React from 'react';
import { Grid } from '@material-ui/core';
import {
	SwitchInput,
	JSONInput,
	TextInput,
	TopicAutosuggest
} from 'components';

const EditorialForm = ({ editorial, isEditing, handleChange }) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={editorial.title}
				label="Title"
				name="title"
				placeholder="Title"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				rows={3}
				multiline
				value={editorial.description}
				label="Description"
				name="description"
				placeholder="Description"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			{editorial.jw_captions && (
				<JSONInput
					value={editorial.jw_captions}
					label="JW Captions"
					name="jw_captions"
					placeholder="JW Player Captions"
					handleChange={handleChange}
				/>
			)}
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={editorial.jw_player_id}
				label="JW Player ID"
				name="jw_player_id"
				placeholder="JW Player ID"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={editorial.video_url}
				label="Video URL"
				name="video_url"
				placeholder="Video URL"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={editorial.video_length}
				label="Video length"
				name="video_length"
				placeholder="00:00:00"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12}>
			<TopicAutosuggest
				label="Select Topic"
				value={editorial.topic_id}
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<SwitchInput
				value={editorial.featured ? true : false}
				label="Featured"
				name="featured"
				placeholder="Featured"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<SwitchInput
				value={editorial.premium ? true : false}
				label="Premium"
				name="premium"
				placeholder="premium"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<SwitchInput
				value={editorial.published ? true : false}
				label="Published"
				name="published"
				placeholder="Published"
				handleChange={handleChange}
			/>
		</Grid>
	</Grid>
);

export default EditorialForm;
