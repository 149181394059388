import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, SubheaderV2 } from 'components';
import { useAlerts, useSkillCategories, useTrainingPlans } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TrainingPlanForm from 'features/trainingPlan/TrainingPlanForm';
import { trainingPlanValidation } from 'validations/trainingPlan';

const TrainingPlanEdit = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { showAlertError } = useAlerts();
	const { findTrainingPlan, trainingPlan, handleChange, updateTrainingPlan } =
		useTrainingPlans({ id: id });
	const { categories, findCategories } = useSkillCategories();

	const handleSaveClick = async () => {
		const valid = trainingPlanValidation(trainingPlan, 'edit');

		if (!valid.isValid) {
			showAlertError(valid.messages[0]);
			return;
		}

		const response = await updateTrainingPlan(trainingPlan);

		if (response.status && response.status !== 200) {
			const { message } = response.data;
			showAlertError(message);
			return;
		}

		navigate('/training-plans');
	};

	useEffect(() => {
		if (id) findTrainingPlan(id);
	}, [id]);

	useEffect(() => {
		findCategories();
	}, []);

	return (
		<>
			<SubheaderV2
				title="Edit Training Plan "
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Update Training Plan
					</Button>
				]}
			/>

			<Container>
				<Box p={4}>
					<TrainingPlanForm
						trainingPlan={trainingPlan}
						handleChange={handleChange}
						type={'Training Plan '}
						categories={categories}
						skillsPlaceholder={'Select one'}
						maxSkills={1}
					/>
				</Box>
			</Container>
		</>
	);
};

export default TrainingPlanEdit;
