import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader } from 'components';
import BenefitForm from 'features/benefits/BenefitForm';
import { useAlerts, useBenefits } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const BenefitEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const {
		benefit,
		handleChange,
		updateBenefit,
		findBenefit,
		uploadFile,
		deleteFile
	} = useBenefits({ id: id });

	const { showAlertSuccess } = useAlerts();

	const handleSaveClick = async () => {
		await updateBenefit(benefit);
		showAlertSuccess('Benefit has been updated');
		navigate(`/benefits/${id}`);
	};

	const handleUpload = async (file, type) => {
		await uploadFile(file, type);
		findBenefit(id);
	};

	const handleDelete = async (type) => {
		await deleteFile(type);
		findBenefit(id);
	};

	useEffect(() => {
		if (id) findBenefit(id);
	}, []);

	return (
		<>
			<Subheader
				title="Edit Benefit"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<center>
							<ImageUploader
								label="Upload thumbnail (2:1)"
								imageUrl={benefit.thumbnail_url}
								handleUpload={(file) => handleUpload(file, 'thumbnail_url')}
								handleDelete={() => handleDelete('thumbnail_url')}
							/>
							<ImageUploader
								label="Upload image (2:3)"
								imageUrl={benefit.image_url}
								handleUpload={(file) => handleUpload(file, 'image_url')}
								handleDelete={() => handleDelete('image_url')}
							/>
							<ImageUploader
								label="Upload web image (6:1)"
								imageUrl={benefit.web_image_url}
								handleUpload={(file) => handleUpload(file, 'web_image_url')}
								handleDelete={() => handleDelete('web_image_url')}
							/>
						</center>
						<BenefitForm benefit={benefit} handleChange={handleChange} />
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default BenefitEdit;
