import { Box, InputBase, Typography } from '@material-ui/core';
import { useStyles } from 'components/inputs/styles';
import { syntheticEvent } from 'lib/utils';
import PropTypes from 'prop-types';
import React from 'react';

const SearchInput = ({
	variant = 'outlined',
	margin,
	name,
	label,
	value,
	placeholder = 'Search...',
	onKeyPress,
	...props
}) => {
	const classes = useStyles();

	const handleClearClick = () => {
		let e = syntheticEvent('', name);
		props.handleChange(e);
		if (props.handleClearClick) props.handleClearClick();
	};

	const handleChange = (ev) => {
		let { name, value } = ev.target;
		props.handleChange(ev);
	};

	return (
		<Box my={1}>
			{label && (
				<Typography variant="body2" color="textSecondary">
					{label}
				</Typography>
			)}
			<InputBase
				className={classes.inputBase}
				type="text"
				fullWidth
				name={name}
				variant={variant}
				margin={margin}
				placeholder={placeholder}
				onKeyPress={onKeyPress}
				autoComplete="off"
				onChange={(e) => handleChange(e)}
				value={value}
			/>
		</Box>
	);
};

SearchInput.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	handleChange: PropTypes.func.isRequired
};

export default SearchInput;
