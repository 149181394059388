import React from 'react';
import { useResource } from 'hooks';

const useFeedbackRequests = ({ url = null }) => {
	const {
		id: feedbackRequestId,
		isLoading,
		resource: feedbackRequest,
		resources: feedbackRequests,
		errors: feedbackRequestErrors,
		findOne: findFeedbackRequest,
		findMany: findFeedbackRequests,
		isEmpty: isEmptyFeedbackRequest,
		setResources: setFeedbackRequests,
		destroy: deleteFeedbackRequest,
		...rest
	} = useResource({
		url: `/api/v1/admin/feedback_requests`,
		name: 'feedback_request',
		label: 'Feedback Request'
	});

	return {
		feedbackRequestId,
		feedbackRequest,
		feedbackRequests,
		feedbackRequestErrors,
		isLoading,
		findFeedbackRequest,
		findFeedbackRequests,
		isEmptyFeedbackRequest,
		setFeedbackRequests,
		deleteFeedbackRequest,
		...rest
	};
};

export default useFeedbackRequests;
