import { Box, Button, Container, Paper } from '@material-ui/core';
import { Subheader } from 'components';
import UserDetails from 'features/users/UserDetails';
import { useUsers } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const UserShow = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { isLoading, user, findUser } = useUsers({ id: id });

	const handleEditClick = () => navigate(`/users/${id}/edit`);

	useEffect(() => {
		if (id) findUser(id);
	}, []);

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : user.name}
				buttons={[
					<Button variant="contained" onClick={handleEditClick} color="primary">
						Edit
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<UserDetails user={user} />
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default UserShow;
