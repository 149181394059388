export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const USER_ROLES = [
	{ value: 'admin', label: 'Admin' },
	{ value: 'club', label: 'Club' },
	{ value: 'scout', label: 'Scout' },
	{ value: 'user', label: 'User' }
];

export const PAYMENT_PLATFORMS = [
	{ value: 'ios', label: 'iOS' },
	{ value: 'web', label: 'Web' },
	{ value: 'manual', label: 'Manual' }
];

export const DIFFICULTY = [
	{ value: 'beginner', label: 'Beginner' },
	{ value: 'intermediate', label: 'Intermediate' },
	{ value: 'advanced', label: 'Advanced' },
	{ value: 'professional', label: 'Professional' }
];

export const CATEGORIES = [
	{ value: 'control', label: 'Ball Control' },
	{ value: 'passing', label: 'Passing' },
	{ value: 'dribbling', label: 'Dribbling' },
	{ value: 'shooting', label: 'Shooting' },
	{ value: 'defending', label: 'Defending' },
	{ value: 'physicality', label: 'Physical' }
];

export const DISPLAY_POSITIONS = [
	// goalkeeper
	{ value: 'GK', label: 'Goalkeeper' },
	// defender
	{ value: 'LB', label: 'Left Back' },
	{ value: 'CB', label: 'Centre Back' },
	{ value: 'RB', label: 'Right Back' },
	{ value: 'LWB', label: 'Left Wing Back' },
	{ value: 'RWB', label: 'Right Wing Back' },
	// midfielder
	{ value: 'CDM', label: 'Defensive Midfielder' },
	{ value: 'CM', label: 'Centre Midfielder' },
	{ value: 'CAM', label: 'Attacking Midfielder' },
	{ value: 'LM', label: 'Left Midfielder' },
	{ value: 'RM', label: 'Right Midfielder' },
	// forward
	{ value: 'LW', label: 'Left Winger' },
	{ value: 'RW', label: 'Right Winger' },
	{ value: 'CF', label: 'Centre Forward' },
	{ value: 'ST', label: 'Striker' }
];

export const POSITIONS = [
	{ value: 'center_back', label: 'Centre Back' },
	{ value: 'full_back', label: 'Full Back' },
	{ value: 'midfielder', label: 'Midfielder' },
	{ value: 'winger', label: 'Winger' },
	{ value: 'striker', label: 'Striker' }
];

export const LEVELS = [
	{ value: 'beginner', label: 'BEGINNER' },
	{ value: 'amateur', label: 'AMATEUR' },
	{ value: 'semi-professional', label: 'SEMI-PROFESSIONAL' },
	{ value: 'professional', label: 'PROFESSIONAL' },
	{ value: 'world_class', label: 'WORLD CLASS' }
];

export const WHAT_YOU_NEED = [
	{ value: '1_player', label: '1 Player' },
	{ value: '2_players', label: '2 Players' },
	{ value: '3_players', label: '3 Players' },
	{ value: '4_players', label: '4 Players' },
	{ value: '5_players', label: '5 Players' },
	{ value: 'cone', label: 'Cone' },
	{ value: 'goal', label: 'Goal' },
	{ value: 'phone', label: 'Phone' },
	{ value: 'football', label: 'Football' },
	{ value: 'mannequin', label: 'Mannequin' },
	{ value: 'poles', label: 'Poles' }
];

export const PREFERRED_FOOT = [
	{ value: 'left', label: 'Left' },
	{ value: 'right', label: 'Right' }
];

/* Deprecated - do not use */
export const SKILLS = [
	{ value: 'control_points', label: 'Ball Control' },
	{ value: 'defending_points', label: 'Defending' },
	{ value: 'dribbling_points', label: 'Dribbling' },
	{ value: 'passing_points', label: 'Passing' },
	{ value: 'shooting_points', label: 'Shooting' },
	{ value: 'star_points', label: 'Star Quality' }
];

export const FEEDBACK_DRILL_STATUS = [
	{ value: 'active', label: 'Active' },
	{ value: 'inactive', label: 'Inactive' }
];

export const REWARD_STATUS = [
	{ value: 'active', label: 'Active' },
	{ value: 'inactive', label: 'Inactive' }
];

export const REWARD_USER_STATUS = [
	{ value: 'new', label: 'New' },
	{ value: 'user_contacted', label: 'User Contacted' }
];

export const LOTTERY_STATUS = [
	{ value: 'active', label: 'Active' },
	{ value: 'inactive', label: 'Inactive' }
];

export const LOTTERY_DRAW_STATUS = [
	{ value: 'active', label: 'Active' },
	{ value: 'recent_winner', label: 'Recent Winner' }
];

export const FEEDBACK_DRILL_SKILLS = [
	{
		skill1: {
			skill: 'shooting',
			title: 'sho'
		}
	},
	{
		skill2: {
			skill: 'control',
			title: 'con'
		}
	},
	{
		skill3: {
			skill: 'passing',
			title: 'pas'
		}
	},
	{
		skill4: {
			skill: 'dribbling',
			title: 'dri'
		}
	},
	{
		skill5: {
			skill: 'pace',
			title: 'pac'
		}
	},
	{
		skill6: {
			skill: 'defending',
			title: 'def'
		}
	}
];

export const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

export const PROMO_CODE_STATUS = [
	{ value: true, label: 'Active' },
	{ value: false, label: 'Inactive' }
];

export const BOOLEAN = [
	{ label: 'True', value: true },
	{ label: 'False', value: false }
];
