import React from 'react';
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	makeStyles,
	Typography
} from '@material-ui/core';
import mobile from 'is-mobile';
import { Close } from '@material-ui/icons';

const Modal = ({
	open,
	handleClose,
	icon,
	title,
	subtitle,
	actions: buttons,
	children,
	maxWidth,
	fullScreen
}) => {
	const classes = useStyles();

	return (
		<Dialog
			className={classes.dialog}
			fullWidth
			maxWidth={maxWidth || 'sm'}
			fullScreen={mobile() || fullScreen === true ? true : false}
			open={open}
			onClose={handleClose}
		>
			<DialogTitle className={classes.dialogTitle} onClose={handleClose}>
				<Box display="flex" justifyContent="space-between">
					<Box display="flex" flexDirection="row">
						<Typography variant="subtitle1" className={classes.title}>
							{title}
						</Typography>
					</Box>
					<Box>
						<IconButton className={classes.closeButton} onClick={handleClose}>
							<Close className={classes.closeIcon} />
						</IconButton>
					</Box>
				</Box>
			</DialogTitle>
			<DialogContent>
				{subtitle && <Typography variant="body1">{subtitle}</Typography>}
				<div>{children}</div>
			</DialogContent>
			{buttons && <DialogActions>{buttons}</DialogActions>}
		</Dialog>
	);
};

export default Modal;

const useStyles = makeStyles((theme) => ({
	title: {
		marginTop: theme.spacing(1)
	},
	dialog: {
		zIndex: 1500
	},
	dialogTitle: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		padding: theme.spacing(1, 1.5, 1, 2),
		width: '100%'
	},
	closeButton: {
		color: theme.palette.common.white,
		margin: 0
	},
	closeIcon: {
		color: theme.palette.common.white
	}
}));
