import { Grid, ListItem, Typography } from '@material-ui/core';
import React from 'react';

import { Image, LineItem } from 'components';
import { COUNTRIES } from 'lib/constants/countries';
import { removeUnderscoresAndCapitalize } from 'lib/utils';
import { capitalize, compose, filter, join, size, toString } from 'lodash/fp';

const UserDetails = ({ user }) => (
	<>
		<Grid xs={12} item container spacing={1}>
			<Grid xs={12} lg={12} item>
				<Typography component="span" variant="h3">
					Avatar
				</Typography>
			</Grid>
			<Grid xs={12} lg={12} item>
				<ListItem>
					<Image src={user.avatar_url} variant="rounded" size={240} />
				</ListItem>
			</Grid>

			<Grid xs={12} lg={12} item>
				<Typography component="span" variant="h3">
					Basic Information
				</Typography>
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Name" value={user.name} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Email" value={user.email} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Birthday" value={user.dob} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem
					label="Phone Number"
					value={(user.dail_code || '') + ' ' + user.phone_number}
				/>
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem
					label="Role"
					value={removeUnderscoresAndCapitalize(user.role)}
				/>
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem
					label="Paid"
					value={compose(capitalize, toString)(user.paid)}
				/>
			</Grid>

			<Grid xs={12} md={4} item>
				<LineItem label="Promo Code" value={user.promo_code} />
			</Grid>
			<Grid xs={12} md={8} item></Grid>

			<Grid xs={12} lg={12} item>
				<Typography component="span" variant="h3">
					Location
				</Typography>
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="City" value={user.city} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem
					label="Country"
					value={
						user.country
							? COUNTRIES.find((c) => c.value == user.country)?.label
							: '-'
					}
				/>
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Nationality" value={user.passport_country} />
			</Grid>
			<Grid xs={12} lg={12} item>
				<Typography component="span" variant="h3">
					Player Profile
				</Typography>
			</Grid>

			<Grid xs={12} md={4} item>
				<LineItem label="Summary" value={user.summary} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Player Level" value={user.player_level} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem
					label="Interested Opportunties"
					value={user.interested_opportunities}
				/>
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem
					label="Positions"
					value={compose(join(', '))(user.positions)}
				/>
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Preferred Foot" value={user.preferred_foot} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Current Club" value={user.current_club} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem
					label="Agent"
					value={compose(capitalize, toString)(user.agent)}
				/>
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Height (CM)" value={user.height} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Weight (KG)" value={user.weight} />
			</Grid>

			<Grid xs={12} md={4} item>
				<LineItem label="Education" value={user.education} />
			</Grid>
			<Grid xs={12} md={8} item></Grid>

			<Grid xs={12} lg={12} item>
				<Typography component="span" variant="h3">
					Favourites
				</Typography>
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Shirt Number" value={user.shirt} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Favorite Boot" value={user.favorite_boot} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Favorite Club" value={user.favorite_club} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Favorite Brand" value={user.favorite_brand} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Favorite Player" value={user.favorite_player} />
			</Grid>

			<Grid xs={12} lg={12} item>
				<Typography component="span" variant="h3">
					App Usage
				</Typography>
			</Grid>

			<Grid xs={12} md={4} item>
				<LineItem
					label="Player Card Total"
					value={user.player_card?.overall.value}
				/>
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Referral Points" value={user.community_points} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="XP" value={user.experience_points} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem
					label="Completed Lessons (count)"
					value={user.completed_lesson_ids?.length}
				/>
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem
					label="Completed Courses (count)"
					value={user.completed_course_ids?.length}
				/>
			</Grid>

			<Grid xs={12} md={4} item></Grid>

			<Grid xs={12} md={4} item>
				<LineItem
					label="Highlight uploads (count)"
					value={compose(
						toString,
						size,
						filter((v) => v.videoable_type === null)
					)(user.videos)}
				/>
			</Grid>

			<Grid xs={12} md={4} item>
				<LineItem
					label="Lesson uploads (count)"
					value={compose(
						toString,
						size,
						filter((v) => v.videoable_type === 'Lesson')
					)(user.videos)}
				/>
			</Grid>

			<Grid xs={12} md={4} item>
				<LineItem
					label="Feedback Attempt uploads (count)"
					value={compose(
						toString,
						size,
						filter((v) => v.videoable_type === 'FeedbackAttempt')
					)(user.videos)}
				/>
			</Grid>

			<Grid xs={12} lg={12} item>
				<Typography component="span" variant="h3">
					Contact Information
				</Typography>
			</Grid>
			<Grid xs={12} lg={12} item>
				<Typography component="span" variant="subtitle2">
					Parent / Guardian
				</Typography>
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Name" value={user.first_guardian?.name} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Email" value={user.first_guardian?.email} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem
					label="Phone Number"
					value={user.first_guardian?.phone_number}
				/>
			</Grid>
			<Grid xs={12} lg={12} item>
				<Typography component="span" variant="subtitle2">
					Second Parent / Guardian
				</Typography>
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Name" value={user.second_guardian?.name} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Email" value={user.second_guardian?.email} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem
					label="Phone Number"
					value={user.second_guardian?.phone_number}
				/>
			</Grid>
			<Grid xs={12} lg={12} item>
				<Typography component="span" variant="subtitle2">
					Coach
				</Typography>
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Name" value={user.coach?.name} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Email" value={user.coach?.email} />
			</Grid>
			<Grid xs={12} md={4} item>
				<LineItem label="Phone Number" value={user.coach?.phone_number} />
			</Grid>
		</Grid>
	</>
);

export default UserDetails;
