import React, { useState } from 'react';
import { FormControl, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import { syntheticEvent } from 'lib/utils';

const DatePicker = ({
	required,
	label,
	name,
	value,
	handleChange,
	placeholder,
	onKeyPress,
	...props
}) => {
	const [error, setError] = useState(false);

	const handleClearClick = () => {
		let ev = syntheticEvent('', name);
		handleChange(ev);
	};

	const handleInputChange = (ev) => {
		let { name, value } = ev.target;
		required && value === '' ? setError(true) : setError(false);
		handleChange(ev);
	};

	let selectedDate = moment(value).format('yyyy-MM-DD');

	return (
		<FormControl fullWidth>
			<Typography variant="body2" color="textSecondary">
				{label}
			</Typography>
			<TextField
				error={error}
				autoComplete="off"
				fullWidth
				type="date"
				name={name}
				placeholder={placeholder}
				margin="dense"
				onKeyPress={onKeyPress}
				variant="outlined"
				onChange={handleInputChange}
				value={selectedDate}
			/>
		</FormControl>
	);
};

export default DatePicker;
