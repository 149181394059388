import { Box, Button, Container, Paper } from '@material-ui/core';
import { Subheader } from 'components';
import ClubDetails from 'features/clubs/ClubDetails';
import { useAlerts, useClubs } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ClubShow = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { clubId, isLoading, isLoaded, club, findClub } = useClubs({
		id: id
	});

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleEditClick = () => navigate(`/clubs/${id}/edit`);

	useEffect(() => {
		if (id) findClub(id);
	}, []);

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : `Club ${club.id}`}
				buttons={[
					<Button onClick={handleEditClick} color="primary" variant="contained">
						Edit
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<ClubDetails club={club} />
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default ClubShow;
