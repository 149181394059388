import React, { useEffect } from 'react';

import {
	Box,
	Button,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import {
	Placeholder,
	Search,
	SortableTableHeader,
	Subheader
} from 'components';
import { Add } from '@material-ui/icons';

import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts, useNavigation, usePromoRules } from 'hooks';
import SearchForm from 'components/Search';
import PromoRuleItem from './PromoRuleItem';
import { Pagination } from '@material-ui/lab';

const TABLE_HEADERS = [
	{
		value: 'name',
		label: 'Name',
		sortable: true,
		hidden: false
	},
	{
		value: 'duration',
		label: 'Duration',
		sortable: false,
		hidden: false
	},
	{
		value: 'created_at',
		label: 'Date Created',
		sortable: false,
		hidden: false
	}
];

const PromoRulesList = () => {
	const navigate = useNavigate();

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/promo_rules'
	});

	const {
		promoRules,
		findPromoRule,
		query,
		isLoading,
		findPromoRules,
		sortKey,
		sortDirection,
		page,
		isEmpty,
		handleSort,
		deletePromoRule,
		reloadPromoRules,
		paginate,
		numPages
	} = usePromoRules({});
	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleDeleteClick = async (promo) => {
		const res = await deletePromoRule(promo);

		if (res.status === 200) {
			showAlertSuccess('Promo Code deleted');
		} else {
			showAlertError(res?.data?.message);
		}
		reloadPromoRules();
	};

	useEffect(() => {
		findPromoRules(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [query, page, sortDirection, sortDirection]);

	return (
		<Container>
			<Paper>
				<SearchForm
					isLoading={isLoading}
					query={query}
					handleSearch={findPromoRule}
				/>

				{!isEmpty ? (
					<TableContainer component={Paper} elevation={0}>
						<Table>
							<TableHead>
								<TableRow>
									{TABLE_HEADERS.filter((h) => !h.hidden).map((header, idx) => (
										<SortableTableHeader
											key={idx}
											handleSort={handleSort}
											value={`promo_rules.${header.value}`}
											sortable={header.sortable}
											sortKey={sortKey}
											sortDirection={sortDirection}
										>
											{header.label}
										</SortableTableHeader>
									))}

									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{promoRules.map((promo, idx) => (
									<PromoRuleItem
										promo={promo}
										key={idx}
										handleClick={handleClick}
										handleEditClick={handleEditClick}
										handleDeleteClick={handleDeleteClick}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<Placeholder
						icon={<Search />}
						title="No Promo Rules"
						subtitle="Please try another search"
					/>
				)}
			</Paper>
			{!isEmpty && (
				<Box my={1}>
					<Pagination
						onChange={(ev, page) => paginate(page)}
						color="primary"
						size="small"
						shape="rounded"
						page={page}
						count={numPages}
					/>
				</Box>
			)}
		</Container>
	);
};

export default PromoRulesList;
