import {
	Box,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Placeholder, SortableTableHeader } from 'components';
import RewardItem from 'features/rewards/RewardItem';
import RewardToolbar from 'features/rewards/RewardToolbar';
import SearchForm from 'features/rewards/SearchForm';
import { useAlerts, useNavigation, useRewards, useSelected } from 'hooks';

const TABLE_HEADERS = [
	{
		value: 'title',
		label: 'Title',
		sortable: true,
		hidden: false
	},

	{
		value: 'description',
		label: 'Description',
		sortable: true,
		hidden: false
	},

	{
		value: 'points',
		label: 'Points',
		sortable: true,
		hidden: false
	},

	{
		value: 'status',
		label: 'Status',
		sortable: true,
		hidden: false
	},
	{
		value: 'created_at',
		label: 'Created On',
		sortable: true,
		hidden: false
	}
];

const RewardList = ({ ...props }) => {
	const navigate = useNavigate();
	const status = 'active';

	const {
		isLoading,
		isEmpty,
		rewards,
		findRewards,
		deleteReward,
		paginate,
		query,
		page,
		numPages,
		reloadRewards,
		sortKey,
		sortDirection,
		handleSort
	} = useRewards({});

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/rewards'
	});

	const { selectedIds, handleSelect, selected, handleClear, totalSelected } =
		useSelected();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleDeleteClick = async (reward) => {
		await deleteReward(reward);
		showAlertSuccess('Reward has been deleted');
		reloadRewards();
	};

	React.useEffect(() => {
		findRewards(
			{
				sort_key: 'rewards.points',
				sort_direction: 'ASC'
			},
			page
		);
	}, [page, sortKey, sortDirection]);

	return (
		<>
			<Container maxWidth="lg">
				<>
					<Paper>
						<SearchForm
							isLoading={isLoading}
							query={query}
							handleSearch={findRewards}
						/>
						{!isEmpty ? (
							<TableContainer component={Paper} elevation={0}>
								<Table>
									<TableHead>
										<TableRow>
											{TABLE_HEADERS.filter((h) => !h.hidden).map(
												(header, idx) => (
													<SortableTableHeader
														key={idx}
														handleSort={handleSort}
														value={`rewards.${header.value}`}
														sortable={header.sortable}
														sortKey={sortKey}
														sortDirection={sortDirection}
													>
														{header.label}
													</SortableTableHeader>
												)
											)}
											<TableCell></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{rewards.map((reward, idx) => (
											<RewardItem
												key={idx}
												reward={reward}
												isSelectable={false}
												selectedIds={selectedIds}
												handleSelect={handleSelect}
												handleClick={handleClick}
												handleDeleteClick={handleDeleteClick}
												handleEditClick={handleEditClick}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<Placeholder
								icon={<Search />}
								title="No rewards"
								subtitle="Please try another search"
							/>
						)}
					</Paper>
					{!isEmpty && (
						<Box my={1}>
							<Pagination
								onChange={(ev, page) => paginate(page)}
								color="primary"
								size="small"
								shape="rounded"
								page={page}
								count={numPages}
							/>
						</Box>
					)}
				</>
				<RewardToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	);
};

export default RewardList;
