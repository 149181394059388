import React from 'react';

import { Modal } from 'components';
import TriviaAnswerForm from 'features/trainingPlan/trainingPlan/trivia/TriviaAnswerForm';

const AnswerModal = ({
	open,
	handleClose,
	action,
	question,
	findTrainingPlan,
	handleChange,
	triviaAnswer,
	createTriviaAnswer,
	updateTriviaAnswer,
	...rest
}) => {
	return (
		<Modal open={open} handleClose={handleClose} {...rest}>
			<TriviaAnswerForm
				action={action}
				handleClose={handleClose}
				question={question}
				findTrainingPlan={findTrainingPlan}
				handleChange={handleChange}
				triviaAnswer={triviaAnswer}
				createTriviaAnswer={createTriviaAnswer}
				updateTriviaAnswer={updateTriviaAnswer}
			/>
		</Modal>
	);
};

export default AnswerModal;
