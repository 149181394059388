import { Box, Container, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';

import { Subheader } from 'components';
import ReferralDetails from 'features/referrals/ReferralDetails';
import { useReferrals } from 'hooks';

const ReferralShow = ({ ...props }) => {
	const { id } = useParams();

	const { isLoading, referral, findReferral } = useReferrals({
		id: id
	});

	useEffect(() => {
		if (id) findReferral(id);
	}, []);

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : referral.title}
				buttons={[]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<ReferralDetails referral={referral} />
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default ReferralShow;
