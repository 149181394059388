import { StylesProvider } from '@material-ui/core/styles';
import React from 'react';
import { createRoot } from 'react-dom/client';
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter } from 'react-router-dom';

import App from 'App';
import { AppProvider } from 'context';

const rootElement = document.getElementById('root');

const root = createRoot(rootElement);

root.render(
	<HttpsRedirect>
		<BrowserRouter>
			<AppProvider>
				<StylesProvider injectFirst>
					<App />
				</StylesProvider>
			</AppProvider>
		</BrowserRouter>
	</HttpsRedirect>
);
