import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader } from 'components';
import BenefitForm from 'features/benefits/BenefitForm';
import { useAlerts, useBenefits, useUploadFileInMemory } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const BenefitNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();

	const { benefit, handleChange, createBenefit, findBenefit, deleteFile } =
		useBenefits({ id: id });

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		await createBenefit(benefit, memoryFile);
		showAlertSuccess('Benefit has been created');
		navigate('/benefits');
	};

	useEffect(() => {
		if (id) findBenefit(id);
	}, []);

	const handleDelete = async (type) => {
		removeFile();
	};

	return (
		<>
			<Subheader
				title="Add Benefits"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<center>
						<ImageUploader
							label="Upload thumbnail (2:1)"
							imageUrl={benefit.thumbnail_url || memoryFile?.thumbnail_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'thumbnail_url')}
							handleDelete={() => handleDelete('thumbnail_url')}
						/>
						<ImageUploader
							label="Upload image (2:3)"
							imageUrl={benefit.image_url || memoryFile?.image_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'image_url')}
							handleDelete={() => handleDelete('image_url')}
						/>
						<ImageUploader
							label="Upload web image (6:1)"
							imageUrl={benefit.web_image_url || memoryFile?.web_image_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'web_image_url')}
							handleDelete={() => handleDelete('web_image_url')}
						/>
					</center>
					<BenefitForm benefit={benefit} handleChange={handleChange} />
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default BenefitNew;
