import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SubheaderV2 } from 'components';
import LessonForm from 'features/lessons/LessonForm';
import {
	useAlerts,
	useCategory,
	useLessons,
	useUploadFileInMemory
} from 'hooks';
import { validateLesson } from 'validations/lessons';

const LessonEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { memoryFile } = useUploadFileInMemory();

	const {
		lesson,
		handleChange,
		updateLesson,
		findLesson,
		deleteFile,
		uploadFile
	} = useLessons({
		id: id
	});

	const { categories, findCategories, query, page, sortKey, sortDirection } =
		useCategory({});

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const valid = validateLesson(lesson);
		if (valid.isValid) {
			await updateLesson(lesson);
			showAlertSuccess('Lesson has been updated');
			navigate(`/lessons/${id}`);
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleUpload = async (file, type) => {
		await uploadFile(file, type);
		findLesson(id);
	};

	const handleDelete = async (type) => {
		await deleteFile(type);
		findLesson(id);
	};

	useEffect(() => {
		if (id) findLesson(id);
	}, []);

	useEffect(() => {
		findCategories(
			{
				...query
			},
			page
		);
	}, [query, page, sortKey, sortDirection]);

	return (
		<>
			<SubheaderV2
				title="Update an existing lesson"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save Lesson
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<LessonForm
						lesson={lesson}
						categories={categories}
						memoryFile={memoryFile}
						handleUpload={handleUpload}
						handleDelete={handleDelete}
						handleChange={handleChange}
					/>
				</Box>
			</Container>
		</>
	);
};

export default LessonEdit;
