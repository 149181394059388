import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const useNavigation = ({ url = '' }) => {
	const navigate = useNavigate();

	const handleShowClick = (resource) => navigate(`${url}/${resource.id}`);
	const handleEditClick = (resource) => navigate(`${url}/${resource.id}/edit`);
	const handleAddClick = () => navigate(`${url}/new`);

	return {
		handleClick: handleShowClick,
		handleShowClick,
		handleEditClick,
		handleAddClick,

		toShow: handleShowClick,
		toEdit: handleEditClick,
		toAdd: handleAddClick
	};
};

export default useNavigation;
