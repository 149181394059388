import React from 'react';
import { useMenu } from 'hooks';
import {
	Hidden,
	Button,
	IconButton,
	TableRow,
	TableCell,
	Menu,
	MenuItem,
	Avatar,
	Checkbox
} from '@material-ui/core';
import { Image } from 'components';
import { MoreHoriz, Person } from '@material-ui/icons';

const EditorialItem = ({
	editorial,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleEditClick(editorial, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(editorial, e)}>
				Delete
			</MenuItem>
		</Menu>
	);

	const isSelected = selectedIds.includes(editorial.id);

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(editorial)}
						name="editorial"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(editorial)}>
					<Image src={editorial.image_url} variant="rounded" size={80} />
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(editorial)}>
					{editorial.title}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(editorial)}>
					{editorial.featured ? 'Yes' : '-'}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(editorial)}>
					{editorial.published ? 'Yes' : '-'}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(editorial)}>
					{editorial.premium ? 'Yes' : '-'}
				</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default EditorialItem;
