import React from 'react';

import { useResource } from 'hooks';

const useAchievementUsers = ({ id, ...props }) => {
	const [filters, setFilters] = React.useState({
		status: undefined
	});

	const resetFilters = () => {
		setFilters({
			status: undefined
		});
	};

	const {
		resourceId: achievementId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: achievement,
		resources: achievements,
		findOne: findAchievement,
		findMany: findAchievements,
		update: updateAchievement,
		create: createAchievement,
		save: saveAchievement,
		destroy: deleteAchievement,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		refresh: reloadAchievement,
		refreshMany: reloadAchievements,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		setResource: setAchievement
	} = useResource({
		id: id,
		url: '/api/v1/admin/achievements',
		name: 'achievement'
	});

	return {
		achievementId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		achievement,
		achievements,
		findAchievement,
		findAchievements,
		saveAchievement,
		updateAchievement,
		createAchievement,
		deleteAchievement,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadAchievement,
		reloadAchievements,
		filters,
		setFilters,
		resetFilters,
		setAchievement
	};
};

export default useAchievementUsers;
