import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader, VideoUploader } from 'components';
import { useAlerts, useFeedbackDrills, useUploadFileInMemory } from 'hooks';
import { validateFeedbackDrill } from 'validations/feedback';
import FeedbackDrillForm from './FeedbackDrillForm';

const FeedbackDrillNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const {
		feedbackDrill,
		handleChange,
		createFeedbackDrill,
		findFeedbackDrill,
		setFeedbackDrill
	} = useFeedbackDrills({ id: id });

	const handleSaveClick = async () => {
		const valid = validateFeedbackDrill(feedbackDrill);
		if (valid.isValid) {
			await createFeedbackDrill(feedbackDrill, memoryFile);
			showAlertSuccess('FeedbackDrill has been created');
			navigate('/feedback');
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleDelete = async (type) => {
		removeFile();
	};

	React.useEffect(() => {
		if (id) findFeedbackDrill(id);
	}, []);

	React.useEffect(
		() => setFeedbackDrill({ ...feedbackDrill, status: 'active' }),
		[]
	);

	return (
		<>
			<Subheader
				title="Add Feedback Drill"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<center>
						<ImageUploader
							label="Upload thumbnail (2:3)"
							imageUrl={
								feedbackDrill.thumbnail_url || memoryFile?.thumbnail_url?.url
							}
							handleUpload={(file) => handleUploadFile(file, 'thumbnail_url')}
							handleDelete={() => handleDelete('thumbnail_url')}
						/>
						<ImageUploader
							label="Upload image (3:2)"
							imageUrl={feedbackDrill.image_url || memoryFile?.image_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'image_url')}
							handleDelete={() => handleDelete('image_url')}
						/>
						<ImageUploader
							label="Upload web image"
							imageUrl={
								feedbackDrill.web_image_url || memoryFile?.web_image_url?.url
							}
							handleUpload={(file) => handleUploadFile(file, 'web_image_url')}
							handleDelete={() => handleDelete('web_image_url')}
						/>
						<VideoUploader
							label="Upload video"
							videoUrl={feedbackDrill.video_url || memoryFile?.video_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'video_url')}
							handleDelete={() => handleDelete('video_url')}
						/>
					</center>
					<FeedbackDrillForm
						feedbackDrill={feedbackDrill}
						handleChange={handleChange}
					/>
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default FeedbackDrillNew;
