import React, { useState } from 'react';
import { Grid, IconButton, InputAdornment } from '@material-ui/core';
import { TextInput } from 'components';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const PartnerForm = ({ partner, handleChange }) => {
	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<TextInput
						name="name"
						label="Partner Name"
						fullWidth
						value={partner.name}
						placeholder="Partner Name"
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextInput
						name="email"
						label="Partner Login Email"
						fullWidth
						value={partner.email}
						placeholder="Partner Login Email"
						onChange={handleChange}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default PartnerForm;
