import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader } from 'components';
import TopicForm from 'features/topics/TopicForm';
import { useAlerts, useTopics } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateTopic } from 'validations/topics';

const TopicEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const {
		topicId,
		isLoading,
		isLoaded,
		topic,
		handleChange,
		updateTopic,
		findTopic,
		uploadFile,
		deleteFile,
		checkTopicOrder
	} = useTopics({ id: id });

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const valid = validateTopic(topic);
		if (valid.isValid) {
			const status = await checkTopicOrder({
				order: topic.order,
				id: topic.id
			});
			if (status.available) {
				await updateTopic(topic);
				showAlertSuccess('Topic has been updated');
				navigate(`/topics/${id}`);
			} else {
				showAlertError(status.message || 'Order number already taken');
			}
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleUpload = async (file, type) => {
		await uploadFile(file, type);
		findTopic(id);
	};

	const handleDelete = async (type) => {
		await deleteFile(type);
		findTopic(id);
	};

	useEffect(() => {
		if (id) findTopic(id);
	}, []);

	return (
		<>
			<Subheader
				title="Edit Topic"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<center>
							<ImageUploader
								label="Upload thumbnail (2:3)"
								imageUrl={topic.thumbnail_url}
								handleUpload={(file) => handleUpload(file, 'thumbnail_url')}
								handleDelete={() => handleDelete('thumbnail_url')}
							/>
							<ImageUploader
								label="Upload image (3:2)"
								imageUrl={topic.image_url}
								handleUpload={(file) => handleUpload(file, 'image_url')}
								handleDelete={() => handleDelete('image_url')}
							/>
							<ImageUploader
								label="Upload web image"
								imageUrl={topic.web_image_url}
								handleUpload={(file) => handleUpload(file, 'web_image_url')}
								handleDelete={() => handleDelete('web_image_url')}
							/>
							<ImageUploader
								label="Upload web image 2"
								imageUrl={topic.web_image2_url}
								handleUpload={(file) => handleUpload(file, 'web_image2_url')}
								handleDelete={() => handleDelete('web_image2_url')}
							/>
						</center>
						<TopicForm topic={topic} handleChange={handleChange} />
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default TopicEdit;
