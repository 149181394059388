import {
	Casino,
	EmojiEvents,
	Feedback,
	LocalOffer,
	PeopleOutlined,
	Person,
	Redeem,
	School,
	Tv,
	Settings,
	Videocam,
	CalendarToday,
	SportsSoccerOutlined,
	TimelapseOutlined,
	CategoryOutlined
} from '@material-ui/icons';

export const NO_AUTH_ROUTES = [
	'/',
	'/login',
	'/forgot_password',
	'/verify_pin',
	'/reset_password'
];

export const MENU_ITEMS = {
	loggedIn: [
		{
			title: 'User Information',
			icon: Person,
			items: [
				{ text: 'Users', value: '/users' },
				{ text: 'Feedback Submissions', value: '/feedback' },
				{ text: 'Videos', value: '/videos' }
			]
		},

		// Training
		{
			title: 'Training',
			icon: TimelapseOutlined,
			items: [
				{ text: 'Lessons', value: '/lessons' },
				{ text: 'Training Plans', value: '/training-plans' },
				{ text: 'Benchmark Drills', value: '/training-plan-drills' },
				{ text: 'Trainers', value: '/trainers' }
			]
		},

		{
			title: 'Promotional',
			icon: Redeem,
			items: [
				{ text: 'Promo Codes', value: '/promo_codes' },
				{ text: 'Rewards', value: '/rewards' },
				{ text: 'Member Benefits', value: '/benefits' },
				{ text: 'Lotteries', value: '/lotteries' }
			]
		},

		{
			title: 'Community',
			icon: PeopleOutlined,
			items: [
				{ text: 'Events & Partners', value: '/events-partners' },
				{ text: 'Editorials', value: '/editorials' },
				{ text: 'Topics', value: '/topics' }
			]
		},

		{
			title: 'Categories',
			icon: CategoryOutlined,
			items: [
				{ text: 'Skills Category', value: '/categories' },
				{ text: 'Positions', value: '/positions' }
			]
		},

		{
			title: 'Settings',
			icon: Settings,
			items: [{ text: 'Settings', value: '/settings' }]
		}
	],

	loggedOut: [
		{
			title: 'Auth',
			items: [
				{ text: 'Sign In', value: '/login' },
				{ text: 'Reset password', value: '/forgot_password' }
			]
		}
	]
};
