import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	SwipeableDrawer,
	Typography,
	withWidth,
	makeStyles,
	Button,
	ListItemSecondaryAction,
	Icon
} from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import clsx from 'clsx';
import { MenuItem, OnlineStatusAvatar, UserMenu, Logo } from 'components';
import { AppContext } from 'context';
import { useAuth, useMenu } from 'hooks';
import { MENU_ITEMS, NO_AUTH_ROUTES } from 'lib/navigation';
import { withStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const CustomAccordion = withStyles({
	root: {
		border: 'none',
		boxShadow: 'none',
		'&:before': {
			display: 'none'
		}
	}
})(Accordion);

const Drawer = ({ open, width, toggleMenu, ...props }) => {
	const classes = useStyles();
	const navigate = useNavigate();

	const { pathname } = useLocation();

	const [isMobile, setIsMobile] = useState(false);

	const { authenticated, currentUser } = useContext(AppContext);

	const { fetchMe, logoutUser } = useAuth();

	const { open: openMenu, anchorEl, closeMenu, handleToggleMenu } = useMenu();

	const handleLogoutClick = () => logoutUser();

	const handleClick = (url) => {
		closeMenu();
		toggleMenu();
		animateScroll.scrollToTop();
		navigate(url);
	};

	useEffect(() => {
		if (!currentUser && !NO_AUTH_ROUTES.includes(pathname)) {
			fetchMe();
		}
	}, [authenticated]);

	useLayoutEffect(() => {
		setIsMobile(['xs', 'sm'].includes(width) ? true : false);
	}, [width]);

	return (
		<SwipeableDrawer
			open={open}
			variant={isMobile ? 'temporary' : 'permanent'}
			onOpen={toggleMenu}
			onClose={toggleMenu}
		>
			<Box
				display="flex"
				justifyContent="space-between"
				flexDirection="column"
				height="100%"
			>
				<List component="nav" className={classes.list}>
					<Box p={4}>
						<center>
							<Logo />
						</center>
					</Box>
					{MENU_ITEMS[currentUser ? 'loggedIn' : 'loggedOut'].map(
						(category, idx) =>
							category.title === 'Auth' ? (
								category.items.map((item, i) => (
									<MenuItem
										key={i}
										active={pathname === item.value}
										value={item.value}
										text={item.text}
										icon={item.icon}
										handleClick={() => handleClick(item.value)}
										className={classes.childItem}
									/>
								))
							) : (
								<CustomAccordion key={idx} className={classes.accordionList}>
									<AccordionSummary
										expandIcon={
											<ExpandMore className={classes.accordionSummary} />
										}
										className={classes.accordionSumm}
									>
										<ListItem variant="h6" className={classes.accordionSummary}>
											{category.icon && (
												<ListItemIcon className={classes.icon}>
													<category.icon />
												</ListItemIcon>
											)}
											<ListItemText
												primary={category.title}
												classes={{ primary: classes.text }}
											/>
										</ListItem>
									</AccordionSummary>
									<AccordionDetails className={classes.accordionDetails}>
										<List component="nav">
											{category.items.map((item, i) => (
												<MenuItem
													key={i}
													active={pathname === item.value}
													value={item.value}
													text={item.text}
													icon={item.icon}
													handleClick={() => handleClick(item.value)}
													className={classes.childItem}
												/>
											))}
										</List>
									</AccordionDetails>
								</CustomAccordion>
							)
					)}
				</List>
			</Box>
			{currentUser && (
				<List className={classes.onlineStatusList}>
					<ListItem>
						<ListItemIcon>
							<OnlineStatusAvatar online={true} user={currentUser} />
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography variant="h5" className={classes.text}>
									{currentUser.name}
								</Typography>
							}
							secondary={
								<Typography variant="body2" className={classes.text}>
									{currentUser.role}
								</Typography>
							}
						/>
						<ListItemSecondaryAction>
							<IconButton onClick={handleToggleMenu}>
								<ExpandMore className={classes.icon} />
							</IconButton>
							<UserMenu
								open={openMenu}
								anchorEl={anchorEl}
								handleToggleMenu={handleToggleMenu}
								handleLogoutClick={handleLogoutClick}
								handleClick={handleClick}
							/>
						</ListItemSecondaryAction>
					</ListItem>
				</List>
			)}
		</SwipeableDrawer>
	);
};

export default withWidth()(Drawer);

const useStyles = makeStyles((theme) => ({
	logo: {
		width: 200
	},
	list: {
		backgroundColor: theme.palette.secondary.main,
		width: '300px',
		height: '100%',
		padding: theme.spacing(0)
	},
	icon: {
		color: 'white',
		fontSize: 20,
		paddingRight: theme.spacing(1)
	},
	item: {
		display: 'block',
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
		fontWeight: theme.typography.fontWeightMedium
	},
	childItem: {
		paddingLeft: theme.spacing(3)
	},
	button: {
		padding: theme.spacing(1, 1),
		justifyContent: 'flex-start',
		textTransform: 'none',
		fontSize: '14px',
		letterSpacing: 0,
		width: '100%',
		fontWeight: theme.typography.fontWeightMedium
	},
	text: {
		color: theme.palette.common.white
	},
	secondaryIcon: {
		marginLeft: 'auto',
		height: 20,
		width: 20
	},
	onlineStatusList: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
		paddingTop: theme.spacing(0.5)
	},
	accordionList: {
		backgroundColor: theme.palette.secondary.main,
		width: '300px',
		padding: theme.spacing(0)
	},
	accordionSumm: {
		marginTop: '-5px',
		marginBottom: '-5px'
	},
	accordionSummary: {
		color: theme.palette.common.white,
		minHeight: theme.spacing(4),
		padding: theme.spacing(0, 1, 0, 0),
		'& .MuiAccordionSummary-content': {
			margin: theme.spacing(0)
		}
	},
	accordionDetails: {
		marginTop: '-30px',
		marginBottom: '-30px'
	},
	expanded: {}
}));
