import React from 'react';
import { Button } from '@material-ui/core';
import { Toolbar } from 'components';
import styled from 'styled-components';

const ClubToolbar = ({ totalSelected, selectedIds, selected, handleClear }) => (
	<Toolbar open={selectedIds?.length > 0} handleClose={handleClear}></Toolbar>
);

export default ClubToolbar;

const StyledButton = styled(Button)`
	margin-right: 10px;
`;
