import {
	getCollection as getFeedbackAttemptCollection,
	getSingle as getFeedbackAttemptSingle
} from '../data/feedback-attempts';
import {
	getCollection as getFeedbackDrillCollection,
	getSingle as getFeedbackDrillSingle
} from '../data/feedback-drills';
import {
	getCollection as testGetCollection,
	getSingle as testGetSingle
} from '../data/test';
import { getCollection as getRewardCollection } from '../data/rewards';
import { getCollection as getLotteryCollection } from '../data/lotteries';
import {
	getCollection as getCourseCollection,
	getSingle as getCourseSingle
} from '../data/courses';
import { getCollection as getPartnersCollection } from '../data/partners';
import { getCollection as getEventsCollection } from '../data/events';

import {
	getCollection as getLeaderboardCollection,
	getSingle as getLeaderboardSingle
} from '../data/leaderboards';

const fetchData = (id, resource) => {
	switch (resource) {
		case 'test_data':
			if (id === null) {
				return testGetCollection;
			} else {
				return { id, ...testGetSingle };
			}
		case 'feedback_drills':
			if (id === null) {
				return getFeedbackDrillCollection;
			} else {
				return { id, ...getFeedbackDrillSingle };
			}
		case 'feedback_attempts':
			if (id === null) {
				return getFeedbackAttemptCollection;
			} else {
				return { id, ...getFeedbackAttemptSingle };
			}
		case 'rewards':
			return getRewardCollection;
		case 'lotteries':
			return getLotteryCollection;
		case 'leaderboards':
			if (id === null) {
				return getLeaderboardCollection;
			} else {
				// return { id, ...getLeaderboardCollection.find((lesson) => lesson.id == id) };
				return { id, ...getLeaderboardSingle[id - 1] };
			}
		case 'courses':
			if (typeof id === 'undefined' || id === null) {
				return getCourseCollection;
			} else {
				return { id, ...getCourseCollection.find((course) => course.id == id) };
			}
		case 'partners':
			if (typeof id === 'undefined' || id === null) {
				return getPartnersCollection;
			} else {
				return {
					id,
					...getPartnersCollection.find((partner) => partner.id == id)
				};
			}
		case 'events':
			if (typeof id === 'undefined' || id === null) {
				return getEventsCollection;
			} else {
				return { id, ...getEventsCollection.find((event) => event.id == id) };
			}
		default:
			return {};
	}
};

export default fetchData;
