import React from 'react';
import api from 'api';
import { useAlerts, useLoaders, useResource } from 'hooks';

const usePartners = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders();
	const { showAlertError, showAlertSuccess } = useAlerts();

	const {
		resourceId: partnerId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: partner,
		resources: partners,
		findOne: findPartner,
		findMany: findPartners,
		update: updatePartner,
		create: createPartner,
		save: savepartner,
		destroy: deletePartner,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadpartner,
		reloadMany: reloadPartners,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort
	} = useResource({
		id: id,
		url: '/api/v1/admin/partners',
		name: 'partner'
	});

	return {
		partnerId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		partner,
		partners,
		findPartner,
		findPartners,
		savepartner,
		updatePartner,
		createPartner,
		deletePartner,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadpartner,
		reloadPartners
	};
};

export default usePartners;
