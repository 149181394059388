import React from 'react';
import { useMenu } from 'hooks';
import {
	Button,
	IconButton,
	TableRow,
	TableCell,
	Menu,
	MenuItem,
	Checkbox
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';

const SkillItem = ({
	category,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleEditClick(category, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(category, e)}>
				Delete
			</MenuItem>
		</Menu>
	);

	const isSelected = selectedIds.includes(category.id);

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(category)}
						name="topic"
						color="primary"
					/>
				</TableCell>
			)}

			<TableCell>
				<Button onClick={() => handleClick(category)}>{category.name}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(category)}>{category.value}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(category)}>
					{category?.description}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(category)}>
					{category.created_at}
				</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default SkillItem;
