import React, { useCallback, useEffect } from 'react';
import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts, usePromoRules } from 'hooks';
import PromoRuleForm from './PromoRuleForm';
import { validatePromoRule } from 'validations/promoRule';

const PromoRuleNew = () => {
	const navigate = useNavigate();

	const { promoRule, handleChange, createPromoRule, promoRuleId } =
		usePromoRules({});
	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const { isValid, messages } = validatePromoRule(promoRule);

		if (isValid) {
			await createPromoRule(promoRule);
		} else {
			messages.map((m) => showAlertError(m));
		}
	};

	const navigateToPromoRuleShow = useCallback(() => {
		if (promoRuleId) {
			showAlertSuccess('Promo Rule Created');
			navigate(`/promo_rules/${promoRuleId}`);
		}
	}, [promoRuleId]);

	useEffect(() => navigateToPromoRuleShow(), [navigateToPromoRuleShow]);

	return (
		<>
			<Subheader
				title="Add Promo Rule"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<PromoRuleForm promo={promoRule} handleChange={handleChange} />
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default PromoRuleNew;
