import {
	Button,
	Checkbox,
	IconButton,
	Menu,
	MenuItem,
	TableCell,
	TableRow
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import React from 'react';

import { useMenu } from 'hooks';
import { ellipsis, properCase } from 'lib/utils';

const RewardItem = ({
	reward,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleEditClick(reward, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(reward, e)}>Delete</MenuItem>
		</Menu>
	);

	const isSelected = selectedIds.includes(reward.id);

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(reward)}
						name="reward"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(reward)}>{reward.title}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(reward)}>
					{ellipsis(reward.description, 50)}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(reward)}>{reward.points}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(reward)}>
					{properCase(reward.status)}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(reward)}>{reward.created_at}</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default RewardItem;
