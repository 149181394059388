import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { LineItem, GoogleMap } from 'components';

const ClubDetails = ({ club }) => (
	<div>
		<List>
			<ListItem>
				<img src={club.image_url} width={240} />
			</ListItem>
			<LineItem label="Name" value={club.name} />
			<LineItem label="City" value={club.city} />
			<LineItem label="Country" value={club.country} />
			<LineItem label="Secretary" value={club.secretary} />
			<LineItem label="Contact phone" value={club.contact_phone} />
			<LineItem label="Contact email" value={club.contact_email} />
			<LineItem label="Main ground" value={club.main_ground} />
			<LineItem label="Training ground" value={club.training_ground} />
			<LineItem label="Level" value={club.level} />
			<LineItem label="Website" value={club.website} />
		</List>
		{club.lat && club.lng && <GoogleMap lat={club.lat} lng={club.lng} />}
	</div>
);

export default ClubDetails;
