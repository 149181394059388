import React from 'react';

import { Grid, makeStyles, Typography } from '@material-ui/core';
import fp from 'lodash/fp';

import { DisplayImage, DisplayText } from 'components';

const TrainingPlanDrillDetails = ({ trainingPlan }) => {
	const classes = useStyles();
	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<DisplayText label={'Drill Name'} text={trainingPlan?.name} />
				<DisplayText
					label={'Drill Description'}
					text={trainingPlan?.description}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<DisplayText
					label={'Skill Categories'}
					text={fp.compose(fp.join(', '))(trainingPlan?.category_names)}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<DisplayText
					label={'Publised Course'}
					text={trainingPlan?.published ? 'Yes' : 'No'}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<DisplayText label={'Created At'} text={trainingPlan?.created_at} />
			</Grid>
			<Grid item xs={12} md={4}>
				<DisplayText
					label={'Main Video Modifier'}
					text={trainingPlan?.video_modifier_main}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<DisplayText
					label={'Clip Video Modifier'}
					text={trainingPlan?.video_modifier_clip}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<DisplayText
					label={'Thumbnail Video Modifier'}
					text={trainingPlan?.video_modifier_thumbnail}
				/>
			</Grid>
			{trainingPlan?.video_url && (
				<Grid clas item xs={12} md={6} className={classes.item}>
					<Typography component="span" variant="body2">
						{'Lesson Video'}
					</Typography>
					<video
						src={trainingPlan.video_url}
						controls
						muted
						height="240"
						width="auto"
					/>
				</Grid>
			)}
			{trainingPlan?.video2_url && (
				<Grid item xs={12} md={6} className={classes.item}>
					<Typography component="span" variant="body2">
						{'Lesson Preview Video'}
					</Typography>
					<video
						src={trainingPlan.video_url}
						controls
						muted
						height="240"
						width="auto"
					/>
				</Grid>
			)}
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	item: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	}
}));

export default TrainingPlanDrillDetails;
