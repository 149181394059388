import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { LineItem, Image } from 'components';

const CategoryDetails = ({ catyegory }) => (
	<List>
		<LineItem label="Name" value={catyegory.name} />
		<LineItem label="Value" value={catyegory.value} />
		<LineItem label="Description" value={catyegory?.description} />
		<LineItem label="Created At" value={catyegory.created_at} />
	</List>
);

export default CategoryDetails;
