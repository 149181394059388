import {
	Box,
	Button,
	Checkbox,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import { Add, Search as SearchIcon } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { useAlerts, useNavigation, useSelected, useUsers } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
	Placeholder,
	Search,
	SortableTableHeader,
	Subheader
} from 'components';
import UserItem from 'features/users/UserItem';
import UserToolbar from 'features/users/UserToolbar';

const TABLE_HEADERS = [
	{
		value: 'avatar_url',
		label: 'Avatar',
		sortable: false,
		hidden: false
	},
	{
		value: 'first_name',
		label: 'First name',
		sortable: true,
		hidden: false
	},
	{
		value: 'last_name',
		label: 'Last name',
		sortable: true,
		hidden: false
	},
	{
		value: 'email',
		label: 'Email',
		sortable: true,
		hidden: false
	},
	{
		value: 'role',
		label: 'Role',
		sortable: true,
		hidden: false
	},
	{
		value: 'paid',
		label: 'Paid',
		sortable: true,
		hidden: false
	}
];

const UserList = ({ ...props }) => {
	const {
		isLoading,
		isEmpty,
		users,
		findUsers,
		deleteUser,
		paginate,
		query,
		page,
		numPages,
		reloadUsers,
		sortKey,
		sortDirection,
		handleSort
	} = useUsers({});

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/users'
	});

	const {
		selected,
		selectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected
	} = useSelected();

	const { showAlertSuccess } = useAlerts();

	const handleDeleteClick = async (user) => {
		await deleteUser(user);
		showAlertSuccess('User has been deleted');
		reloadUsers();
	};

	useEffect(() => {
		findUsers(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [query, page, sortKey, sortDirection]);

	const isSelectable = false;

	return (
		<>
			<Subheader
				title="Users"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						User
					</Button>
				]}
			/>
			<Container maxWidth="lg">
				<>
					<Paper>
						<Search
							isLoading={isLoading}
							query={query}
							handleSearch={findUsers}
						/>
						{!isEmpty ? (
							<TableContainer component={Paper} elevation={0}>
								<Table>
									<TableHead>
										<TableRow>
											{isSelectable && (
												<TableCell>
													<Checkbox
														checked={selectedIds?.length === users?.length}
														onChange={() => handleSelectAll(users)}
														color="primary"
													/>
												</TableCell>
											)}
											{TABLE_HEADERS.filter((h) => !h.hidden).map(
												(header, idx) => (
													<SortableTableHeader
														key={idx}
														handleSort={handleSort}
														value={`users.${header.value}`}
														sortable={header.sortable}
														sortKey={sortKey}
														sortDirection={sortDirection}
													>
														{header.label}
													</SortableTableHeader>
												)
											)}
											<TableCell></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{users.map((user, idx) => (
											<UserItem
												key={idx}
												user={user}
												isSelectable={isSelectable}
												selectedIds={selectedIds}
												handleSelect={handleSelect}
												handleClick={handleClick}
												handleDeleteClick={handleDeleteClick}
												handleEditClick={handleEditClick}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<Placeholder
								icon={<SearchIcon />}
								title="No users"
								subtitle="Please try another search"
							/>
						)}
					</Paper>
					{!isEmpty && (
						<Box my={1}>
							<Pagination
								onChange={(ev, page) => paginate(page)}
								color="primary"
								size="small"
								shape="rounded"
								page={page}
								count={numPages}
							/>
						</Box>
					)}
				</>
				<UserToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	);
};

export default UserList;
