import React from 'react';
import { AsyncAutosuggest } from 'components';

const TopicAutosuggest = ({ value, label, handleChange }) => (
	<AsyncAutosuggest
		name="topic_id"
		label={label}
		value={value}
		displayField="title"
		remoteUrl="/api/v1/admin/topics"
		placeholder="Select"
		handleChange={handleChange}
	/>
);

export default TopicAutosuggest;
