import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { LineItem, Image } from 'components';

const BenefitDetails = ({ benefit }) => (
	<List>
		<ListItem>
			<img src={benefit.thumbnail_url} width={240} />
		</ListItem>
		<ListItem>
			<img src={benefit.image_url} width={240} />
		</ListItem>
		<ListItem>
			<img src={benefit.web_image_url} width={500} />
		</ListItem>
		<LineItem label="Title" value={benefit.title} />
		<LineItem label="Expires at" value={benefit.expires_at} />
		<LineItem label="URL" value={benefit.url} />
		<LineItem label="Description" value={benefit.description} />
	</List>
);

export default BenefitDetails;
