import React from 'react';
import { Grid } from '@material-ui/core';
import { TextInput } from 'components';

const CategoryForm = ({ category, isEditing, handleChange }) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={category.name}
				label="Name"
				name="name"
				placeholder="Name"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={category.value}
				label="Value"
				name="value"
				placeholder="Value"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				rows={3}
				multiline
				value={category.description}
				label="Description"
				name="description"
				placeholder="Description"
				handleChange={handleChange}
			/>
		</Grid>
	</Grid>
);

export default CategoryForm;
