import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	messageBox: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(2),
		borderRadius: theme.shape.borderRadius,
		marginBottom: theme.spacing(2)
	},
	icon: {
		marginRight: theme.spacing(1)
	},
	success: {
		backgroundColor: '#dcedc8',
		color: '#4caf50'
	},
	warning: {
		backgroundColor: '#fff9c4',
		color: '#ff9800'
	},
	danger: {
		backgroundColor: '#ffcdd2',
		color: '#f44336'
	}
}));

const StatusMessage = ({ status, message }) => {
	const classes = useStyles();
	let icon;

	switch (status) {
		case 'success':
			icon = <CheckCircleIcon className={classes.icon} />;
			break;
		case 'warning':
			icon = <WarningIcon className={classes.icon} />;
			break;
		case 'danger':
			icon = <ErrorIcon className={classes.icon} />;
			break;
		default:
			icon = null;
	}

	return (
		<Box className={`${classes.messageBox} ${classes[status]}`}>
			{icon}
			<Typography variant="body1">{message}</Typography>
		</Box>
	);
};

export default StatusMessage;
