import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import { Autosuggest, TextInput, VideoUploader } from 'components';
import { BOOLEAN, LEVELS } from 'lib/constants';

const TrainingPlanLessonForm = ({
	lesson,
	handleChange,
	memoryFile,
	handleDelete,
	handleUpload,
	withPosition = false
}) => {
	const classes = useStyles();
	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={lesson.title}
					label="Lesson Title"
					name="title"
					placeholder="Title"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<Autosuggest
					options={LEVELS}
					value={lesson.level}
					label="Level"
					name="level"
					placeholder="Select one"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>

			<Grid item xs={12} sm={4}>
				<TextInput
					value={lesson.experience_points}
					label="XP"
					name="experience_points"
					placeholder="XP"
					handleChange={handleChange}
					type={'number'}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				{withPosition && (
					<TextInput
						value={lesson.position}
						label="Position"
						name="position"
						placeholder="Position"
						handleChange={handleChange}
						className={classes}
						type="number"
					/>
				)}
			</Grid>
			<Grid item xs={12} md={4}>
				<TextInput
					value={lesson.video_modifier_main}
					label="Video Modifier Main"
					name="video_modifier_main"
					placeholder="Cloudinary Modification"
					handleChange={handleChange}
					disabled={false}
					className={classes}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<TextInput
					value={lesson.video_modifier_clip}
					label="Video Modifier Clip"
					name="video_modifier_clip"
					placeholder="Cloudinary Modification"
					handleChange={handleChange}
					disabled={false}
					className={classes}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<TextInput
					value={lesson.video_modifier_thumbnail}
					label="Video Modifier Thumbnail"
					name="video_modifier_thumbnail"
					placeholder="Cloudinary Modification"
					handleChange={handleChange}
					disabled={false}
					className={classes}
				/>
			</Grid>
			<Grid container spacing={1}>
				<Grid item md={6} xs={12}>
					<Typography variant="body2" color="textSecondary">
						{'Lesson Preview Video'}
					</Typography>
					<VideoUploader
						label="Upload Preview Video"
						videoUrl={
							lesson.video2_url ||
							(memoryFile && memoryFile?.video2_url?.file?.preview) ||
							memoryFile?.video2_url?.url
						}
						handleUpload={(file) => handleUpload(file, 'video2_url')}
						handleDelete={() => {
							handleDelete('video2_url');
							handleChange({ target: { name: 'video2_url', value: '' } });
						}}
					/>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography variant="body2" color="textSecondary">
						{'Lesson Video'}
					</Typography>
					<VideoUploader
						label="Upload Video"
						videoUrl={
							lesson.video_url ||
							(memoryFile && memoryFile?.video_url?.file?.preview) ||
							memoryFile?.video_url?.url
						}
						handleUpload={(file) => handleUpload(file, 'video_url')}
						handleDelete={() => {
							handleDelete('video_url');
							handleChange({ target: { name: 'video_url', value: '' } });
						}}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} md={12}>
				<TextInput
					value={lesson.video2_url}
					label="Lesson Preview Video URL"
					name="video2_url"
					placeholder="Cloudinary URL"
					handleChange={handleChange}
					onPaste={handleChange}
					className={classes}
				/>
				<TextInput
					value={lesson.video_url}
					label="Lesson Video URL"
					name="video_url"
					placeholder="Cloudinary URL"
					handleChange={handleChange}
					onPaste={handleChange}
					className={classes}
				/>
			</Grid>
		</Grid>
	);
};

export default TrainingPlanLessonForm;

const useStyles = makeStyles((theme) => ({
	inputBase: {
		marginBottom: 20,
		'& input, & textarea': {
			borderColor: theme.palette.placeHolder.text
		}
	}
}));
