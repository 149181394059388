import { useResource } from 'hooks';

const usePromoRules = ({ id }) => {
	const {
		resources: promoRules,
		findMany: findPromoRules,
		id: promoRuleId,
		resource: promoRule,
		reloadMany: reloadPromoRules,
		isEmpty,
		isLoaded,
		isLoading,
		page,
		paginate,
		numPages,
		query,
		handleChange,
		sortDirection,
		sortKey,
		findOne: findPromoRule,
		handleSort,
		update: updatePromoRule,
		create: createPromoRule,
		destroy: deletePromoRule
	} = useResource({
		url: '/api/v1/admin/promo_rules',
		name: 'promo_rule',
		id: id
	});

	return {
		promoRules,
		findPromoRules,
		promoRule,
		promoRuleId,
		reloadPromoRules,
		isEmpty,
		isLoaded,
		isLoading,
		page,
		query,
		paginate,
		numPages,
		handleChange,
		sortDirection,
		sortKey,
		findPromoRule,
		handleSort,
		updatePromoRule,
		createPromoRule,
		deletePromoRule
	};
};

export default usePromoRules;
