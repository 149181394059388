import React from 'react';

import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';

import { SubheaderV2 } from 'components';
import TrainingPlanLessonForm from 'features/trainingPlan/TrainingPlanLessonForm';
import {
	useAlerts,
	useLessons,
	useQueryParams,
	useUploadFileInMemory
} from 'hooks';
import { validateLesson } from 'validations/lessons';
import { LESSONABLE_TYPE } from 'lib/constants/trainingPlan';

const TrainingPlanLessonNew = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const query = useQueryParams();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();
	const { showAlertSuccess, showAlertError } = useAlerts();
	const { lesson, handleChange, createLesson } = useLessons({
		id: undefined
	});

	const lessonableType = query.get('lessonable_type');

	const handleSaveClick = async () => {
		const valid = validateLesson(lesson);

		if (!valid.isValid) {
			showAlertError(valid.messages[0]);
			return;
		}

		const trainingPlanLesson = {
			...lesson
		};

		await createLesson(trainingPlanLesson, memoryFile);
		if (lessonableType === LESSONABLE_TYPE.TRAINING_PLAN_DRILL) {
			navigate(`/training-plan-drills/${id}`);
		} else {
			navigate(`/training-plans/${id}`);
		}
	};

	const handleUpload = async (file, type) => {
		await handleUploadFile(file, type);
	};

	const handleDelete = async () => {
		await removeFile();
	};

	const messages = {
		trainingPlan: 'Add a new training plan lesson',
		trainingPlanDrill: 'Add a new training plan drill lesson'
	};

	return (
		<div>
			<SubheaderV2
				title={
					lessonableType === LESSONABLE_TYPE.TRAINING_PLAN
						? messages.trainingPlan
						: messages.trainingPlanDrill
				}
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save Lesson
					</Button>
				]}
			/>

			<Container>
				<Box p={4}>
					<TrainingPlanLessonForm
						lesson={lesson}
						memoryFile={memoryFile}
						handleUpload={handleUpload}
						handleDelete={handleDelete}
						handleChange={handleChange}
						withPosition={lessonableType === LESSONABLE_TYPE.TRAINING_PLAN}
					/>
				</Box>
			</Container>
		</div>
	);
};

export default TrainingPlanLessonNew;
