import React from 'react';

import { useResource } from 'hooks';
import api from 'api';

const useTrainingPlans = ({ id }) => {
	const {
		id: trainingPlanId,
		isLoading,
		resource: trainingPlan,
		resources: trainingPlans,
		errors: trainingPlanErrors,
		findOne: findTrainingPlan,
		findMany: findTrainingPlans,
		create: createTrainingPlan,
		update: updateTrainingPlan,
		destroy: destroyTrainingPlan,
		isEmpty: isEmptyTrainingPlan,
		setResources: setTrainingPlans,
		handleErrors,
		...rest
	} = useResource({
		id: id,
		url: '/api/v1/admin/training_plans',
		name: 'training_plan',
		label: 'Training Plan'
	});

	const resortTrainingPlanLessons = async (trainingPlans) => {
		const sorted = trainingPlans.map((item) => ({
			id: item.training_plan_lesson_id,
			position: item.position
		}));

		const response = await api.post(
			`/api/v1/admin/training_plans/resort-lessons`,
			{
				training_plans_lessons: sorted
			}
		);
		return response.data;
	};

	const resortTrainingPlans = async (trainingPlans) => {
		const sorted = trainingPlans.map((item) => ({
			id: item.id,
			position: item.position
		}));
		const response = await api.post(`/api/v1/admin/training_plans/resort`, {
			trainingPlans: sorted
		});
		return response.data;
	};

	const createFeedback = async (id, feedback) => {
		try {
			const res = await api.post(
				`/api/v1/admin/training_plans/${id}/create_feedback`,
				{ feedback }
			);
			return res.data;
		} catch (e) {
			handleErrors(e);
			return e;
		}
	};

	const updateFeedback = async (id, feedback) => {
		try {
			const res = await api.put(
				`/api/v1/admin/training_plans/${id}/update_feedback`,
				{ feedback }
			);
			return res.data;
		} catch (e) {
			handleErrors(e);
			return e;
		}
	};

	const addLesson = async (id, lessonId) => {
		try {
			const res = await api.post(
				`/api/v1/admin/training_plans/${id}/add_lesson`,
				{ lesson_id: lessonId }
			);
			return res.data;
		} catch (e) {
			handleErrors(e);
			return e;
		}
	};

	const removeLesson = async (id, lessonId) => {
		try {
			const res = await api.post(
				`/api/v1/admin/training_plans/${id}/remove_lesson`,
				{ lesson_id: lessonId }
			);
			return res.data;
		} catch (e) {
			handleErrors(e);
			return e;
		}
	};

	return {
		trainingPlanId,
		trainingPlan,
		trainingPlans,
		trainingPlanErrors,
		isLoading,
		findTrainingPlan,
		findTrainingPlans,
		createTrainingPlan,
		updateTrainingPlan,
		destroyTrainingPlan,
		isEmptyTrainingPlan,
		resortTrainingPlanLessons,
		setTrainingPlans,
		createFeedback,
		updateFeedback,
		resortTrainingPlans,
		addLesson,
		removeLesson,
		...rest
	};
};

export default useTrainingPlans;
