import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';
import { useAlerts, useCategory, usePositions } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validatePosition } from 'validations/position';
import PositionForm from './PositionForm';

const PositionNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const {
		positionId,
		isLoading,
		isLoaded,
		position,
		handleChange,
		createPosition,
		findPosition
	} = usePositions({ id: id });

	const handleSaveClick = async () => {
		const valid = validatePosition(position);

		try {
			if (valid.isValid) {
				const response = await createPosition(position);
				// if (response.status === 422) {
				// 	const errorMessages = response && response.data;
				// 	for (const key in errorMessages) {
				// 		if (errorMessages.hasOwnProperty(key)) {
				// 			showAlertError(`${key}: ${errorMessages[key].join(', ')}`);
				// 		}
				// 	}
				// } else {
				showAlertSuccess('Position has been created');
				navigate('/positions');
				// }
			} else {
				valid.messages.map((m) => showAlertError(m));
			}
		} catch (error) {
			showAlertError('An error occurred while creating the position');
		}
	};

	useEffect(() => {
		if (id) findPosition(id);
	}, []);

	return (
		<>
			<Subheader
				title="Add Position"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<PositionForm position={position} handleChange={handleChange} />
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default PositionNew;
