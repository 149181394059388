import { isEmpty } from 'validations';

export const validateEventVideo = (event_video, memoryFile, useCloudinary) => {
	let isValid = true;
	let messages = [];

	if (isEmpty(event_video.title)) {
		isValid = false;
		messages.push('Event video title is required');
	}

	if (isEmpty(event_video.event_id)) {
		isValid = false;
		messages.push('Event video has to be associated with an event');
	}

	if (isEmpty(event_video.video_url) && isEmpty(memoryFile)) {
		isValid = false;
		messages.push('No file selected for upload');
	}

	if (isEmpty(event_video.video_url)) {
		isValid = false;
		messages.push('Cloudinary URL is required for large files');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};
