import React from 'react';
import { AsyncAutosuggest } from 'components';

const TrainerAutosuggest = ({ className, value, label, handleChange }) => (
	<AsyncAutosuggest
		className={className}
		name="trainer_id"
		label={label}
		value={value}
		displayField="name"
		remoteUrl="/api/v1/admin/trainers"
		placeholder="Select"
		handleChange={handleChange}
	/>
);

export default TrainerAutosuggest;
