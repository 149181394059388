import { AppBar, Button, Container, Tab, Tabs } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Subheader, TabPanel } from 'components';
import PromoRulesList from 'features/promoCode/PromoRules/PromoRulesList';
import { useNavigation } from 'hooks';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from 'theme/typography';
import PromoCodeList from './PromoCodesList';

const CREATE_BUTTON_CONFIG = [
	{
		url: 'promo_codes',
		title: 'Code'
	},
	{
		url: 'promo_rules',
		title: 'Rule'
	}
];

const PromoCode = () => {
	const [createConfig, setCreateConfig] = useState(CREATE_BUTTON_CONFIG[0]);
	const [tabValue, setTabValue] = useState(0);

	const navigate = useNavigate();
	const { handleAddClick } = useNavigation({
		history,
		url: `/${createConfig.url}`
	});

	const handleTabChange = (_, newValue) => {
		setTabValue(newValue);
		setCreateConfig(CREATE_BUTTON_CONFIG[newValue]);
	};

	const createButton = useMemo(
		() => (
			<Button
				variant="contained"
				startIcon={<Add />}
				onClick={handleAddClick}
				color="primary"
			>
				{createConfig.title}
			</Button>
		),
		[createConfig.url]
	);

	return (
		<>
			<Subheader title="Promo" buttons={[createButton]} />
			<Container maxWidth="lg">
				<AppBar
					position="static"
					style={{ background: '#FFF', color: '#000' }}
				></AppBar>

				<Tabs
					value={tabValue}
					onChange={handleTabChange}
					aria-label="LotteryDraw Tabs"
				>
					<Tab label="Promo Codes" />
					<Tab label="Promo Rules" />
				</Tabs>
				<TabPanel value={tabValue} index={0}>
					<PromoCodeList />
				</TabPanel>
				<TabPanel value={tabValue} index={1}>
					<PromoRulesList />
				</TabPanel>
			</Container>
		</>
	);
};

export default PromoCode;
