import { isEmpty } from 'validations';

export const validateEvent = (event) => {
	var isValid = true;
	let messages = [];

	if (isEmpty(event.name)) {
		isValid = false;
		messages.push('Event name is required');
	}

	if (isEmpty(event.description)) {
		isValid = false;
		messages.push('Event description is required');
	}

	if (isEmpty(event.start_date)) {
		isValid = false;
		messages.push('Start date is required');
	}

	if (isEmpty(event.end_date)) {
		isValid = false;
		messages.push('End date is required');
	}

	if (isEmpty(event.event_type)) {
		isValid = false;
		messages.push('Event type is required');
	}

	let validation = {
		isValid,
		messages
	};

	return validation;
};
