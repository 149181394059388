import React from 'react';
import { Grid } from '@material-ui/core';
import {
	Autosuggest,
	RatingInput,
	TextInput,
	SwitchInput,
	TrainerAutosuggest
} from 'components';
import { CATEGORIES } from 'lib/constants';

const TopicForm = ({ topic, isEditing, handleChange }) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={topic.title}
				label="Title"
				name="title"
				placeholder="Title"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				rows={3}
				multiline
				value={topic.description}
				label="Description"
				name="description"
				placeholder="Description"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={topic.order}
				label="Order"
				name="order"
				placeholder="order"
				handleChange={handleChange}
				type="number"
			/>
		</Grid>
	</Grid>
);

export default TopicForm;
