import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';
import RewardForm from 'features/rewards/RewardForm';
import { useAlerts, useRewards } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateReward } from 'validations/rewards';

const RewardEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const {
		reward,
		handleChange,
		updateReward,
		findReward,
		uploadFile,
		deleteFile
	} = useRewards({ id: id });

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const valid = validateReward(reward);
		if (valid.isValid) {
			await updateReward(reward);
			showAlertSuccess('Reward has been updated');
			navigate(`/rewards/${id}`);
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	useEffect(() => {
		if (id) findReward(id);
	}, []);

	return (
		<>
			<Subheader
				title="Edit Reward"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<RewardForm reward={reward} handleChange={handleChange} />
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default RewardEdit;
