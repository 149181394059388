import React, { useContext, useState } from 'react';
import { useAlerts, useAuth } from 'hooks';
import { AppContext } from 'context';
import {
	useNavigate,
	useParams,
	useLocation,
	Link as RouterLink
} from 'react-router-dom';
import {
	Box,
	Button,
	Container,
	Grid,
	Link,
	Typography
} from '@material-ui/core';
import { Subheader, TextInput, PinInput } from 'components';
import { ChevronRight } from '@material-ui/icons';
import queryString from 'query-string';

const ResetPassword = ({ ...props }) => {
	const location = useLocation();
	const params = queryString.parse(location.search);

	const [pin, setPin] = useState();

	const { showAlertError, showAlertSuccess } = useAlerts();

	const { authenticated, currentUser } = useContext(AppContext);

	const { verifyPin } = useAuth();

	const navigate = useNavigate();

	const handleSubmit = async () => {
		try {
			let resp = await verifyPin(pin, params.email);
			if (resp) {
				showAlertSuccess('Your pin is verified');
				window.location.href = '/reset_password';
			} else {
				showAlertError('pin is not valid');
			}
		} catch (e) {
			showAlertError("A user with that pin doesn't exist");
		}
	};

	const handleChange = ({ target }) => setPin(target.value);

	return (
		<div>
			<Subheader title="Enter pin" />
			<Container maxWidth="xs">
				<Box my={10}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<PinInput
								fullWidth
								required={true}
								value={pin}
								name="pin"
								label="Enter 6-digit PIN"
								handleChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button
								fullWidth
								size="large"
								variant="contained"
								color="primary"
								endIcon={<ChevronRight />}
								onClick={handleSubmit}
							>
								Verify and continue
							</Button>
						</Grid>
					</Grid>
					<Box my={4} />
					<Typography variant="body2">
						<Link component={RouterLink} to="/login">
							Sign in
						</Link>
					</Typography>
				</Box>
			</Container>
		</div>
	);
};

export default ResetPassword;
