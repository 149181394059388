import { Box, Container, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';

import { Subheader } from 'components';
import LotteryDrawDetails from 'features/lotteryDraws/LotteryDrawDetails';
import { useLotteryDraws } from 'hooks';

const LotteryDrawShow = ({ ...props }) => {
	const { id } = useParams();

	const { isLoading, lotteryDraw, findLotteryDraw } = useLotteryDraws({
		id: id
	});

	useEffect(() => {
		if (id) findLotteryDraw(id);
	}, []);

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : 'Lottery Draw: ' + lotteryDraw.id}
				buttons={[]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<LotteryDrawDetails lotteryDraw={lotteryDraw} />
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default LotteryDrawShow;
