import {
	Button,
	Checkbox,
	Menu,
	MenuItem,
	TableCell,
	TableRow
} from '@material-ui/core';
import React from 'react';

import { useMenu } from 'hooks';
import { properCase } from 'lib/utils';

const ReferralItem = ({
	referral,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleEditClick(referral, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(referral, e)}>
				Delete
			</MenuItem>
		</Menu>
	);

	const isSelected = selectedIds.includes(referral.id);

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(referral)}
						name="referral"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(referral)}>
					{referral?.referrer?.first_name} {referral?.referrer?.last_name}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(referral)}>
					{referral?.new_user?.first_name} {referral?.new_user?.last_name}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(referral)}>{referral.points}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(referral)}>
					{properCase(referral.status)}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(referral)}>
					{referral.created_at}
				</Button>
			</TableCell>
		</TableRow>
	);
};

export default ReferralItem;
