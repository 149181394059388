import React, { useMemo, useState } from 'react';

import {
	AppBar,
	Button,
	Container,
	makeStyles,
	Paper,
	Typography
} from '@material-ui/core';
import { Add, Info } from '@material-ui/icons';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { Subheader, TabPanel } from 'components';
import TrainingPlanDrillList from 'features/trainingPlan/trainingPlanDrill/TrainingPlanDrillList';
import TrainingPlanList from 'features/trainingPlan/trainingPlan/TrainingPlanList';
import { useNavigation } from 'hooks';
import { useNavigate } from 'react-router-dom';

const CREATE_BUTTON_CONFIG = [
	{
		url: 'training-plans',
		title: 'Training Plan'
	}
];

const TrainingPlans = () => {
	const [createConfig, setCreateConfig] = useState(CREATE_BUTTON_CONFIG[0]);
	const [tabValue, setTabValue] = useState(0);
	const navigate = useNavigate();

	const handleAddNew = () => {
		navigate('/training-plans/new');
	};

	const handleTabChange = (_, newValue) => {
		setTabValue(newValue);
		setCreateConfig(CREATE_BUTTON_CONFIG[newValue]);
	};

	const createButton = useMemo(() => {
		return (
			<Button
				variant="contained"
				startIcon={<Add />}
				onClick={handleAddNew}
				color="primary"
			>
				{createConfig.title}
			</Button>
		);
	}, [createConfig.url]);

	return (
		<>
			<Subheader title="Training Plans" buttons={[createButton]} />
			<Container maxWidth="lg">
				<>
					<TrainingPlanList
						title={'Training Plans'}
						handleAddClick={handleAddNew}
					/>
				</>
			</Container>
		</>
	);
};

export default TrainingPlans;
