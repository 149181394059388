import { Grid } from '@material-ui/core';
import { TextInput } from 'components';
import React from 'react';

const PromoRuleForm = ({ promo, handleChange }) => {
	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={promo.name || ''}
					label="Name"
					name="name"
					placeholder="Name"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={promo.description || ''}
					label="Description"
					name="description"
					placeholder="Description"
					handleChange={handleChange}
					multiline
					rows={4}
				/>
			</Grid>

			<Grid item xs={12} sm={12}>
				<TextInput
					value={promo.user_description || ''}
					label="User Description"
					name="user_description"
					placeholder="User Description"
					handleChange={handleChange}
					multiline
					rows={4}
				/>
			</Grid>

			<Grid item xs={12} sm={12}>
				<TextInput
					value={promo.duration || ''}
					type={'number'}
					label="Promo Duration"
					name="duration"
					placeholder="Promo Duration"
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	);
};

export default PromoRuleForm;
