import {
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Typography,
	makeStyles
} from '@material-ui/core';
import { DragIndicator, MoreHoriz } from '@material-ui/icons';
import fp from 'lodash/fp';
import React from 'react';

import { useMenu } from 'hooks';

const LessonItem = ({
	lesson,
	level,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const classes = useStyles();

	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleClick(lesson, e)}>Show</MenuItem>
			<MenuItem onClick={(e) => handleEditClick(lesson, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(lesson, e)}>Delete</MenuItem>
		</Menu>
	);

	return (
		<Grid container spacing={1} className={classes.root}>
			<Grid item md={1} xs={12} className={classes.item}>
				<DragIndicator className={classes.icon} />
			</Grid>
			<Grid item md={2} xs={12} className={classes.item}>
				<Typography variant="body1" className={classes.text}>
					{`Lesson ${lesson.position}`}
				</Typography>
			</Grid>
			<Grid item md={4} xs={12} className={classes.item}>
				<Typography variant="body1" className={classes.text}>
					{lesson.title}
				</Typography>
			</Grid>
			<Grid item md={1} xs={12} className={classes.item}>
				<Typography variant="body1" className={classes.text}>
					{fp.compose(
						fp.join(' '),
						fp.map((v) => fp.capitalize(v)),
						fp.split('_')
					)(level)}
				</Typography>
			</Grid>
			<Grid item md={1} xs={12} className={classes.item}>
				<Typography variant="body1" className={classes.text}>
					{lesson.experience_points}
				</Typography>
			</Grid>
			<Grid item md={2} xs={12} className={classes.item}>
				<Typography variant="body1" className={classes.text}>
					{lesson.premium ? 'Yes' : 'No'}
				</Typography>
			</Grid>
			<Grid className={classes.item} item md={1} xs={12}>
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</Grid>
		</Grid>
	);
};

export default LessonItem;

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 80,
		paddingLeft: 20,
		paddingRight: 20
	},
	item: { verticalAlign: 'center' },
	text: { paddingTop: 20 },
	icon: { color: theme.palette.common.grey, marginLeft: 20, marginTop: 20 },
	button: { paddingTop: 20, paddingBottom: 20 }
}));
