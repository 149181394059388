import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader, VideoUploader } from 'components';
import ArticleForm from 'features/articles/ArticleForm';
import { useAlerts, useArticles } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ArticleEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const {
		article,
		handleChange,
		updateArticle,
		findArticle,
		uploadFile,
		deleteFile
	} = useArticles({ id: id });

	const { showAlertSuccess } = useAlerts();

	const handleSaveClick = async () => {
		await updateArticle(article);
		showAlertSuccess('Article has been updated');
		navigate(`/articles/${article.id}`);
	};

	const handleUpload = async (file, type) => {
		await uploadFile(file, type);
		findArticle(id);
	};

	const handleDelete = async (type) => {
		await deleteFile(type);
		findArticle(id);
	};

	useEffect(() => {
		if (id) findArticle(id);
	}, []);

	useEffect(() => {
		console.log(article);
	}, [article]);

	return (
		<>
			<Subheader
				title="Edit Article"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<center>
							<ImageUploader
								label="Upload thumbnail (2:3)"
								imageUrl={article.image_url}
								handleUpload={(file) => handleUpload(file, 'image_url')}
								handleDelete={() => handleDelete('image_url')}
							/>
							<ImageUploader
								label="Upload image (2:3)"
								imageUrl={article.image2_url}
								handleUpload={(file) => handleUpload(file, 'image2_url')}
								handleDelete={() => handleDelete('image2_url')}
							/>
							<ImageUploader
								label="Upload web image (6:1)"
								imageUrl={article.web_image_url}
								handleUpload={(file) => handleUpload(file, 'web_image_url')}
								handleDelete={() => handleDelete('web_image_url')}
							/>
							<VideoUploader
								label="Upload video"
								videoUrl={article.video_url}
								handleUpload={(file) => handleUpload(file, 'video_url')}
								handleDelete={() => handleDelete('video_url')}
							/>
						</center>
						<ArticleForm article={article} handleChange={handleChange} />
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default ArticleEdit;
