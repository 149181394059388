import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const EventDisplayImage = ({ label, url, width = '100%', className }) => {
	const classes = useStyles();

	return (
		<div className={clsx(className?.root, classes.root)}>
			<div className={classes.imageContainer}>
				<img
					src={url}
					alt={label}
					className={classes.image}
					style={{ width }}
				/>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: 32
	},
	label: {},
	imageContainer: {
		height: 320,
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center'
	},
	image: {
		objectFit: 'cover',
		width: '100%',
		height: '100%'
	}
}));

export default EventDisplayImage;
