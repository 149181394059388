import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';
import { useAlerts, useCategory } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateCategory } from 'validations/category';
import CategoryForm from './CategoryForm';

const CategoryNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const {
		categoryId,
		isLoading,
		isLoaded,
		category,
		handleChange,
		createCategory,
		findCategory
	} = useCategory({ id: id });

	const handleSaveClick = async () => {
		const valid = validateCategory(category);

		try {
			if (valid.isValid) {
				const response = await createCategory(category);
				if (response.status === 422) {
					const errorMessages = response && response.data;
					for (const key in errorMessages) {
						if (errorMessages.hasOwnProperty(key)) {
							showAlertError(`${key}: ${errorMessages[key].join(', ')}`);
						}
					}
				} else {
					showAlertSuccess('Category has been created');
					navigate('/categories');
				}
			} else {
				valid.messages.map((m) => showAlertError(m));
			}
		} catch (error) {
			showAlertError('An error occurred while creating the category');
		}
	};

	useEffect(() => {
		if (id) findCategory(id);
	}, []);

	return (
		<>
			<Subheader
				title="Add Category"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<CategoryForm category={category} handleChange={handleChange} />
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default CategoryNew;
