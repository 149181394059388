import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader, VideoUploader } from 'components';
import EditorialForm from 'features/editorials/EditorialForm';
import { useAlerts, useEditorials, useUploadFileInMemory } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateEditorial } from 'validations/editorials';

const EditorialNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const { editorial, handleChange, createEditorial, findEditorial } =
		useEditorials({
			id: id
		});

	const handleSaveClick = async () => {
		const valid = validateEditorial(editorial);
		if (valid.isValid) {
			await createEditorial(editorial, memoryFile);
			showAlertSuccess('Editorial has been created');
			navigate('/editorials');
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleDelete = async (type) => {
		removeFile();
	};

	useEffect(() => {
		if (id) findEditorial(id);
	}, []);

	return (
		<>
			<Subheader
				title="Add Editorials"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<center>
						<ImageUploader
							label="Upload thumbnail (3:2)"
							imageUrl={
								editorial.thumbnail_url || memoryFile?.thumbnail_url?.url
							}
							handleUpload={(file) => handleUploadFile(file, 'thumbnail_url')}
							handleDelete={() => handleDelete('thumbnail_url')}
						/>
						<ImageUploader
							label="Upload image (3:2)"
							imageUrl={editorial.image_url || memoryFile?.image_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'image_url')}
							handleDelete={() => handleDelete('image_url')}
						/>
						<ImageUploader
							label="Upload web image (6:1)"
							imageUrl={
								editorial.web_image_url || memoryFile?.web_image_url?.url
							}
							handleUpload={(file) => handleUploadFile(file, 'web_image_url')}
							handleDelete={() => handleDelete('web_image_url')}
						/>
						<VideoUploader
							label="Upload video"
							videoUrl={editorial.video_url || memoryFile?.video_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'video_url')}
							handleDelete={() => handleDelete('video_url')}
						/>
					</center>
					<EditorialForm editorial={editorial} handleChange={handleChange} />
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default EditorialNew;
