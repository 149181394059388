import React from 'react';
import { useMenu } from 'hooks';
import {
	Button,
	IconButton,
	TableRow,
	TableCell,
	Menu,
	MenuItem,
	Checkbox
} from '@material-ui/core';
import { Image } from 'components';
import { MoreHoriz } from '@material-ui/icons';

const TopicItem = ({
	topic,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleEditClick(topic, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(topic, e)}>Delete</MenuItem>
		</Menu>
	);

	const isSelected = selectedIds.includes(topic.id);

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(topic)}
						name="topic"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(topic)}>
					<Image src={topic.thumbnail_url} variant="rounded" size={80} />
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(topic)}>{topic.title}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(topic)}>{topic.order}</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default TopicItem;
