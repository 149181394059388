import React from 'react';

import { Grid, makeStyles, Typography } from '@material-ui/core';

import {
	MultiAutosuggest,
	TextInput,
	VideoUploader,
	Autosuggest,
	TrainerAutosuggest
} from 'components';
import { POSITIONS, BOOLEAN, LEVELS } from 'lib/constants';

const LessonForm = ({
	lesson,
	categories,
	handleChange,
	memoryFile,
	handleDelete,
	handleUpload
}) => {
	const classes = useStyles();

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<TextInput
					value={lesson.title}
					label="Lesson Name"
					name="title"
					placeholder="Enter lesson name"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextInput
					value={lesson.description}
					label="Description"
					name="description"
					placeholder="Enter description"
					handleChange={handleChange}
					multiline
					className={classes}
					rows={4}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<Autosuggest
					options={LEVELS}
					value={lesson.level || []}
					label="Level"
					name="level"
					placeholder="Select one"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<Autosuggest
					options={categories.map((category) => ({
						label: category.name,
						value: category.id
					}))}
					value={lesson.category_id || []}
					label="Skill Category"
					name="category_id"
					placeholder="Select a category"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<MultiAutosuggest
					options={POSITIONS}
					value={lesson.positions || []}
					label="Position"
					name="positions"
					placeholder="Select one/many"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<Autosuggest
					options={BOOLEAN}
					value={lesson.published ? true : false}
					label="Published Course"
					name="published"
					placeholder="Select one"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<TrainerAutosuggest
					value={lesson.trainer_id}
					label="Trainer"
					name="trainer_id"
					placeholder="Select trainer"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12} />
			<Grid item xs={12} md={4}>
				<TextInput
					value={lesson.video_modifier_main}
					label="Main Video Modifier"
					name="video_modifier_main"
					placeholder="Enter Cloudinary main modifier"
					handleChange={handleChange}
					disabled={false}
					className={classes}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<TextInput
					value={lesson.video_modifier_clip}
					label="Clip Video Modifier"
					name="video_modifier_clip"
					placeholder="Enter Cloudinary clip modifier"
					handleChange={handleChange}
					disabled={false}
					className={classes}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<TextInput
					value={lesson.video_modifier_thumbnail}
					label="Thumbnail Video Modifier"
					name="video_modifier_thumbnail"
					placeholder="Enter Cloudinary thumbnail modifier"
					handleChange={handleChange}
					disabled={false}
					className={classes}
				/>
			</Grid>

			<Grid container spacing={1}>
				<Grid item md={6} xs={12}>
					<Typography variant="body2" color="textSecondary">
						{'Lesson Preview Video'}
					</Typography>
					<VideoUploader
						label="Upload Preview Video"
						videoUrl={
							lesson.video2_url ||
							(memoryFile && memoryFile?.video2_url?.file?.preview)
						}
						handleUpload={(file) => handleUpload(file, 'video2_url')}
						handleDelete={() => {
							handleDelete('video2_url');
							handleChange({ target: { name: 'video2_url', value: '' } });
						}}
					/>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography variant="body2" color="textSecondary">
						{'Lesson Video'}
					</Typography>
					<VideoUploader
						label="Upload Video"
						videoUrl={
							lesson.video_url ||
							(memoryFile && memoryFile?.video_url?.file?.preview)
						}
						handleUpload={(file) => handleUpload(file, 'video_url')}
						handleDelete={() => {
							handleDelete('video_url');
							handleChange({ target: { name: 'video_url', value: '' } });
						}}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<TextInput
					value={lesson.video2_url}
					label="Preview Video URL"
					name="video2_url"
					placeholder="Enter Cloudinary URL"
					handleChange={handleChange}
					className={classes}
				/>
				<TextInput
					value={lesson.video_url}
					label="Lesson Video URL"
					name="video_url"
					placeholder="Enter Cloudinary URL"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
		</Grid>
	);
};

export default LessonForm;

const useStyles = makeStyles((theme) => ({
	inputBase: {
		marginBottom: 20,
		'& input, & textarea': {
			borderColor: theme.palette.placeHolder.text
		}
	}
}));
