import { AsyncAutosuggest } from 'components';
import React from 'react';

const RewardAutosuggest = ({
	value,
	name = 'reward_id',
	label,
	position,
	handleChange,
	...props
}) => (
	<AsyncAutosuggest
		name={name}
		label={label}
		value={value}
		position={position}
		displayField="title"
		remoteUrl="/api/v1/admin/rewards"
		placeholder="Select"
		handleChange={handleChange}
	/>
);

export default RewardAutosuggest;
