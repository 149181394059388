import React, { useState } from 'react';

const useMenu = (props = {}) => {
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleToggleMenu = (e) => {
		setAnchorEl(e.currentTarget);
		setOpen(!open);
	};

	const closeMenu = () => setOpen(false);

	return {
		open,
		anchorEl,
		handleToggleMenu,
		closeMenu
	};
};

export default useMenu;
