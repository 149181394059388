import {
	Box,
	Button,
	Container,
	List,
	Paper,
	Typography
} from '@material-ui/core';
import api from 'api';
import { Subheader } from 'components';
import { useAlerts, useCategory, usePositions } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PositionDetails from './PositionDetails';

const PositionShow = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const editorialContainerRef = useRef(null);

	const { positionId, isLoading, isLoaded, position, findPosition } =
		usePositions({
			id: id
		});

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleEditClick = () => navigate(`/positions/${id}/edit`);

	useEffect(() => {
		if (id) findPosition(id);
	}, []);

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : position.name}
				buttons={[
					<Button onClick={handleEditClick} color="primary" variant="contained">
						Edit
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>{<PositionDetails position={position} />}</Box>
				</Paper>
			</Container>
		</>
	);
};

export default PositionShow;
