import React from 'react';

import { useResource } from 'hooks';

const useSkillCategories = () => {
	const {
		isLoading,
		resources: categories,
		resource: category,
		errors,
		findOne: findCategory,
		findMany: findCategories
	} = useResource({
		url: '/api/v1/admin/categories',
		name: 'category'
	});

	return {
		isLoading,
		categories,
		errors,
		findCategory,
		findCategories,
		category
	};
};

export default useSkillCategories;
