import React from 'react';
import { useMenu } from 'hooks';
import {
	Button,
	IconButton,
	TableRow,
	TableCell,
	Menu,
	MenuItem,
	Checkbox
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';

const LessonItem = ({
	lesson,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleEditClick(lesson, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(lesson, e)}>Delete</MenuItem>
		</Menu>
	);

	const isSelected = selectedIds.includes(lesson.id);

	return (
		<TableRow>
			{/* Selectable Checkbox */}
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected}
						onChange={() => handleSelect(lesson)}
						name="lesson"
						color="primary"
					/>
				</TableCell>
			)}

			<TableCell>
				<Button onClick={() => handleClick(lesson)}>{lesson.title}</Button>
			</TableCell>

			<TableCell>
				<Button onClick={() => handleClick(lesson)}>{lesson.level}</Button>
			</TableCell>

			<TableCell>
				<Button onClick={() => handleClick(lesson)}>
					{lesson.trainer?.name}
				</Button>
			</TableCell>

			<TableCell>
				<Button onClick={() => handleClick(lesson)}>
					{lesson.category_name}
				</Button>
			</TableCell>

			<TableCell>
				<Button onClick={() => handleClick(lesson)}>
					{lesson.published ? 'Yes' : 'No'}
				</Button>
			</TableCell>

			<TableCell align="right">
				<IconButton onClick={handleToggleMenu}>
					<MoreHoriz />
				</IconButton>
				{renderMenu()}
			</TableCell>
		</TableRow>
	);
};

export default LessonItem;
