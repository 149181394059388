import React from 'react';
import { useResource } from 'hooks';

const useTriviaAnswers = () => {
	const {
		id: triviaAnswerId,
		isLoading,
		resource: triviaAnswer,
		resources: triviaAnswers,
		errors: triviaAnswerErrors,
		findOne: findTriviaAnswer,
		findMany: findTriviaAnswers,
		create: createTriviaAnswer,
		update: updateTriviaAnswer,
		destroy: destroyTriviaAnswer,
		isEmpty: isEmptyTriviaAnswer,
		...rest
	} = useResource({
		url: '/api/v1/admin/trivia_answers',
		name: 'trivia_answer',
		label: 'Trivia Answer'
	});

	return {
		triviaAnswerId,
		triviaAnswer,
		triviaAnswers,
		triviaAnswerErrors,
		isLoading,
		findTriviaAnswer,
		findTriviaAnswers,
		createTriviaAnswer,
		updateTriviaAnswer,
		destroyTriviaAnswer,
		isEmptyTriviaAnswer,
		...rest
	};
};

export default useTriviaAnswers;
