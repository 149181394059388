import React, { useState } from 'react';
import { useAlerts, useLoaders, useResource } from 'hooks';
import api from 'api';

const useFeedbackAttempts = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders();
	const { showAlertError, showAlertSuccess } = useAlerts();
	const [filters, setFilters] = React.useState({
		status: undefined
	});
	const [previousFeedbackAttempts, setPreviousFeedbackAttempts] = useState([]);

	const resetFilters = () => {
		setFilters({
			status: undefined
		});
	};

	const {
		resourceId: feedbackAttemptId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: feedbackAttempt,
		resources: feedbackAttempts,
		findOne: findFeedbackAttempt,
		findMany: findFeedbackAttempts,
		update: updateFeedbackAttempt,
		create: createFeedbackAttempt,
		save: saveFeedbackAttempt,
		destroy: deleteFeedbackAttempt,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reloadOne: reloadFeedbackAttempt,
		reloadMany: reloadFeedbackAttempts,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		setResource: setFeedbackAttempt
	} = useResource({
		id: id,
		url: '/api/v1/admin/feedback_attempts',
		name: 'feedback_attempt'
	});

	const findPreviousFeedbackAttempts = async (feedback_attempt_id) => {
		let resp = await api.get(
			`/api/v1/admin/feedback_attempts/previous_feedback_attempts`,
			{
				params: { feedback_attempt_id: feedback_attempt_id }
			}
		);
		setPreviousFeedbackAttempts(resp.data);
	};

	return {
		feedbackAttemptId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		feedbackAttempt,
		feedbackAttempts,
		previousFeedbackAttempts,
		findPreviousFeedbackAttempts,
		findFeedbackAttempt,
		findFeedbackAttempts,
		saveFeedbackAttempt,
		updateFeedbackAttempt,
		createFeedbackAttempt,
		deleteFeedbackAttempt,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadFeedbackAttempt,
		reloadFeedbackAttempts,
		filters,
		setFilters,
		resetFilters,
		setFeedbackAttempt
	};
};

export default useFeedbackAttempts;
