import {
	Box,
	Button,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Placeholder, SortableTableHeader, Subheader } from 'components';
import CourseItem from 'features/courses/CourseItem';
import CourseToolbar from 'features/courses/CourseToolbar';
import SearchForm from 'features/courses/SearchForm';
import { useAlerts, useCourses, useNavigation, useSelected } from 'hooks';

const TABLE_HEADERS = [
	{
		value: 'image_url',
		label: 'Image',
		sortable: true,
		hidden: false
	},

	{
		value: 'title',
		label: 'Title',
		sortable: true,
		hidden: false
	},
	{
		value: 'subtitle',
		label: 'Subtitle',
		sortable: true,
		hidden: true
	},

	{
		value: 'description',
		label: 'Description',
		sortable: true,
		hidden: true
	},

	{
		value: 'categories',
		label: 'Categories',
		sortable: false,
		hidden: false
	},

	{
		value: 'positions',
		label: 'Positions',
		sortable: false,
		hidden: false
	},

	{
		value: 'trainer_id',
		label: 'Trainer',
		sortable: true,
		hidden: false
	},

	{
		value: 'featured',
		label: 'Featured',
		sortable: true,
		hidden: false
	},
	{
		value: 'published',
		label: 'Published',
		sortable: true,
		hidden: false
	},

	{
		value: 'recommended',
		label: 'Recommended',
		sortable: true,
		hidden: false
	},

	{
		value: 'premium',
		label: 'Premium',
		sortable: true,
		hidden: false
	}
];

const CourseList = ({ ...props }) => {
	const navigate = useNavigate();
	const {
		isLoading,
		isEmpty,
		courses,
		findCourses,
		deleteCourse,
		paginate,
		query,
		page,
		numPages,
		reloadCourses,
		sortKey,
		sortDirection,
		handleSort
	} = useCourses({});

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/courses'
	});

	const { selected, selectedIds, handleSelect, handleClear, totalSelected } =
		useSelected();

	const { showAlertSuccess } = useAlerts();

	const handleDeleteClick = async (course) => {
		await deleteCourse(course);
		showAlertSuccess('Course has been deleted');
		reloadCourses();
	};

	useEffect(() => {
		findCourses(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [query, page, sortKey, sortDirection]);

	return (
		<>
			<Subheader
				title="Courses"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						Course
					</Button>
				]}
			/>
			<Container maxWidth="lg">
				<>
					<Paper>
						<SearchForm
							isLoading={isLoading}
							query={query}
							handleSearch={findCourses}
						/>
						{!isEmpty ? (
							<TableContainer component={Paper} elevation={0}>
								<Table>
									<TableHead>
										<TableRow>
											{TABLE_HEADERS.filter((h) => !h.hidden).map(
												(header, idx) => (
													<SortableTableHeader
														key={idx}
														handleSort={handleSort}
														value={`courses.${header.value}`}
														sortable={header.sortable}
														sortKey={sortKey}
														sortDirection={sortDirection}
													>
														{header.label}
													</SortableTableHeader>
												)
											)}
											<TableCell></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{courses.map((course, idx) => (
											<CourseItem
												key={idx}
												course={course}
												isSelectable={false}
												selectedIds={selectedIds}
												handleSelect={handleSelect}
												handleClick={handleClick}
												handleDeleteClick={handleDeleteClick}
												handleEditClick={handleEditClick}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<Placeholder
								icon={<Search />}
								title="No courses"
								subtitle="Please try another search"
							/>
						)}
					</Paper>
					{!isEmpty && (
						<Box my={1}>
							<Pagination
								onChange={(ev, page) => paginate(page)}
								color="primary"
								size="small"
								shape="rounded"
								page={page}
								count={numPages}
							/>
						</Box>
					)}
				</>
				<CourseToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	);
};

export default CourseList;
