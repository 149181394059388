import React, { useEffect } from 'react';

import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';

import { ImageUploader, SubheaderV2 } from 'components';
import TrainingPlanDrillForm from 'features/trainingPlan/trainingPlanDrill/TrainingPlamDrillForm';
import {
	useAlerts,
	useSkillCategories,
	useTrainingPlanDrills,
	useUploadFileInMemory
} from 'hooks';
import { trainingPlanValidation } from 'validations/trainingPlan';
import { trainingPlanDrillValidation } from 'validations/trainingPlanDrill';

const TrainingPlanDrillNew = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const {
		trainingPlanDrill,
		handleChange,
		findTrainingPlanDrill,
		createTrainingPlanDrill
	} = useTrainingPlanDrills({ id: id });
	const { categories, findCategories } = useSkillCategories();
	const { showAlertError } = useAlerts();
	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();

	const handleSaveClick = async () => {
		const valid = trainingPlanDrillValidation(trainingPlanDrill);

		if (!valid.isValid) {
			showAlertError(valid.messages[0]);
			return;
		}

		const response = await createTrainingPlanDrill(
			trainingPlanDrill,
			memoryFile
		);

		if (response.status && response.status !== 200) {
			const { message } = response.data;
			showAlertError(message);
			return;
		}

		navigate('/training-plan-drills');
	};

	const handleUpload = async (file, type) => {
		await handleUploadFile(file, type);
	};

	const handleDelete = async () => {
		await removeFile();
	};

	useEffect(() => {
		findCategories();
	}, []);

	useEffect(() => {
		if (id) findTrainingPlanDrill(id);
	}, [id]);

	return (
		<div>
			<SubheaderV2
				title="New Benchmark Drill"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save Benchmark Drill
					</Button>
				]}
			/>

			<Container>
				<Box p={4}>
					<TrainingPlanDrillForm
						trainingPlan={trainingPlanDrill}
						handleChange={handleChange}
						categories={categories}
						memoryFile={memoryFile}
						handleUpload={handleUpload}
						handleDelete={handleDelete}
					/>
				</Box>
			</Container>
		</div>
	);
};

export default TrainingPlanDrillNew;
