import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';

import { useAlerts, usePositions } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PositionForm from './PositionForm';
import { validatePosition } from 'validations/position';

const PositionEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const {
		positionId,
		isLoading,
		isLoaded,
		position,
		handleChange,
		updatePosition,
		findPosition
	} = usePositions({ id: id });

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const valid = validatePosition(position);
		if (valid.isValid) {
			await updatePosition(position);
			showAlertSuccess('Position has been updated');
			navigate(`/positions/${id}`);
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	useEffect(() => {
		if (id) findPosition(id);
	}, []);

	return (
		<>
			<Subheader
				title="Edit Position"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<PositionForm position={position} handleChange={handleChange} />
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default PositionEdit;
