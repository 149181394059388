import { isEmpty } from 'validations';

export const validatePromoRule = (promo) => {
	const messages = [];
	let isValid = true;

	if (isEmpty(promo.name)) {
		isValid = false;
		messages.push('Promo Rule name is required');
	}

	if (isEmpty(promo.description)) {
		isValid = false;
		messages.push('Promo Rule description is required');
	}

	if (isEmpty(promo.duration)) {
		isValid = false;
		messages.push('Promo Rule duration is required');
	}

	return {
		messages,
		isValid
	};
};
