import { Box, Button, Container, Paper } from '@material-ui/core';
import { Subheader } from 'components';
import usePromoCodes from 'hooks/usePromoCodes';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PromoCodeDetails from './PromoCodeDetails';

const PromoCodeShow = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { promoCode, findPromoCode, isLoading } = usePromoCodes({ id: id });

	const handleEditClick = () => navigate(`/promo_codes/${id}/edit`);

	useEffect(() => {
		if (id) findPromoCode(id);
	}, []);

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : promoCode.promo_code}
				buttons={[
					<Button onClick={handleEditClick} color="primary" variant="contained">
						Edit
					</Button>
				]}
			/>

			<Container>
				<Paper>
					<Box p={4}>
						<PromoCodeDetails promo={promoCode} />
					</Box>
				</Paper>

				<Box p={4} />
			</Container>
		</>
	);
};

export default PromoCodeShow;
