import { isEmpty } from 'validations';

export const validateLottery = (lottery) => {
	let { isValid, messages } = validate(lottery);

	const validation = {
		isValid,
		messages
	};

	return validation;
};

export const validateLotteryWithWinner = (lottery) => {
	let { isValid, messages } = validate(lottery);

	if (isEmpty(lottery.winner) && isEmpty(lottery.winner_id)) {
		isValid = false;
		messages.push('Please select a winner before saving');
	}

	const validation = {
		isValid,
		messages
	};

	return validation;
};

const validate = (lottery) => {
	let isValid = true;
	const messages = [];

	if (isEmpty(lottery.year)) {
		isValid = false;
		messages.push('Year is required');
	}

	if (isEmpty(lottery.month)) {
		isValid = false;
		messages.push('Month is required');
	}

	if (isEmpty(lottery.points_required)) {
		isValid = false;
		messages.push('Points Required is required');
	}

	if (isEmpty(lottery.prize_description)) {
		isValid = false;
		messages.push('Prize Description is required');
	}

	if (isEmpty(lottery.status)) {
		isValid = false;
		messages.push('Status is required');
	}

	return { isValid: isValid, messages: messages };
};
