import React, { useEffect } from 'react';
import { useAlerts, useNavigation, useSelected, useCategory } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { SortableTableHeader, Subheader, Placeholder } from 'components';
import Pagination from '@material-ui/lab/Pagination';
import {
	Checkbox,
	Container,
	Box,
	Paper,
	Button,
	Table,
	TableHead,
	TableContainer,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import SkillItem from './CategoryItem';
import TopicToolbar from 'features/topics/TopicToolbar';
import SearchForm from 'features/topics/SearchForm';

const TABLE_HEADERS = [
	{
		value: 'name',
		label: 'Name',
		sortable: true,
		hidden: false
	},
	{
		value: 'value',
		label: 'Value',
		sortable: true,
		hidden: false
	},
	{
		value: 'description',
		label: 'Description',
		sortable: true,
		hidden: false
	},
	{
		value: 'created_at',
		label: 'Created At',
		sortable: true,
		hidden: false
	}
];

const SkillList = ({ ...props }) => {
	const navigate = useNavigate();
	const {
		isLoading,
		isLoaded,
		isEmpty,
		categories,
		findCategories,
		deleteCategory,
		paginate,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadCategories,
		sortKey,
		sortDirection,
		handleSort
	} = useCategory({});

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/categories'
	});

	const {
		selected,
		selectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected
	} = useSelected();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleDeleteClick = async (topic) => {
		await deleteCategory(topic);
		showAlertSuccess('Category has been deleted');
		reloadCategories();
	};

	useEffect(() => {
		findCategories(
			{
				...query
			},
			page
		);
	}, [query, page, sortKey, sortDirection]);

	return (
		<>
			<Subheader
				title="Skills Category"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						Skills Category
					</Button>
				]}
			/>
			<Container maxWidth="lg">
				<>
					<Paper>
						<SearchForm
							isLoading={isLoading}
							query={query}
							handleSearch={findCategories}
						/>
						{!isEmpty ? (
							<TableContainer component={Paper} elevation={0}>
								<Table>
									<TableHead>
										<TableRow>
											{TABLE_HEADERS.filter((h) => !h.hidden).map(
												(header, idx) => (
													<SortableTableHeader
														key={idx}
														handleSort={handleSort}
														value={`categories.${header.value}`}
														sortable={header.sortable}
														sortKey={sortKey}
														sortDirection={sortDirection}
													>
														{header.label}
													</SortableTableHeader>
												)
											)}
											<TableCell></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{categories.map((category, idx) => (
											<SkillItem
												key={idx}
												category={category}
												isSelectable={false}
												selectedIds={selectedIds}
												handleSelect={handleSelect}
												handleClick={handleClick}
												handleDeleteClick={handleDeleteClick}
												handleEditClick={handleEditClick}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<Placeholder
								icon={<Search />}
								title="No topics"
								subtitle="Please try another search"
							/>
						)}
					</Paper>
					{!isEmpty && (
						<Box my={1}>
							<Pagination
								onChange={(ev, page) => paginate(page)}
								color="primary"
								size="small"
								shape="rounded"
								page={page}
								count={numPages}
							/>
						</Box>
					)}
				</>
				<TopicToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	);
};

export default SkillList;
