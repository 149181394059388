import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

import {
	Autosuggest,
	MultiAutosuggest,
	TextInput,
	TrainerAutosuggest
} from 'components';
import { CATEGORIES, LEVELS, POSITIONS, BOOLEAN } from 'lib/constants';

const CourseForm = ({ course, handleChange }) => {
	const classes = useStyles();

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={course.title}
					label="Title"
					name="title"
					placeholder="Title"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					rows={3}
					multiline
					value={course.description}
					label="Description"
					name="description"
					placeholder="Description"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<Autosuggest
					options={LEVELS}
					value={course.level || []}
					label="Level"
					name="level"
					placeholder="Select one"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<MultiAutosuggest
					options={POSITIONS}
					value={course.positions || []}
					label="Position"
					name="positions"
					placeholder="Select one/many"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<TrainerAutosuggest
					value={course.trainer_id}
					label="Trainer"
					name="trainer_id"
					placeholder="Select one"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<MultiAutosuggest
					options={CATEGORIES}
					value={course.categories || []}
					label="Skill Category"
					name="categories"
					placeholder="Select one"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<Autosuggest
					options={BOOLEAN}
					value={course.featured ? true : false}
					label="Featured Course"
					name="featured"
					placeholder="Select one"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<Autosuggest
					options={BOOLEAN}
					value={course.published ? true : false}
					label="Published Course"
					name="published"
					placeholder="Select one"
					handleChange={handleChange}
					className={classes}
				/>
			</Grid>
		</Grid>
	);
};

export default CourseForm;

const useStyles = makeStyles((theme) => ({
	inputBase: {
		'& input, & textarea': {
			borderColor: theme.palette.placeHolder.text
		}
	}
}));
