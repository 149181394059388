import React from 'react';

import { Toolbar } from 'components';

const ReferralToolbar = ({
	selectedIds,

	handleClear
}) => (
	<Toolbar open={selectedIds?.length > 0} handleClose={handleClear}></Toolbar>
);

export default ReferralToolbar;
