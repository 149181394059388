import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: 9999 // Adjust the z-index if needed
	},
	progress: {
		margin: theme.spacing(2)
	}
}));

const Wip = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				<CircularProgress className={classes.progress} color="primary" />
				<Typography variant="h6">Work in Progress...</Typography>
			</div>
		</div>
	);
};

export default Wip;
