import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Subheader } from 'components';
import LotteryDrawForm from 'features/lotteryDraws/LotteryDrawForm';
import { useAlerts, useLotteryDraws } from 'hooks';
import { validateLotteryDraw } from 'validations/lotteryDraws';

const LotteryDrawNew = ({ ...props }) => {
	const navigate = useNavigate();

	const { id } = useParams();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const {
		lotteryDrawId,
		lotteryDraw,
		handleChange,
		createLotteryDraw,
		setLotteryDraw,
		lotteryDrawErrors
	} = useLotteryDraws({
		id: id
	});

	const navigateToLotteryDrawShow = useCallback(() => {
		if (lotteryDrawId) {
			showAlertSuccess('Lottery Draw has been created');
			navigate(`/lottery_draws/${lotteryDrawId}`);
		}
	}, [lotteryDrawId]);

	const setInitialValues = useCallback(
		() => setLotteryDraw((prev) => ({ ...prev, status: 'active' })),
		[]
	);

	const displayErrors = useCallback(() => {
		if (lotteryDrawErrors?.data?.message) {
			showAlertError(lotteryDrawErrors.data.message);
		}
	}, [lotteryDrawErrors]);

	const handleSaveClick = async () => {
		const valid = validateLotteryDraw(lotteryDraw);
		if (!valid.isValid) {
			valid.messages.map((m) => showAlertError(m));
			return;
		}

		await createLotteryDraw(lotteryDraw);
	};

	useEffect(() => setInitialValues(), [setInitialValues]);
	useEffect(() => displayErrors(), [displayErrors]);
	useEffect(() => navigateToLotteryDrawShow(), [navigateToLotteryDrawShow]);

	return (
		<>
			<Subheader
				title="Add Lottery Draw"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<LotteryDrawForm
						lotteryDraw={lotteryDraw}
						handleChange={handleChange}
					/>
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default LotteryDrawNew;
