import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import { DisplayImage, DisplayText, LineItem } from 'components';

const PartnerDetail = ({ partner }) => (
	<>
		<div>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<DisplayText label={'Partner Login Email:'} text={partner.email} />
				</Grid>
				<Grid item xs={6}>
					<DisplayText
						label={'Partner Login Password:'}
						text={partner.visible_password}
					/>
				</Grid>
			</Grid>
		</div>
	</>
);

export default PartnerDetail;
