import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';
import UserForm from 'features/users/UserForm';
import { useAlerts, useUsers } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const UserEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { user, updateUser, findUser, resetUser, setUser } = useUsers({
		id: id
	});

	const { showAlertSuccess } = useAlerts();

	const handleSaveClick = async () => {
		await updateUser(user);
		showAlertSuccess('User has been updated');
		navigate(`/users/${id}`);
	};

	const handleResetUser = async () => {
		await resetUser(user?.id);
		showAlertSuccess('User has been reset');
	};

	const handleChange = (ev) => {
		const { name } = ev.target;
		const [parent, nested] = name.split('.');

		const value =
			ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value;

		setUser((prevState) =>
			nested
				? {
						...prevState,
						[parent]: {
							...prevState[parent],
							[nested]: value
						}
					}
				: {
						...prevState,
						[parent]: value
					}
		);
	};

	useEffect(() => {
		if (id) findUser(id);
	}, []);

	return (
		<>
			<Subheader
				title="Edit User"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<UserForm user={user} handleChange={handleChange} />
					<Box py={2}>
						<Button
							color="primary"
							variant="contained"
							startIcon={<Check />}
							onClick={handleSaveClick}
						>
							Save
						</Button>
						<Button ml={4} onClick={handleResetUser} color="secondary">
							Reset User
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default UserEdit;
