import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader } from 'components';
import TopicForm from 'features/topics/TopicForm';
import { useAlerts, useTopics, useUploadFileInMemory } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateTopic } from 'validations/topics';

const TopicNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const {
		topicId,
		isLoading,
		isLoaded,
		topic,
		handleChange,
		createTopic,
		findTopic,
		deleteFile,
		checkTopicOrder
	} = useTopics({ id: id });

	const handleSaveClick = async () => {
		const valid = validateTopic(topic);

		if (valid.isValid) {
			const status = await checkTopicOrder({ order: topic.order });
			if (status?.available) {
				await createTopic(topic, memoryFile);
				showAlertSuccess('Topic has been created');
				navigate('/topics');
			} else {
				showAlertError(status.message || 'Order number already taken');
			}
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleDelete = async (type) => {
		removeFile();
	};

	useEffect(() => {
		if (id) findTopic(id);
	}, []);

	return (
		<>
			<Subheader
				title="Add Topics"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<center>
						<ImageUploader
							label="Upload thumbnail (2:3)"
							imageUrl={topic.thumbnail_url || memoryFile?.thumbnail_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'thumbnail_url')}
							handleDelete={() => handleDelete('thumbnail_url')}
						/>
						<ImageUploader
							label="Upload image (3:2)"
							imageUrl={topic.image_url || memoryFile?.image_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'image_url')}
							handleDelete={() => handleDelete('image_url')}
						/>
						<ImageUploader
							label="Upload web image"
							imageUrl={topic.web_image_url || memoryFile?.web_image_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'web_image_url')}
							handleDelete={() => handleDelete('web_image_url')}
						/>
						<ImageUploader
							label="Upload web image 2"
							imageUrl={topic.web_image2_url || memoryFile?.web_image2_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'web_image2_url')}
							handleDelete={() => handleDelete('web_image2_url')}
						/>
					</center>
					<TopicForm topic={topic} handleChange={handleChange} />
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default TopicNew;
