import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Subheader } from 'components';
import RewardForm from 'features/rewards/RewardForm';
import { useAlerts, useRewards } from 'hooks';
import { validateReward } from 'validations/rewards';

const RewardNew = ({ ...props }) => {
	const navigate = useNavigate();

	const { id } = useParams();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const {
		rewardId,
		reward,
		handleChange,
		createReward,
		setReward,
		rewardErrors
	} = useRewards({
		id: id
	});

	const navigateToRewardShow = useCallback(() => {
		if (rewardId) {
			showAlertSuccess('Reward has been created');
			navigate(`/rewards/${rewardId}`);
		}
	}, [rewardId]);

	const setInitialValues = useCallback(
		() => setReward((prev) => ({ ...prev, status: 'active' })),
		[]
	);

	const displayErrors = useCallback(() => {
		if (rewardErrors?.data?.message) {
			showAlertError(rewardErrors.data.message);
		}
	}, [rewardErrors]);

	const handleSaveClick = async () => {
		const valid = validateReward(reward);
		if (!valid.isValid) {
			valid.messages.map((m) => showAlertError(m));
			return;
		}

		await createReward(reward);
	};

	useEffect(() => setInitialValues(), [setInitialValues]);
	useEffect(() => displayErrors(), [displayErrors]);
	useEffect(() => navigateToRewardShow(), [navigateToRewardShow]);

	return (
		<>
			<Subheader
				title="Add Reward"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<RewardForm reward={reward} handleChange={handleChange} />
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default RewardNew;
