import React, { useState } from 'react';

import { useResource } from 'hooks';

const useReferrals = ({ id, ...props }) => {
	const [sortKey, _] = useState(`referrals.id`);
	const [filters, setFilters] = React.useState({
		status: undefined
	});

	const resetFilters = () => {
		setFilters({
			status: undefined
		});
	};

	const {
		resourceId: referralId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: referral,
		resources: referrals,
		findOne: findReferral,
		findMany: findReferrals,
		update: updateReferral,
		create: createReferral,
		save: saveReferral,
		destroy: deleteReferral,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		refresh: reloadReferral,
		refreshMany: reloadReferrals,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortDirection,
		handleSort,
		setResource: setReferral
	} = useResource({
		id: id,
		url: '/api/v1/admin/referrals',
		name: 'referral'
	});

	return {
		referralId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		referral,
		referrals,
		findReferral,
		findReferrals,
		saveReferral,
		updateReferral,
		createReferral,
		deleteReferral,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadReferral,
		reloadReferrals,
		filters,
		setFilters,
		resetFilters,
		setReferral
	};
};

export default useReferrals;
