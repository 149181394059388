import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader } from 'components';
import EventForm from 'features/events/EventForm';
import {
	useAlerts,
	useEvents,
	useEventVideos,
	usePartners,
	useUploadFileInMemory
} from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateEvent } from 'validations/events';
import { validateEventVideo } from 'validations/event_video';

const EventEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const {
		eventId,
		isLoading,
		isLoaded,
		event,
		handleChange,
		updateEvent,
		findEvent,
		uploadFile,
		deleteFile,
		reloadEvent
	} = useEvents({ id: id });

	const {
		partners,
		findPartners,
		query,
		page,
		sortKey,
		sortDirection,
		handleSort
	} = usePartners({});

	const {
		event_video,
		findEventVideos,
		handleChange: handleChangeVideo,
		clearResource: clearResourceVideo,
		createEventVideo,
		deleteEventVideo,
		reloadEventsVideos
	} = useEventVideos({});

	const { showAlertSuccess, showAlertError } = useAlerts();
	const [showEventVideos, setShowEventVideos] = React.useState(false);

	const { memoryFile, removeFile, handleUploadFile, clearAllFiles } =
		useUploadFileInMemory();

	const handleSaveClick = async () => {
		let valid = validateEvent(event);

		if (valid.isValid) {
			await updateEvent(event)
				.then(() => {
					showAlertSuccess('Event has been updated');
					navigate(`/events/${id}`);
				})
				.catch((err) => {
					showAlertError(err.message);
				});
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleSaveVideo = async () => {
		const event_data = { event_id: event.id, title: event_video.title };
		let valid = validateEventVideo(event_data);

		if (valid.isValid) {
			await createEventVideo(event_data, memoryFile)
				.then(() => {
					showAlertSuccess('event video has been added');
					setShowEventVideos(false);

					reloadEvent();
					clearResourceVideo();
					clearAllFiles();
				})
				.catch((err) => {
					showAlertError('Failed to add event video');
					showAlertError(err.message);
				});
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const handleUpload = async (file, type) => {
		await uploadFile(file, type);
		findEvent(id);
	};

	const handleDelete = async (type) => {
		await deleteFile(type);
		findEvent(id);
	};

	useEffect(() => {
		if (id) findEvent(id);
	}, []);

	useEffect(() => {
		findPartners(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [query, page, sortKey, sortDirection]);

	const handleDeleteFile = async (type) => {
		removeFile();
	};

	const handleVideoDelete = async (event_id) => {
		try {
			if (id) {
				await deleteEventVideo({ id: event_id });
				showAlertSuccess('Event Video deleted successfully');
				reloadEvent();
				reloadEventsVideos();
			} else {
				showAlertError('Cannot delete event video');
			}
		} catch (error) {
			showAlertError('Error deleting event');
		}
	};

	return (
		<>
			<Subheader
				title={isLoaded ? `Edit ${event.name}` : `Loading...`}
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<EventForm
							event={event}
							partners={partners}
							event_video={event_video}
							memoryFile={memoryFile}
							status="edit"
							showEventVideos={showEventVideos}
							setShowEventVideos={setShowEventVideos}
							handleDelete={handleDelete}
							handleDeleteFile={handleDeleteFile}
							handleChange={handleChange}
							handleUploadFile={handleUploadFile}
							handleChangeVideo={handleChangeVideo}
							handleSaveVideo={handleSaveVideo}
							handleUpload={handleUpload}
							handleVideoDelete={handleVideoDelete}
							reloadEvent={reloadEvent}
						/>
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default EventEdit;
