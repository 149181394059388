import React from 'react';
import {
	ListItem,
	ListItemText,
	Typography,
	makeStyles
} from '@material-ui/core';

const LineItem = ({ label, value, ...rest }) => {
	const classes = useStyles();

	return (
		<ListItem>
			<ListItemText
				primary={
					<Typography component="span" variant="body2">
						{label + ' '}
					</Typography>
				}
				secondary={
					<Typography component="span" variant="body1">
						{value ? value : '-'}
					</Typography>
				}
			/>
		</ListItem>
	);
};

export default LineItem;

const useStyles = makeStyles((theme) => ({
	root: {}
}));
