import {
	List,
	ListItem,
	ListItemText,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SortableTableHeader } from 'components';
import EntryItem from './EntryItem';

const TABLE_HEADERS = [
	{
		value: 'first_name',
		label: 'First Name',
		sortable: false,
		hidden: false
	},

	{
		value: 'last_name',
		label: 'Last Name',
		sortable: false,
		hidden: false
	},

	{
		value: 'country',
		label: 'Country',
		sortable: false,
		hidden: false
	},

	{
		value: 'age',
		label: 'Age',
		sortable: false,
		hidden: false
	}
];

const EventShortList = ({ event }) => {
	const navigate = useNavigate();
	const classes = useStyles();
	const { id, event_users, event_submissions } = event;
	const handleClick = (submission) => {
		navigate(`/events/${id}/entries/${submission.id}`);
	};

	const shortlistedSubmissions =
		event_submissions?.filter(
			(submission) => submission.status === 'approved'
		) || [];

	return (
		<div className={classes.root}>
			{shortlistedSubmissions?.length > 0 ? (
				<>
					<TableContainer component={Paper} elevation={0}>
						<Table>
							<TableHead>
								<TableRow>
									{TABLE_HEADERS.filter((h) => !h.hidden).map((header) => (
										<SortableTableHeader
											key={header}
											value={`event_users.${header.value}`}
											sortable={header.sortable}
										>
											{header.label}
										</SortableTableHeader>
									))}
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{shortlistedSubmissions.map((submission, idx) => (
									<>
										<EntryItem
											key={idx}
											entry={submission}
											isSelectable={false}
											handleClick={handleClick}
										/>
									</>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			) : (
				<List>
					<ListItem>
						<ListItemText secondary="No users have been shortlisted yet." />
					</ListItem>
				</List>
			)}
		</div>
	);
};

export default EventShortList;

const useStyles = makeStyles((theme) => ({
	root: {}
}));
