import React, { useEffect } from 'react';
import { useTopics, useAlerts, useNavigation, useSelected } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { SortableTableHeader, Subheader, Placeholder } from 'components';
import Pagination from '@material-ui/lab/Pagination';
import {
	Checkbox,
	Container,
	Box,
	Paper,
	Button,
	Table,
	TableHead,
	TableContainer,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import TopicItem from 'features/topics/TopicItem';
import TopicToolbar from 'features/topics/TopicToolbar';
import SearchForm from 'features/topics/SearchForm';

const TABLE_HEADERS = [
	{
		value: 'image_url',
		label: 'Image',
		sortable: true,
		hidden: false
	},

	{
		value: 'title',
		label: 'Title',
		sortable: true,
		hidden: false
	},
	{
		value: 'description',
		label: 'Description',
		sortable: true,
		hidden: true
	},
	{
		value: 'order',
		label: 'Order',
		sortable: true,
		hidden: false
	}
];

const TopicList = ({ ...props }) => {
	const navigate = useNavigate();
	const {
		isLoading,
		isLoaded,
		isEmpty,
		topics,
		findTopics,
		deleteTopic,
		paginate,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadTopics,
		sortKey,
		sortDirection,
		handleSort
	} = useTopics({
		defaultSortKey: 'topics.order&topics.title',
		defaultSortDirection: 'asc'
	});

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		history,
		url: '/topics'
	});

	const {
		selected,
		selectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected
	} = useSelected();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleDeleteClick = async (topic) => {
		await deleteTopic(topic);
		showAlertSuccess('Topic has been deleted');
		reloadTopics();
	};

	useEffect(() => {
		findTopics(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection
			},
			page
		);
	}, [query, page, sortKey, sortDirection]);

	return (
		<>
			<Subheader
				title="Topics"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						Topic
					</Button>
				]}
			/>
			<Container maxWidth="lg">
				<>
					<Paper>
						<SearchForm
							isLoading={isLoading}
							query={query}
							handleSearch={findTopics}
						/>
						{!isEmpty ? (
							<TableContainer component={Paper} elevation={0}>
								<Table>
									<TableHead>
										<TableRow>
											{TABLE_HEADERS.filter((h) => !h.hidden).map(
												(header, idx) => (
													<SortableTableHeader
														key={idx}
														handleSort={handleSort}
														value={`topics.${header.value}`}
														sortable={header.sortable}
														sortKey={sortKey}
														sortDirection={sortDirection}
													>
														{header.label}
													</SortableTableHeader>
												)
											)}
											<TableCell></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{topics.map((topic, idx) => (
											<TopicItem
												key={idx}
												topic={topic}
												isSelectable={false}
												selectedIds={selectedIds}
												handleSelect={handleSelect}
												handleClick={handleClick}
												handleDeleteClick={handleDeleteClick}
												handleEditClick={handleEditClick}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<Placeholder
								icon={<Search />}
								title="No topics"
								subtitle="Please try another search"
							/>
						)}
					</Paper>
					{!isEmpty && (
						<Box my={1}>
							<Pagination
								onChange={(ev, page) => paginate(page)}
								color="primary"
								size="small"
								shape="rounded"
								page={page}
								count={numPages}
							/>
						</Box>
					)}
				</>
				<TopicToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	);
};

export default TopicList;
