import { Grid } from '@material-ui/core';
import React from 'react';

import { Autosuggest, TextInput } from 'components';
import { LOTTERY_STATUS, MONTHS } from 'lib/constants';

const LotteryForm = ({ lottery, isEditing, handleChange }) => {
	const AUTO_SUGGEST_MONTHS = getAutoSuggestMonths();
	const AUTO_SUGGEST_YEARS = getAutoSuggestYears();

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Autosuggest
					options={AUTO_SUGGEST_MONTHS}
					value={lottery.month || []}
					label="Month"
					name="month"
					placeholder=""
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Autosuggest
					options={AUTO_SUGGEST_YEARS}
					value={lottery.year || []}
					label="Year"
					name="year"
					placeholder=""
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={lottery.points_required || ''}
					label="Points Required"
					name="points_required"
					placeholder="Points Required"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					rows={3}
					multiline
					value={lottery.prize_description || ''}
					label="Prize Description"
					name="prize_description"
					placeholder="Prize Description"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12}>
				<Autosuggest
					options={LOTTERY_STATUS}
					value={lottery.status || ''}
					label="Status"
					name="status"
					placeholder=""
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	);
};

export default LotteryForm;

const getAutoSuggestYears = () =>
	[...new Array(25).keys()]
		.map((x) => x + 2020)
		.map((v, i) => ({ value: v, label: `${v}` }));

const getAutoSuggestMonths = () =>
	MONTHS.map((v, i) => ({ value: i + 1, label: v }));
