import React from 'react';
import { useLoaders, useResource } from 'hooks';
import api from 'api';

const useTriviaQuestions = () => {
	const { showLoading, hideLoading } = useLoaders();
	const {
		id: triviaQuestionId,
		isLoading,
		resource: triviaQuestion,
		resources: triviaQuestions,
		setResource: setTriviaQuestion,
		errors: triviaQuestionErrors,
		findOne: findTriviaQuestion,
		findMany: findTriviaQuestions,
		create: createTriviaQuestion,
		update: updateTriviaQuestion,
		destroy: destroyTriviaQuestion,
		isEmpty: isEmptyTriviaQuestion,

		...rest
	} = useResource({
		url: '/api/v1/admin/trivia_questions',
		name: 'trivia_question',
		label: 'Trivia Question'
	});

	const createTriviaBlock = async (params) => {
		showLoading();
		try {
			const formData = new FormData();

			formData.append('trivia_question[question]', params.question);
			formData.append('trivia_question[media_type]', params.media_type);

			if (params.media_type !== 'text') {
				const files = params.files;
				if (files && Object.keys(files).length) {
					for (const key in files) {
						formData.append(`trivia_question[${key}]`, files[key].file);
					}
				}
			}

			formData.append(
				'trivia_question[training_plan_id]',
				params.training_plan_id
			);
			formData.append('training_plan_id', params.training_plan_id);

			params?.trivia_answers?.forEach((item, index) => {
				if (item.file) {
					formData.append(
						`trivia_question[trivia_answers][][media_url]`,
						item.file
					);
				}

				if (item.media_type === 'text') {
					formData.append(
						`trivia_question[trivia_answers][][answer]`,
						item.answer
					);
				}

				if (item.id === params.correct_answer) {
					formData.append(
						`trivia_question[trivia_answers][][is_correct]`,
						true
					);
				}

        formData.append(
          `trivia_question[trivia_answers][][media_type]`,
          item.media_type
        )
			});

			const response = await api.post(
				'/api/v1/admin/trivia_questions',
				formData,
				{ headers: { 'Content-Type': 'multipart/form-data' } }
			);

			return response.data;
		} catch (e) {
			console.log(e);
		} finally {
			hideLoading();
		}
	};

	const updateTriviaBlock = async (params) => {
		showLoading();
		try {
			const formData = new FormData();

			formData.append('trivia_question[question]', params.question);
			formData.append('trivia_question[media_type]', params.media_type);

			if (params.media_type !== 'text') {
				const files = params.files;

        
				if (files && Object.keys(files).length) {
					for (const key in files) {
						formData.append(`trivia_question[${key}]`, files[key].file);
					}
				}
			}

			formData.append(
				'trivia_question[training_plan_id]',
				params.training_plan_id
			);
			formData.append('training_plan_id', params.training_plan_id);

			params?.trivia_answers?.forEach((item, index) => {
				formData.append(`trivia_question[trivia_answers][][id]`, item.id);

				if (item.file) {
					formData.append(
						`trivia_question[trivia_answers][][media_url]`,
						item.file
					);
				}

				if (item.media_type === 'text') {
					formData.append(
						`trivia_question[trivia_answers][][answer]`,
						item.answer
					);
				}

				if (item.id === params.correct_answer) {
					formData.append(
						`trivia_question[trivia_answers][][is_correct]`,
						true
					);
				}

        formData.append(
          `trivia_question[trivia_answers][][media_type]`,
          item.media_type
        )

			});

			const response = await api.put(
				`/api/v1/admin/trivia_questions/${params.id}`,
				formData,
				{ headers: { 'Content-Type': 'multipart/form-data' } }
			);

			return response.data;
		} catch (e) {
			console.log(e);
		} finally {
			hideLoading();
		}
	};

	return {
		triviaQuestionId,
		triviaQuestion,
		triviaQuestions,
		triviaQuestionErrors,
		isLoading,
		findTriviaQuestion,
		findTriviaQuestions,
		createTriviaQuestion,
		updateTriviaQuestion,
		destroyTriviaQuestion,
		isEmptyTriviaQuestion,
		setTriviaQuestion,
		createTriviaBlock,
		updateTriviaBlock,
		...rest
	};
};

export default useTriviaQuestions;
