import {
	Button,
	Checkbox,
	IconButton,
	Menu,
	MenuItem,
	TableCell,
	TableRow
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import React from 'react';

import { useMenu } from 'hooks';
import { MONTHS } from 'lib/constants';
import { ellipsis, properCase } from 'lib/utils';

const LotteryItem = ({
	lottery,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleSelectWinnerClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			{!lottery?.winner?.id && (
				<MenuItem onClick={(e) => handleSelectWinnerClick(lottery, e)}>
					Select Winner
				</MenuItem>
			)}
			<MenuItem onClick={(e) => handleEditClick(lottery, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(lottery, e)}>Delete</MenuItem>
		</Menu>
	);

	const isSelected = selectedIds.includes(lottery.id);

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(lottery)}
						name="lottery"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(lottery)}>
					{MONTHS[lottery.month - 1]}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(lottery)}>{lottery.year}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(lottery)}>
					{lottery.points_required}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(lottery)}>
					{ellipsis(lottery.prize_description, 50)}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(lottery)}>
					{lottery?.winner?.first_name} {lottery?.winner?.last_name}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(lottery)}>
					{properCase(lottery.status)}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(lottery)}>
					{lottery.created_at}
				</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default LotteryItem;
