import { isEmpty } from 'validations';

export const validatePromoCode = (promo) => {
	const messages = [];
	let isValid = true;

	if (isEmpty(promo.promo_code)) {
		isValid = false;
		messages.push('Promo Code is required');
	}

	if (isEmpty(promo.expiration_date)) {
		isValid = false;
		messages.push('Promo Expiry Date is required');
	}

	if (isEmpty(promo.promo_rule_id)) {
		isValid = false;
		messages.push('Promo Rule is required');
	}

	if (isEmpty(promo.redeems)) {
		isValid = false;
		messages.push('Promo Redeems is required');
	}

	return {
		isValid,
		messages
	};
};
