import React from 'react';
import {
	Avatar,
	ListItem,
	ListItemText,
	ListItemIcon,
	makeStyles
} from '@material-ui/core';
import { Photo } from '@material-ui/icons';

const EditorialItem = ({ editorial, handleClick, ...rest }) => {
	const classes = useStyles();
	return (
		<ListItem button onClick={handleClick}>
			<ListItemIcon>
				<Avatar size={80} variant="rounded" src={editorial.image_url}>
					<Photo />
				</Avatar>
			</ListItemIcon>
			<ListItemText primary={editorial.title} />
		</ListItem>
	);
};

export default EditorialItem;

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.common.grey
	}
}));
