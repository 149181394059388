import React, { useEffect } from 'react';
import { useAlerts, useNavigation, useSelected, useLessons } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { SortableTableHeader, Subheader, Placeholder } from 'components';
import Pagination from '@material-ui/lab/Pagination';
import {
	Container,
	Box,
	Paper,
	Button,
	Table,
	TableHead,
	TableContainer,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import LessonItem from './LessonItem';
import TopicToolbar from 'features/topics/TopicToolbar';
import SearchForm from 'features/topics/SearchForm';

const TABLE_HEADERS = [
	{
		value: 'title',
		label: 'Title',
		sortable: true,
		hidden: false
	},
	{
		value: 'level',
		label: 'Level',
		sortable: true,
		hidden: false
	},
	{
		value: 'trainer',
		label: 'Trainer',
		sortable: true,
		hidden: false
	},
	{
		value: 'skill_categories',
		label: 'Skill Categories',
		sortable: true,
		hidden: false
	},
	{
		value: 'published',
		label: 'Published',
		sortable: true,
		hidden: false
	}
];

const LessonList = ({ ...props }) => {
	const navigate = useNavigate();
	const {
		isLoading,
		isLoaded,
		isEmpty,
		lessons,
		findLessons,
		deleteLesson,
		paginate,
		query,
		page,
		numPages,
		reloadLessons,
		sortKey,
		sortDirection,
		handleSort
	} = useLessons({});

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		url: '/lessons'
	});

	const {
		selected,
		selectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected
	} = useSelected();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleDeleteClick = async (lesson) => {
		await deleteLesson(lesson);
		showAlertSuccess('Lesson has been deleted');
		reloadLessons();
	};

	useEffect(() => {
		findLessons({ ...query }, page);
	}, [query, page, sortKey, sortDirection]);

	return (
		<>
			<Subheader
				title="Lessons"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						Add Lesson
					</Button>
				]}
			/>
			<Container maxWidth="lg">
				<Paper>
					<SearchForm
						isLoading={isLoading}
						query={query}
						handleSearch={findLessons}
					/>
					{!isEmpty ? (
						<TableContainer component={Paper} elevation={0}>
							<Table>
								<TableHead>
									<TableRow>
										{TABLE_HEADERS.filter((h) => !h.hidden).map(
											(header, idx) => (
												<SortableTableHeader
													key={idx}
													handleSort={handleSort}
													value={header.value}
													sortable={header.sortable}
													sortKey={sortKey}
													sortDirection={sortDirection}
												>
													{header.label}
												</SortableTableHeader>
											)
										)}
										{/* <TableCell></TableCell> */}
									</TableRow>
								</TableHead>
								<TableBody>
									{lessons.map((lesson, idx) => (
										<LessonItem
											key={idx}
											lesson={lesson}
											isSelectable={false}
											selectedIds={selectedIds}
											handleSelect={handleSelect}
											handleClick={() => handleClick(lesson)}
											handleDeleteClick={() => handleDeleteClick(lesson)}
											handleEditClick={() => handleEditClick(lesson)}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					) : (
						<Placeholder
							icon={<Search />}
							title="No lessons found"
							subtitle="Please try another search"
						/>
					)}
				</Paper>
				{!isEmpty && (
					<Box my={1}>
						<Pagination
							onChange={(ev, page) => paginate(page)}
							color="primary"
							size="small"
							shape="rounded"
							page={page}
							count={numPages}
						/>
					</Box>
				)}
				<TopicToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	);
};

export default LessonList;
