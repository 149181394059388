import { Grid, Paper, Typography, Box } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';

const EventItem = ({
	key,
	event,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	return (
		<Grid
			item
			xs={12}
			sm={6}
			md={4}
			key={key}
			onClick={() => handleClick(event)}
		>
			<Paper
				elevation={0}
				style={{
					border: '1px solid #6BE0A0',
					borderRadius: 8,
					padding: 16,
					width: '100%',
					cursor: 'pointer'
				}}
			>
				<Box display={'flex'} justifyContent={'space-between'}>
					<Typography variant="h5" component="div">
						<strong>{event?.name}</strong>
					</Typography>
					<ChevronRightIcon fontSize={'large'} />
				</Box>
				<Grid container spacing={1} style={{ marginTop: 8 }}>
					<Grid item xs={6}>
						<Typography variant="body1" color="textSecondary">
							Start Date:
						</Typography>
						<Typography variant="h6" color="textSecondary">
							{new Date(event?.start_date)?.toLocaleDateString('en-GB')}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="body1" color="textSecondary">
							End Date:
						</Typography>
						<Typography variant="h6" color="textSecondary">
							{new Date(event?.end_date)?.toLocaleDateString('en-GB')}
						</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={1} style={{ marginTop: 8 }}>
					<Grid item xs={6}>
						<Typography variant="body1" color="textSecondary">
							{event?.partner?.name}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="body1" color="textSecondary">
							{event.event_submissions.length}{' '}
							{event.event_submissions.length === 1 ? 'Entry' : 'Entries'}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
};

export default EventItem;
