import React, { useEffect } from 'react';
import { useAlerts, useNavigation, useSelected, useLessons } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { SortableTableHeader, Subheader, Placeholder } from 'components';
import Pagination from '@material-ui/lab/Pagination';
import {
	Container,
	Box,
	Paper,
	Button,
	Table,
	TableHead,
	TableContainer,
	TableBody,
	TableRow,
	TableCell,
	makeStyles
} from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import TopicToolbar from 'features/topics/TopicToolbar';
import SearchForm from 'features/topics/SearchForm';
import AddLessonItem from './AddLessonItem';

const TABLE_HEADERS = [
	{
		value: 'title',
		label: 'Name',
		sortable: true,
		hidden: false
	},
	{
		value: 'level',
		label: 'Level',
		sortable: true,
		hidden: false
	},
	{
		value: 'trainer',
		label: 'Trainer',
		sortable: true,
		hidden: false
	},
	{
		value: 'skill_categories',
		label: 'Skill Categories',
		sortable: true,
		hidden: false
	}
];

const AddLessonModal = ({ id, handleAddLesson }) => {
	const navigate = useNavigate();
	const {
		isLoading,
		isLoaded,
		isEmpty,
		lessons,
		findLessons,
		deleteLesson,
		paginate,
		query,
		page,
		numPages,
		reloadLessons,
		sortKey,
		sortDirection,
		handleSort,
		addLesson
	} = useLessons({});

	const { handleClick, handleEditClick, handleAddClick } = useNavigation({
		url: '/lessons'
	});

	const {
		selected,
		selectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected
	} = useSelected();

	const { showAlertSuccess, showAlertError } = useAlerts();

	useEffect(() => {
		findLessons({ ...query, training_plan: id }, page);
	}, [query, page, sortKey, sortDirection]);

	const classes = styles();

	return (
		<>
			<Container maxWidth="lg" className={classes.container}>
				<Paper>
					<SearchForm
						isLoading={isLoading}
						query={query}
						handleSearch={findLessons}
					/>
					{!isEmpty ? (
						<TableContainer component={Paper} elevation={0}>
							<Table>
								<TableHead>
									<TableRow>
										{TABLE_HEADERS.filter((h) => !h.hidden).map(
											(header, idx) => (
												<SortableTableHeader
													key={idx}
													handleSort={handleSort}
													value={header.value}
													sortable={header.sortable}
													sortKey={sortKey}
													sortDirection={sortDirection}
												>
													{header.label}
												</SortableTableHeader>
											)
										)}
										{/* <TableCell></TableCell> */}
									</TableRow>
								</TableHead>
								<TableBody>
									{lessons.map((lesson, idx) => (
										<AddLessonItem
											key={idx}
											lesson={lesson}
											handleAddLesson={handleAddLesson}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					) : (
						<Placeholder
							icon={<Search />}
							title="No lessons found"
							subtitle="Please try another search"
						/>
					)}
				</Paper>
				{!isEmpty && (
					<Box my={1}>
						<Pagination
							onChange={(ev, page) => paginate(page)}
							color="primary"
							size="small"
							shape="rounded"
							page={page}
							count={numPages}
						/>
					</Box>
				)}
				<TopicToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	);
};

const styles = makeStyles((theme) => ({
	container: {
		minHeight: '50vh'
	}
}));

export default AddLessonModal;
