import React from 'react';
import api from 'api';
import { useAlerts, useLoaders, useResource } from 'hooks';
import { validateClub } from 'validations/clubs';

const useClubs = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders();
	const { showAlertError, showAlertSuccess } = useAlerts();

	const {
		resourceId: clubId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: club,
		resources: clubs,
		findOne: findClub,
		findMany: findClubs,
		update: updateClub,
		create: createClub,
		save: saveClub,
		destroy: deleteClub,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadClub,
		reloadMany: reloadClubs,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort
	} = useResource({
		id: id,
		url: '/api/v1/admin/clubs',
		name: 'club'
	});

	return {
		clubId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		club,
		clubs,
		findClub,
		findClubs,
		saveClub,
		updateClub,
		createClub,
		deleteClub,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadClub,
		reloadClubs
	};
};

export default useClubs;
