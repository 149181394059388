import React from 'react';
import { useResource } from 'hooks';

const useSubmissions = ({ url }) => {
	const {
		id: submissionId,
		isLoading,
		resource: submission,
		resources: submissions,
		errors: submissionErrors,
		findOne: findSubmission,
		findMany: findSubmissions,
		isEmpty: isEmptySubmission,
		setResources: setSubmissions,
		destroy: deleteSubmission,
		...rest
	} = useResource({
		url: url,
		name: 'submission',
		label: 'Submission'
	});

	return {
		submissionId,
		submission,
		submissions,
		submissionErrors,
		isLoading,
		findSubmission,
		findSubmissions,
		isEmptySubmission,
		setSubmissions,
		deleteSubmission,
		...rest
	};
};

export default useSubmissions;
