import { Box, Button, Container, Paper } from '@material-ui/core';
import { Subheader } from 'components';
import BenefitDetails from 'features/benefits/BenefitDetails';
import { useAlerts, useBenefits } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const BenefitShow = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { benefitId, isLoading, isLoaded, benefit, findBenefit } = useBenefits({
		id: id
	});

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleEditClick = () => navigate(`/benefits/${id}/edit`);

	useEffect(() => {
		if (id) findBenefit(id);
	}, []);

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : `Benefit ${benefit.id}`}
				buttons={[
					<Button onClick={handleEditClick} color="primary" variant="contained">
						Edit
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<BenefitDetails benefit={benefit} />
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default BenefitShow;
