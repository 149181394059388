import React, { useState } from 'react';
import {
	FormControl,
	InputAdornment,
	InputBase,
	Typography,
	IconButton
} from '@material-ui/core';
import clsx from 'clsx';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useStyles } from './styles';

const TextInput = ({
	className = {},
	required = false,
	label = '',
	type = 'text',
	name = '',
	margin = 'none',
	value = '',
	multiline = false,
	handleChange = () => {},
	rows = 1,
	placeholder = '',
	disabled = false,
	onKeyPress = () => {},
	autoComplete = 'off',
	endIcon,
	isClearable = false,
	isLoading = false,
	min,
	max,
	maxLength,
	...props
}) => {
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState(false);
	const [error, setError] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword((prev) => !prev);
	};

	const handleInputChange = (ev) => {
		let { name, value } = ev.target;
		required && value === '' ? setError(true) : setError(false);
		handleChange(ev);
	};

	const getPasswordInputType = (type, showPassword) => {
		return (
			(type !== 'password' && type) ||
			(type === 'password' && showPassword && 'text') ||
			type
		);
	};

	return (
		<FormControl fullWidth className={clsx(className.root, classes.root)}>
			<Typography
				variant="body2"
				color="textSecondary"
				className={clsx(className.label, classes.label)}
			>
				{label}
			</Typography>
			<InputBase
				error={error}
				rows={rows}
				className={clsx(className.inputBase, classes.inputBase)}
				multiline={multiline}
				autoComplete="off"
				fullWidth
				type={getPasswordInputType(type, showPassword)}
				name={name}
				margin={margin}
				disabled={disabled}
				placeholder={placeholder}
				onKeyPress={onKeyPress}
				variant="outlined"
				onChange={handleInputChange}
				inputProps={{
					min: type === 'date' ? min : undefined,
					max: type === 'date' ? max : undefined,
					maxLength: maxLength
				}}
				value={value}
				endAdornment={
					type === 'password' && (
						<InputAdornment position="end">
							<IconButton onClick={togglePasswordVisibility}>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					)
				}
				{...props}
			/>
			{maxLength && (
				<Typography
					variant="caption"
					color="textSecondary"
					className={clsx(className.charCount, classes.charCount)}
				>
					{`${value.length}/${maxLength} characters`}
				</Typography>
			)}
		</FormControl>
	);
};

export default TextInput;
