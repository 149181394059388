import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ImageUploader, Subheader, VideoUploader } from 'components';
import ArticleForm from 'features/articles/ArticleForm';
import { useAlerts, useArticles, useUploadFileInMemory } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ArticleNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();

	const { article, handleChange, createArticle, findArticle, deleteFile } =
		useArticles({ id: id });

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		await createArticle(article, memoryFile);
		showAlertSuccess('Article has been created');
		navigate('/articles');
	};

	useEffect(() => {
		if (id) findArticle(id);
	}, []);

	const handleDelete = async (type) => {
		removeFile();
	};

	return (
		<>
			<Subheader
				title="Add Articles"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<center>
						<ImageUploader
							label="Upload cover image (3:2)"
							imageUrl={article.image_url || memoryFile?.image_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'image_url')}
							handleDelete={() => handleDelete('image_url')}
						/>
						<ImageUploader
							label="Upload article image"
							imageUrl={article.image2_url || memoryFile?.image2_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'image2_url')}
							handleDelete={() => handleDelete('image_url')}
						/>
						<ImageUploader
							label="Upload web image (6:1)"
							imageUrl={article.web_image_url || memoryFile?.web_image_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'web_image_url')}
							handleDelete={() => handleDelete('web_image_url')}
						/>
						<VideoUploader
							label="Upload video"
							videoUrl={article.video_url || memoryFile?.video_url?.url}
							handleUpload={(file) => handleUploadFile(file, 'video_url')}
							handleDelete={() => handleDelete('video_url')}
						/>
					</center>
					<ArticleForm article={article} handleChange={handleChange} />
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default ArticleNew;
