import { render } from '@testing-library/react';
import { AppProvider } from '../src/context';
import React from 'react';

const AllTheProviders = ({ children }) => {
	return <AppProvider>{children}</AppProvider>;
};

const customRender = (ui, options) =>
	render(ui, { wrapper: AllTheProviders, ...options });

const convertToRegExp = (value) => {
	return new RegExp(value, 'i');
};

const DisplayTextComponent = ({ text }) => {
	return (
		<div>
			<span>{text}</span>
		</div>
	);
};

// re-export everything
export * from '@testing-library/react';
// override render method
export { customRender as render, convertToRegExp, DisplayTextComponent };
