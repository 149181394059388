import {
	Box,
	Button,
	Container,
	List,
	Paper,
	Typography
} from '@material-ui/core';
import api from 'api';
import { Subheader } from 'components';
import { useAlerts, useCategory } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CategoryDetails from './CategoryDetails';

const CategoryShow = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const editorialContainerRef = useRef(null);

	const { categoryId, isLoading, isLoaded, category, findCategory } =
		useCategory({
			id: id
		});

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleEditClick = () => navigate(`/categories/${id}/edit`);

	useEffect(() => {
		if (id) findCategory(id);
	}, []);

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : category.name}
				buttons={[
					<Button onClick={handleEditClick} color="primary" variant="contained">
						Edit
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>{<CategoryDetails catyegory={category} />}</Box>
				</Paper>
			</Container>
		</>
	);
};

export default CategoryShow;
