import React from 'react';
import api from 'api';
import { useAlerts, useLoaders, useResource } from 'hooks';
import { validateEvent } from 'validations/events';

const useEventVideos = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders();
	const { showAlertError, showAlertSuccess } = useAlerts();

	const {
		resourceId: eventId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: event_video,
		resources: event_videos,
		findOne: findEventVideo,
		findMany: findEventVideos,
		update: updateEventVideo,
		create: createEventVideo,
		save: saveEventVideo,
		destroy: deleteEventVideo,
		handleChange,
		clearResource,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadEventVideo,
		reloadMany: reloadEventsVideos,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort
	} = useResource({
		id: id,
		url: '/api/v1/admin/event_videos',
		name: 'event_video'
	});

	return {
		eventId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		event_video,
		event_videos,
		findEventVideo,
		findEventVideos,
		saveEventVideo,
		updateEventVideo,
		createEventVideo,
		deleteEventVideo,
		uploadFile,
		deleteFile,
		handleChange,
		clearResource,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadEventVideo,
		reloadEventsVideos
	};
};

export default useEventVideos;
