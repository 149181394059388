import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';
import SettingForm from 'features/settings/SettingForm';
import { useAlerts, useSettings } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateSetting } from 'validations/settings';

const SETTING_ID = 1;

const SettingEdit = ({ ...props }) => {
	const navigate = useNavigate();

	const { setting, handleChange, updateSetting, findSetting } = useSettings({
		id: SETTING_ID
	});

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const valid = validateSetting(setting);
		if (valid.isValid) {
			await updateSetting(setting);
			showAlertSuccess('Setting has been updated');
			navigate(`/settings`);
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	useEffect(() => {
		findSetting(SETTING_ID);
	}, []);

	return (
		<>
			<Subheader
				title="Edit Settings"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<SettingForm setting={setting} handleChange={handleChange} />
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default SettingEdit;
