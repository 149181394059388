import PropTypes from 'prop-types';

const Maybe = ({ children, display }) => {
	return display ? children : null;
};

Maybe.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	display: PropTypes.bool.isRequired
};

export default Maybe;
