import { Grid, Typography, makeStyles } from '@material-ui/core';
import {
	Autosuggest,
	MultiAutosuggest,
	SwitchInput,
	TextInput
} from 'components';
import { DISPLAY_POSITIONS, PREFERRED_FOOT, USER_ROLES } from 'lib/constants';
import { COUNTRIES } from 'lib/constants/countries';
import React from 'react';

const UserForm = ({ user, handleChange }) => {
	const classes = useStyles();
	return (
		<Grid container spacing={1}>
			<Grid xs={12} lg={12} className={classes.sectionHeader} item>
				<Typography component="span" variant="h3">
					Basic Information
				</Typography>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.first_name}
					label="First name"
					name="first_name"
					placeholder="First name"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.last_name}
					label="Last name"
					name="last_name"
					placeholder="Last name"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.email}
					label="Email"
					name="email"
					placeholder="Email"
					handleChange={handleChange}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<Autosuggest
					options={USER_ROLES}
					value={user.role}
					label="Role"
					name="role"
					placeholder="Role"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.dob}
					label="Birthday"
					name="dob"
					placeholder="Birthday"
					type="date"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<SwitchInput
					value={user.paid ? true : false}
					label="Paid"
					name="paid"
					placeholder="paid"
					handleChange={handleChange}
					className={classes.switchInput}
				/>
			</Grid>

			<Grid xs={12} lg={12} className={classes.sectionHeader} item>
				<Typography component="span" variant="h3">
					Location
				</Typography>
			</Grid>
			<Grid item xs={12} md={6}>
				<Autosuggest
					options={COUNTRIES}
					value={user.country}
					label="Country"
					name="country"
					placeholder="Country"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Autosuggest
					options={COUNTRIES}
					value={user.passport_country}
					label="Nationality"
					name="passport_country"
					placeholder="Nationality"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.city}
					label="City"
					name="city"
					placeholder="City"
					type="text"
					handleChange={handleChange}
				/>
			</Grid>

			<Grid xs={12} lg={12} className={classes.sectionHeader} item>
				<Typography component="span" variant="h3">
					Player Profile
				</Typography>
			</Grid>
			<Grid item xs={12} md={12}>
				<TextInput
					value={user.summary}
					label="Summary"
					name="summary"
					placeholder="Summary"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.player_level}
					label="Player Level"
					name="player_level"
					placeholder="Player Level"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.interested_opportunities}
					label="Interested Opportunities"
					name="interested_opportunities"
					placeholder="Interested Opportunities"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<MultiAutosuggest
					options={DISPLAY_POSITIONS}
					value={user.positions || []}
					label="Positions"
					name="positions"
					placeholder="Select one"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Autosuggest
					value={user.preferred_foot}
					label="Preferred foot"
					name="preferred_foot"
					placeholder="Preferred foot"
					options={PREFERRED_FOOT}
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.current_club}
					label="Current Team"
					name="current_club"
					placeholder="Current Team"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<SwitchInput
					value={user.agent ? true : false}
					label="Agent"
					name="agent"
					placeholder="Agent"
					handleChange={handleChange}
					className={classes.switchInput}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.height}
					label="Height"
					name="height"
					placeholder="Height (CM)"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.weight}
					label="Weight"
					name="weight"
					placeholder="Weight (KG)"
					handleChange={handleChange}
				/>
			</Grid>

			<Grid xs={12} lg={12} className={classes.sectionHeader} item>
				<Typography component="span" variant="h3">
					Favourites
				</Typography>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.shirt}
					label="Shirt number"
					name="shirt"
					placeholder="Shirt number"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.favorite_boot}
					label="Favorite boot"
					name="favorite_boot"
					placeholder="Favorite boot"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.favorite_club}
					label="Favorite club"
					name="favorite_club"
					placeholder="Favorite club"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.favorite_brand}
					label="Favorite brand"
					name="favorite_brand"
					placeholder="Favorite brand"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.favorite_player}
					label="Favorite Player"
					name="favorite_player"
					placeholder="Favorite Player"
					handleChange={handleChange}
				/>
			</Grid>

			<Grid xs={12} lg={12} className={classes.sectionHeader} item>
				<Typography component="span" variant="h3">
					Contact Information
				</Typography>
			</Grid>
			<Grid xs={12} lg={12} item>
				<Typography component="span" variant="subtitle2">
					Parent / Guardian
				</Typography>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.first_guardian?.name}
					label="Name"
					name="first_guardian.name"
					placeholder="Name"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.first_guardian?.email}
					label="Email"
					name="first_guardian.email"
					placeholder="Email"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.first_guardian?.phone_number}
					label="Phone Number"
					name="first_guardian.phone_number"
					placeholder="Phone Number"
					handleChange={handleChange}
				/>
			</Grid>

			<Grid xs={12} lg={12} item>
				<Typography component="span" variant="subtitle2">
					Second Parent / Guardian
				</Typography>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.second_guardian?.name}
					label="Name"
					name="second_guardian.name"
					placeholder="Name"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.second_guardian?.email}
					label="Email"
					name="second_guardian.email"
					placeholder="Email"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.second_guardian?.phone_number}
					label="Phone Number"
					name="second_guardian.phone_number"
					placeholder="Phone Number"
					handleChange={handleChange}
				/>
			</Grid>

			<Grid xs={12} lg={12} item>
				<Typography component="span" variant="subtitle2">
					Coach
				</Typography>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.coach?.name}
					label="Name"
					name="coach.name"
					placeholder="Name"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.coach?.email}
					label="Email"
					name="coach.email"
					placeholder="Email"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextInput
					value={user.coach?.phone_number}
					label="Phone Number"
					name="coach.phone_number"
					placeholder="Phone Number"
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	);
};

export default UserForm;

const useStyles = makeStyles(() => ({
	sectionHeader: {
		marginTop: 50
	},
	switchInput: {
		marginTop: 30,
		paddingLeft: 5
	}
}));
