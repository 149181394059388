import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ImageUploader, SubheaderV2 } from 'components';
import CourseForm from 'features/courses/CourseForm';
import { useAlerts, useCourses, useUploadFileInMemory } from 'hooks';
import { validateCourse } from 'validations/courses';

const CourseNew = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();
	const { showAlertSuccess, showAlertError } = useAlerts();
	const { course, handleChange, createCourse, findCourse } = useCourses({
		id: id
	});

	const handleSaveClick = async () => {
		const valid = validateCourse(course);
		if (valid.isValid) {
			delete course.difficulty;
			await createCourse(course, memoryFile);
			showAlertSuccess('Course has been created');
			navigate('/courses');
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	useEffect(() => {
		if (id) findCourse(id);
	}, []);

	const handleDelete = async () => {
		removeFile();
	};

	return (
		<>
			<SubheaderV2
				title="Add a new course"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save New Course
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<CourseForm course={course} handleChange={handleChange} />
				</Box>
				<Box px={4}>
					<Grid container spacing={1}>
						<Grid item md={6} xs={12}>
							<Typography variant="body2" color="textSecondary">
								{'Course Image'}
							</Typography>
							<ImageUploader
								label={`Upload Image`}
								imageUrl={
									course.image_url || (memoryFile && memoryFile?.image_url?.url)
								}
								handleUpload={(file) => handleUploadFile(file, 'image_url')}
								handleDelete={() => handleDelete('image_url')}
							/>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</>
	);
};

export default CourseNew;
