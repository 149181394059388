import React from 'react';
import { Grid } from '@material-ui/core';
import {
	Autosuggest,
	CheckboxGroupInput,
	RadioInput,
	TextInput
} from 'components';

const BenefitForm = ({ benefit, isEditing, handleChange }) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={benefit.title}
				label="Title"
				name="title"
				placeholder="Title"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={benefit.expires_at}
				label="Expires at"
				name="expires_at"
				placeholder="mm/dd/yyyyy"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				rows={3}
				multiline
				value={benefit.description}
				label="Description"
				name="description"
				placeholder="Description"
				handleChange={handleChange}
			/>
		</Grid>

		<Grid item xs={12} sm={12}>
			<TextInput
				value={benefit.url}
				label="Benefit URL"
				name="url"
				placeholder="Benefit URL"
				handleChange={handleChange}
			/>
		</Grid>
	</Grid>
);

export default BenefitForm;
