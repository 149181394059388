import React from 'react';

import { Grid } from '@material-ui/core';
import fp from 'lodash/fp';

import { DisplayImage, DisplayText } from 'components';

const TrainingPlanDetails = ({ trainingPlan }) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<DisplayText label={'Drill Name'} text={trainingPlan?.name} />
			<DisplayText
				label={'Drill Description'}
				text={trainingPlan?.description}
			/>
		</Grid>
		<Grid item xs={12} md={4}>
			<DisplayText
				label={'Skill Categories'}
				text={
					fp.compose(
						fp.join(' '),
						fp.map((y) => fp.capitalize(y)),
						fp.split('_')
					)(trainingPlan?.category_names) || trainingPlan?.category_name
				}
			/>
		</Grid>
		<Grid item xs={12} md={4}>
			<DisplayText
				label={'Publised Course'}
				text={trainingPlan?.published ? 'Yes' : 'No'}
			/>
		</Grid>
		<Grid item xs={12} md={4}>
			<DisplayText label={'Created At'} text={trainingPlan?.created_at} />
		</Grid>
		<Grid item xs={12} sm={12}>
			<DisplayImage
				label={'Training Plan Drill Image'}
				url={trainingPlan?.image_url}
				width={'33.3%'}
			/>
		</Grid>
	</Grid>
);

export default TrainingPlanDetails;
