import React from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Check } from '@material-ui/icons';

import {
	useAlerts,
	useTrainingPlans,
	useTriviaAnswers,
	useUploadFileInMemory
} from 'hooks';
import { triviaAnswerValidation } from 'validations/triviaAnswers';
import {
	Autosuggest,
	ImageUploader,
	SwitchInput,
	TextInput,
	VideoUploader
} from 'components';
import { TRAINING_PLAN_TRIVIA_MEDIA_TYPES } from 'lib/constants/trainingPlan';

const TriviaAnswerForm = ({
	handleClose,
	question,
	findTrainingPlan,
	handleChange,
	triviaAnswer,
	createTriviaAnswer,
	updateTriviaAnswer
}) => {
	const { id } = useParams();

	const { memoryFile, removeFile, handleUploadFile } = useUploadFileInMemory();
	const { showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const isValid = triviaAnswerValidation(triviaAnswer);

		if (!isValid.isValid) {
			showAlertError(isValid.messages[0]);
			return;
		}

		if (triviaAnswer?.id) {
			await updateTriviaAnswer(triviaAnswer, memoryFile).finally(async () => {
				handleClose();
				await findTrainingPlan(id);
			});
		} else {
			await createTriviaAnswer(
				{ ...triviaAnswer, trivia_question_id: question.id },
				memoryFile
			).finally(async () => {
				handleClose();
				await findTrainingPlan(id);
			});
		}
	};

	const handleUpload = async (file, type) => {
		await handleUploadFile(file, type);
	};

	const handleDelete = async (type) => {
		removeFile();
	};

	return (
		<div>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={12}>
					<TextInput
						rows={4}
						multiline
						value={triviaAnswer.answer}
						label="Trivia Answer"
						name="answer"
						placeholder="Trivia Answer"
						handleChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<Autosuggest
						options={TRAINING_PLAN_TRIVIA_MEDIA_TYPES}
						value={triviaAnswer.media_type}
						label="Media Type"
						name="media_type"
						placeholder=""
						handleChange={handleChange}
					/>
				</Grid>

				{triviaAnswer.media_type === 'image' && (
					<Grid item xs={12} sm={12}>
						<Box py={4}>
							<Typography variant="body2" color="textSecondary">
								{'Image'}
							</Typography>
							<ImageUploader
								label="Media File"
								imageUrl={triviaAnswer.media_url || memoryFile?.media_url?.url}
								handleUpload={(file) => handleUploadFile(file, 'media_url')}
								handleDelete={() => handleDelete('media_url')}
							/>
						</Box>
					</Grid>
				)}

				{triviaAnswer.media_type === 'video' && (
					<Grid item xs={12} sm={12}>
						<Box py={4}>
							<Typography variant="body2" color="textSecondary">
								{'Video'}
							</Typography>
							<VideoUploader
								label="Media File"
								videoUrl={
									triviaAnswer.media_url ||
									(memoryFile && memoryFile?.media_url?.url)
								}
								handleUpload={(file) => handleUpload(file, 'media_url')}
								handleDelete={() => {
									handleDelete('media_url');
									handleChange({ target: { name: 'media_url', value: '' } });
								}}
							/>
						</Box>
					</Grid>
				)}

				<Grid item xs={12} sm={12}>
					<SwitchInput
						value={triviaAnswer.featured}
						label="Correct Answer"
						name="is_correct"
						placeholder="Correct Answer"
						handleChange={handleChange}
					/>
				</Grid>

				<Box py={2}>
					<Button
						startIcon={<Check />}
						color="primary"
						variant="contained"
						onClick={handleSaveClick}
					>
						Save
					</Button>
				</Box>
			</Grid>
		</div>
	);
};

export default TriviaAnswerForm;
