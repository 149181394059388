import { Box, Button, Container } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Subheader } from 'components';
import AchievementForm from 'features/achievements/AchievementForm';
import { useAlerts, useAchievements } from 'hooks';
import { validateAchievement } from 'validations/achievements';

const AchievementNew = ({ ...props }) => {
	const navigate = useNavigate();

	const { id } = useParams();

	const { showAlertSuccess, showAlertError } = useAlerts();

	const { achievement, handleChange, createAchievement, setAchievement } =
		useAchievements({
			id: id
		});

	const handleSaveClick = async () => {
		const valid = validateAchievement(achievement);
		if (valid.isValid) {
			await createAchievement(achievement);
			showAlertSuccess('Reward has been created');
			navigate('/rewards');
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	const setInitialValues = useCallback(
		() => setAchievement((prev) => ({ ...prev, status: 'new' })),
		[]
	);

	useEffect(() => {
		setInitialValues();
	}, [setInitialValues]);

	return (
		<>
			<Subheader
				title="Add Achievement"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Box p={4}>
					<AchievementForm
						achievement={achievement}
						handleChange={handleChange}
					/>
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default AchievementNew;
