import {
	Button,
	Checkbox,
	IconButton,
	Menu,
	MenuItem,
	TableCell,
	TableRow,
	makeStyles
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import React from 'react';

import { Image, Label } from 'components';
import { useMenu } from 'hooks';
import { CATEGORIES, POSITIONS } from 'lib/constants';

const CourseItem = ({
	course,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } = useMenu();
	const classes = useStyles();

	const renderMenu = () => (
		<Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
			<MenuItem onClick={(e) => handleEditClick(course, e)}>Edit</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(course, e)}>Delete</MenuItem>
		</Menu>
	);

	const isSelected = selectedIds.includes(course.id);

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(course)}
						name="course"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(course)}>
					<Image src={course.image_url} variant="rounded" size={80} />
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(course)}>{course.title}</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(course)}>
					<div className={classes.labelContainer}>
						{course.categories.map((c, i) => (
							<div className={classes.labelItem} key={i}>
								<Label
									label={CATEGORIES.find((v) => v.value == c)?.label || c}
								/>
							</div>
						))}
					</div>
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(course)}>
					<div className={classes.labelContainer}>
						{course.positions.map((p, i) => (
							<div className={classes.labelItem} key={i}>
								<Label
									label={POSITIONS.find((v) => v.value == p)?.label || p}
								/>
							</div>
						))}
					</div>
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(course)}>
					{course.trainer?.name}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(course)}>
					{course.featured ? 'Yes' : 'No'}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(course)}>
					{course.published ? 'Yes' : 'No'}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(course)}>
					{course.recommended ? 'Yes' : 'No'}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(course)}>
					{course.premium ? 'Yes' : '-'}
				</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default CourseItem;

const useStyles = makeStyles((theme) => ({
	labelContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	labelItem: {
		marginBottom: 5
	}
}));
