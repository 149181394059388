import { Box, Button, Container, Paper } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Subheader } from 'components';
import AchievementForm from 'features/achievements/AchievementForm';
import { useAlerts, useAchievements } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateAchievement } from 'validations/achievements';

const AchievementEdit = ({ ...props }) => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { achievement, handleChange, updateAchievement, findAchievement } =
		useAchievements({ id: id });

	const { showAlertSuccess, showAlertError } = useAlerts();

	const handleSaveClick = async () => {
		const valid = validateAchievement(achievement);
		if (valid.isValid) {
			await updateAchievement(achievement);
			showAlertSuccess('Achievement has been updated');
			navigate(`/achievements/${id}`);
		} else {
			valid.messages.map((m) => showAlertError(m));
		}
	};

	useEffect(() => {
		if (id) findAchievement(id);
	}, []);

	return (
		<>
			<Subheader
				title="Edit Achievement"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<AchievementForm
							achievement={achievement}
							handleChange={handleChange}
						/>
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	);
};

export default AchievementEdit;
