import { useState } from 'react';

const useUploadFileInMemory = () => {
	const [memoryFile, setFile] = useState();

	const handleUploadFile = async (file, attributeName) => {
		const url = await getUrlFromFile(file);
		setFile({
			...memoryFile,
			[attributeName]: {
				file,
				url: url
			}
		});
	};

	const removeFile = () => setFile();

	const clearAllFiles = () => {
		setFile({});
	};

	return {
		memoryFile,
		removeFile,
		handleUploadFile,
		clearAllFiles,
		setFile
	};
};

export default useUploadFileInMemory;

export function getUrlFromFile(file) {
	return new Promise((response) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => {
			response(reader.result);
		});
		reader.readAsDataURL(file);
	});
}
