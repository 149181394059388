import React from 'react';
import { alpha, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	root: {},
	inputBase: {
		width: '100%',
		'& input, & textarea': {
			'-webkit-appearance': 'none',
			'-moz-appearance': 'none',
			appearance: 'none',
			borderRadius: 4,
			backgroundColor: theme.palette.common.white,
			padding: theme.spacing(1, 1.5),
			minHeight: 28,
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			border: `1px solid ${theme.palette.common.inputBorder}`,
			fontSize: 15,
			'&:focus': {
				boxShadow: `${alpha(theme.palette.primary.light, 0.25)} 0 0 0 0.2rem`,
				borderColor: theme.palette.primary.light
			}
		}
	},
	label: {
		marginBottom: theme.spacing(1)
	},
	IconButton: {
		padding: '5px'
	},
	icon: {
		fontSize: '20px',
		color: '#888'
	}
}));
