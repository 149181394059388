import api from 'api';
import { useResource } from 'hooks';

const useVideos = ({ id, ...props }) => {
	const {
		resourceId: videoId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: video,
		resources: videos,
		findOne: findVideo,
		findMany: findVideos,
		update: updateVideo,
		create: createVideo,
		save: saveVideo,
		destroy: deleteVideo,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		refresh: reloadVideo,
		reloadMany: reloadVideos,
		params,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort
	} = useResource({
		id: id,
		url: '/api/v1/admin/videos',
		name: 'video'
	});

	const findVideoTags = async () => {
		let resp = await api.get(`/api/v1/videos/tags`);
		return resp.data;
	};

	return {
		videoId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		video,
		videos,
		findVideo,
		findVideos,
		saveVideo,
		updateVideo,
		createVideo,
		deleteVideo,
		uploadFile,
		deleteFile,
		findVideoTags,
		handleChange,
		handleChangePage,
		params,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadVideo,
		reloadVideos
	};
};

export default useVideos;
