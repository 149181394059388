import { List } from '@material-ui/core';
import React from 'react';

import { LineItem } from 'components';
import { MONTHS } from 'lib/constants';
import { properCase } from 'lib/utils';

const LotteryDetails = ({ lottery }) => {
	return (
		<List>
			<LineItem label="Month" value={MONTHS[lottery.month - 1]} />
			<LineItem label="Year" value={lottery.year} />
			<LineItem label="Points Required" value={lottery.points_required} />
			<LineItem label="Prize Description" value={lottery.prize_description} />
			<LineItem
				label="Winner"
				value={
					lottery?.winner
						? `${lottery?.winner?.first_name} ${lottery?.winner?.last_name}`
						: 'n/a'
				}
			/>
			<LineItem label="Status" value={properCase(lottery.status || '')} />
			<LineItem label="Date Created" value={lottery.created_at} />
			<LineItem label="Last Updated" value={lottery.updated_at} />
		</List>
	);
};

export default LotteryDetails;
