import React from 'react';

import { useResource } from 'hooks';

const useRewards = ({ id, ...props }) => {
	const [filters, setFilters] = React.useState({
		status: undefined
	});

	const resetFilters = () => {
		setFilters({
			status: undefined
		});
	};

	const {
		id: rewardId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: reward,
		resources: rewards,
		errors: rewardErrors,
		findOne: findReward,
		findMany: findRewards,
		update: updateReward,
		create: createReward,
		save: saveReward,
		destroy: deleteReward,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		refresh: reloadReward,
		refreshMany: reloadRewards,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		setResource: setReward
	} = useResource({
		id: id,
		url: '/api/v1/admin/rewards',
		name: 'reward'
	});

	return {
		rewardId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		reward,
		rewards,
		findReward,
		findRewards,
		rewardErrors,
		saveReward,
		updateReward,
		createReward,
		deleteReward,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadReward,
		reloadRewards,
		filters,
		setFilters,
		resetFilters,
		setReward
	};
};

export default useRewards;
