import React from 'react';
import api from 'api';
import { useAlerts, useLoaders, useResource } from 'hooks';
import { validateBenefit } from 'validations/benefits';

const useBenefits = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders();
	const { showAlertError, showAlertSuccess } = useAlerts();

	const {
		resourceId: benefitId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: benefit,
		resources: benefits,
		findOne: findBenefit,
		findMany: findBenefits,
		update: updateBenefit,
		create: createBenefit,
		save: saveBenefit,
		destroy: deleteBenefit,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadBenefit,
		reloadMany: reloadBenefits,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort
	} = useResource({
		id: id,
		url: '/api/v1/admin/benefits',
		name: 'benefit'
	});

	return {
		benefitId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		benefit,
		benefits,
		findBenefit,
		findBenefits,
		saveBenefit,
		updateBenefit,
		createBenefit,
		deleteBenefit,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadBenefit,
		reloadBenefits
	};
};

export default useBenefits;
